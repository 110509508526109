import React from 'react';
import PropTypes from 'prop-types';

const ImageGallery = ({ parsedimages }) => {
  // Base URL for the image path
  const imgPath = 'https://navrang.mvd-tech.io/API/images/meal/attachment/';

  return (
    <div className="container mt-4 px-4">
      <div className="row g-4 justify-content-center">
        {parsedimages && parsedimages.length > 0 ? (
          parsedimages.map(image => (
            <div 
              key={image.logo_id} 
              className="col-12 col-sm-6 col-md-4 col-lg-3"
            >
              <div className="relative group">
                <img
                  src={imgPath + image.file_name}
                  alt={image.file_name || 'Gallery image'}
                  className="w-full h-64 object-cover rounded-lg 
                  shadow-md transition-all duration-300 group-hover:shadow-xl"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-black opacity-0 
                group-hover:opacity-10 transition-opacity duration-300 rounded-lg"
                />
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 py-8">
            <div className="bg-gray-50 rounded-lg p-8 text-center shadow-sm">
              <p className="text-gray-500 text-lg mb-0">No images available</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ImageGallery.propTypes = {
  parsedimages: PropTypes.arrayOf(
    PropTypes.shape({
      logo_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      file_name: PropTypes.string.isRequired,
    }),
  ),
};

ImageGallery.defaultProps = {
  parsedimages: [],
};

export default ImageGallery;
