import React from 'react';
import { Card, CardBody } from '@/shared/components/Card';

const LocationCard = () => {
  // Hardcoded latitude and longitude
  const latitude = '37.7749'; // Example: San Francisco latitude
  const longitude = '-122.4194'; // Example: San Francisco longitude

  const mapSrc = latitude && longitude
    ? `https://maps.google.com/maps?q=${latitude},${longitude}&z=17&output=embed&maptype=satellite&t=h`
    : null;

  return (
    <Card className="w-full max-w-4xl">
      <CardBody>
        {mapSrc ? (
          <div className="relative w-full">
            <div style={{ width: '100%' }}>
              <iframe
                width="100%"
                height="300"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src={mapSrc}
                title="Google Map"
                loading="lazy"
                allowFullScreen
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-[400px]">
            <p>No location coordinates available</p>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default LocationCard;
