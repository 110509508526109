import React, { 
  useState, 
  useContext, 
  useEffect,
  useRef,
 } from 'react';
import { Col } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';

import {
  Card,
  CardBody,
} from '@/shared/components/Card';
import { RestaurantContext } from '../../../../../context/RestaurantContext';

const GetLocation = ({
 isEdit, longitude, latitude, maplink, 
}) => {
  const { restaurantInfo, setRestaurantInfo } = useContext(RestaurantContext);
  const [isLinkverified, setIsLinkVerified] = useState(false);
  const [mapLinks, setMapLinks] = useState(restaurantInfo.maplink || '');
  const selectref = useRef(null);

  useEffect(() => {
    if (isEdit) {
        setRestaurantInfo({
   latitude,
   longitude,
   maplink,
        });
        setMapLinks(maplink);
    }
  }, []);

 

  const CurrentLocationHandler = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Geolocation fetched:', position.coords);
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          // Update restaurantInfo with latitude and longitude
          setRestaurantInfo(prevInfo => ({
            ...prevInfo,
            latitude: lat,
            longitude: lon,
          }));

          // Generate the Google Maps link
          const mapLink = `https://www.google.com/maps?q=${lat},${lon}`;
          setIsLinkVerified(true);
          setRestaurantInfo(prevInfo => ({
            ...prevInfo,
            maplink: mapLink,
          }));
          setMapLinks(mapLink);
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  };
  
  const parseLinkHandler = () => {
    const link = selectref.current.value.trim();

    try {
      if (link.includes('@')) {
        const matches = link.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/);
        if (matches) {
          setRestaurantInfo(prevInfo => ({
            ...prevInfo,
            latitude: parseFloat(matches[1]),
            longitude: parseFloat(matches[2]),
          }));
        }
      } else if (link.includes('?q=')) {
        const params = new URLSearchParams(link.split('?')[1]);
        const locationParam = params.get('q');
        
        if (locationParam && locationParam.match(/-?\d+\.\d+,-?\d+\.\d+/)) {
          const [lat, lng] = locationParam.split(',');
          setRestaurantInfo(prevInfo => ({
            ...prevInfo,
            latitude: parseFloat(lat),
            longitude: parseFloat(lng),
          }));
        }
      } else if (link.match(/\/(-?\d+\.\d+),(-?\d+\.\d+)/)) {
        const matches = link.match(/\/(-?\d+\.\d+),(-?\d+\.\d+)/);
        setRestaurantInfo(prevInfo => ({
          ...prevInfo,
          latitude: parseFloat(matches[1]),
          longitude: parseFloat(matches[2]),
        }));
      }
      if (restaurantInfo.latitude === null || restaurantInfo.longitude === null) {
        throw new Error('Could not extract coordinates from the link');
      }
  
      // Validate coordinate ranges
      if (Math.abs(restaurantInfo.latitude) > 90 || Math.abs(restaurantInfo.longitude) > 180) {
        throw new Error('Invalid coordinate values');
      }

      return restaurantInfo.longitude;
    } catch (error) {
      console.error('Error parsing Google Maps link:', error.message);
      return null;
    }
  };
 
  const verifyLinkhandler = (e) => {
    const link = e.target.value.trim();
    
    // Set of valid Google Maps domains
    const validDomains = [
      'google.com/maps',
      'maps.google.com',
      'www.google.com/maps',
      'goo.gl/maps',
    ];
  
    try {
      // Reset verification state if empty
      if (!link) {
        setIsLinkVerified(false);
        return {
          isValid: false,
          message: 'Please enter a Google Maps link',
        };
      }
  
      // Validate URL format
      let url;
      try {
        url = new URL(link);
      } catch {
        setIsLinkVerified(false);
        return {
          isValid: false,
          message: 'Invalid URL format',
        };
      }
  
      // Check if it's from a valid Google Maps domain
      if (!validDomains.some(domain => url.toString().includes(domain))) {
        setIsLinkVerified(false);
        return {
          isValid: false,
          message: 'Not a valid Google Maps URL',
        };
      }
  
      // Check for valid coordinate patterns
      const patterns = [
        /@(-?\d+\.\d+),(-?\d+\.\d+)/, // @lat,lng format
        /\?q=(-?\d+\.\d+),(-?\d+\.\d+)/, // ?q=lat,lng format
        /\/place\/.*?\/(-?\d+\.\d+),(-?\d+\.\d+)/, // /place/name/lat,lng format
        /\/maps\/place\/(-?\d+\.\d+),(-?\d+\.\d+)/, // /maps/place/lat,lng format
      ];
  
      let hasValidFormat = false;
let coordinates = null;

patterns.some((pattern) => {
  const matches = link.match(pattern);
  if (matches) {
    const lat = parseFloat(matches[1]);
    const lng = parseFloat(matches[2]);

    // Validate coordinate ranges
    if (Math.abs(lat) <= 90 && Math.abs(lng) <= 180) {
      hasValidFormat = true;
      coordinates = { latitude: lat, longitude: lng };
      return true; 
    }
  }
  return false; 
});

  
      if (!hasValidFormat) {
        setIsLinkVerified(false);
        return {
          isValid: false,
          message: 'Could not find valid coordinates in the link',
        };
      }
  
      // If all validations pass, set state to verified
      setIsLinkVerified(true);
      return {
        isValid: true,
        message: 'Valid Google Maps link',
        coordinates,
      };
    } catch (error) {
      setIsLinkVerified(false);
      return {
        isValid: false,
        message: 'Error validating link',
      };
    }
  };


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Button type="button" variant="warning" onClick={CurrentLocationHandler}>
            {isEdit ? 'Update Location' : 'Get Location'} 
          </Button>
          <div style={{ marginTop: '20px' }}>
            <label htmlFor="lati">Latitude: <span style={{ color: 'red' }}>*</span></label>

            {isEdit ? (
              <input
                type="text"
                placeholder="Latitude"
                value={restaurantInfo.latitude || ''}
                disabled
                style={{ marginLeft: '10px' }}
              />
) : (
  <input
    type="text"
    placeholder="Latitude"
    value={restaurantInfo.latitude || ''}
    disabled
    style={{ marginLeft: '10px' }}
  />
)}
            
          </div>
          <div style={{ marginTop: '10px' }}>
            <label htmlFor="longi">Longitude: <span style={{ color: 'red' }}>*</span></label>
            {isEdit ? (
              <input
                type="text"
                placeholder="Longitude"
                value={restaurantInfo.longitude || ''}
                disabled
                style={{ marginLeft: '10px' }}
              />
) : (
  <input
    type="text"
    placeholder="Longitude"
    value={restaurantInfo.longitude || ''}
    disabled
    style={{ marginLeft: '10px' }}
  />
)}
            
          </div>
          <div style={{ marginTop: '20px' }}>
            <label htmlFor="maplink">Google Maps Link: <span style={{ color: 'red' }}>*</span></label>
            <div style={{ float: 'right' }}>
              {isLinkverified ? <p>Link verified ✅</p> : <p>Invalid Link❌</p> }
            </div>
            
            <input
              type="text"
              ref={selectref}
              value={mapLinks || ''}
              style={{
                  marginLeft: '10px',
                  width: '100%',
                  marginTop: '5px',
                }}
              onChange={e => setMapLinks(e.target.value)}
              onInput={verifyLinkhandler}
            />
          </div>
          <Button type="button" variant="success" size="sm" className="mt-2" onClick={parseLinkHandler}>
            Parse Link
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

GetLocation.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    maplink: PropTypes.string,
  };
  
  GetLocation.defaultProps = {
    latitude: '',
    longitude: '',
    maplink: '',
  };

export default GetLocation;
