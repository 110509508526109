import React from 'react';
import PastCard from './PastCard';

const Past = () => (
  <div>
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
    <PastCard />
  </div>
  );

export default Past;
