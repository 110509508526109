import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { renderMaskedField } from '@/shared/components/form/FormField';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const PrimaryContactForm = ({ onSubmit }) => {
    const [selectedImage, setSelectedImage] = useState(null);
  
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
  
      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };
  return (
    <Col md={12} lg={12}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit}>
            {/* Primary Contact Details Section */}
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle />
                  <p><span style={{ color: 'red' }}>*{' '}</span>Marks Fields are Required</p>
                </CardTitleWrap>
                {/* First Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userName"
                          component="input"
                          type="text"
                          placeholder="Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Phone Number<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userPhone"
                          component={renderMaskedField}
                          mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          type="text"
                          placeholder="Phone Number"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Second Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Email<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userEmail"
                          component="input"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>UINs #<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="uin"
                          component="input"
                          type="number"
                          placeholder="UIN"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                    
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Logo<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="file"
                          render={({ input }) => (
                            <input
                              type="file"
                              onChange={e => handleFileChange(e, input)}
                              required
                            />
                        )}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {selectedImage && (
                      <FormGroup>
                        <FormGroupLabel>Selected Logo</FormGroupLabel>
                        <FormGroupField>
                          <img
                            src={selectedImage}
                            alt="Selected Logo"
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                            required
                          />
                        </FormGroupField>
                      </FormGroup>
                    )}  
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* Account Details Section */}
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Account Details</CardTitle>
                </CardTitleWrap>
                {/* Third Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accName"
                          component="input"
                          type="text"
                          placeholder="Account Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accCountry"
                          component="input"
                          type="text"
                          placeholder="Country"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Fourth Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accountNo"
                          component="input"
                          type="number"
                          placeholder="Account Number"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accCity"
                          component="input"
                          type="text"
                          placeholder="City"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Fifth Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Confirm Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="conAccount"
                          component="input"
                          type="number"
                          placeholder="Confirm Account"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Branch<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="branch"
                          component="input"
                          type="text"
                          placeholder="Branch"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Sixth Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>IFSC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="ifscCode"
                          component="input"
                          type="text"
                          placeholder="IFSC"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Bank<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="bank"
                          component="input"
                          type="text"
                          placeholder="Bank"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <FormButtonToolbar style={{ float: 'right' }}>
              <Button variant="primary" type="submit">Submit</Button>
            </FormButtonToolbar>
          </FormContainer>
      )}
      </Form>
    </Col>
);
};

PrimaryContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PrimaryContactForm;
