// import React from 'react';
// import PropTypes from 'prop-types';
// import { Row, Col, Container } from 'react-bootstrap';

// const ImageGrid = ({ attachment }) => {
//   const imgPath = 'https://navrang.mvd-tech.io/API/images/restaurant/attachment/';
  
//   return (
//     <Container className="mt-2">
//       <div style={{ maxHeight: '500px', overflowY: 'auto' }}> {/* Add scrollable container */}
//         <Row>
//           {attachment.map(image => (
//             <Col key={image.logo_id} xs={12} sm={6} md={4} lg={3}>
//               <img 
//                 src={`${imgPath}${image.file_name}`} 
//                 alt={image.file_name} 
//                 style={{ width: '100%', height: 'auto', objectFit: 'contain' }} 
//               />
//             </Col>
//           ))}
//         </Row>
//       </div>
//     </Container>
//   );
// };

// ImageGrid.propTypes = {
//   attachment: PropTypes.arrayOf(
//     PropTypes.shape({
//       logo_id: PropTypes.string.isRequired,
//       file_name: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
// };

// export default ImageGrid;
import React, { useState, useEffect, useContext } from 'react';
import { Container, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IoMdCloseCircle } from 'react-icons/io';
import { Button } from '@/shared/components/Button';
import Loading from '@/shared/components/Loading';
import AddAttachment from './AddAttachment';
import { PermissionContext } from '../../../../context/PermissionContext';

const ImageGrid = ({ Locationid }) => {
  const [showAttachmentModal, setShowAtttachmentModal] = useState(false);
  const toggleModalAttachment = () => setShowAtttachmentModal(!showAttachmentModal);
  const [attchments, setAttachment] = useState([]);
  const [isloading, setIsloadng] = useState(true);
  const { permissionsAccess } = useContext(PermissionContext);

  const imgPath = 'https://navrang.mvd-tech.io/API/images/restaurant/attachment/';
  const dummyImage = 'https://via.placeholder.com/164?text=No+Attachment';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_location_data.php?id=${Locationid}`);
        const response = await url.json();
        if (response.status && response.message === 'Success') {
          const parsedAttachment = JSON.parse(response.data[0].attachment);
          setAttachment(parsedAttachment);
          setIsloadng(false);
        }
      } catch (error) {
        setIsloadng(false);
      }
    };

    fetchData();
  }, [Locationid]);

  // Improved CSS styles for better grid layout
  const styles = {
    gridContainer: {
      maxHeight: '400px',
      overflowY: 'auto',
      padding: '16px',
    },
    imageGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '16px',
      padding: '8px',
    },
    imageWrapper: {
      width: '100%',
      aspectRatio: '1',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      transition: 'transform 0.2s ease',
    },
    noImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px',
    },
    addButton: {
      marginTop: '16px',
    },
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <Container>
      <div style={styles.gridContainer}>
        {attchments.length === 0 ? (
          <div style={styles.noImageContainer}>
            <img src={dummyImage} alt="No Attachment" style={styles.image} />
          </div>
        ) : (
          <div style={styles.imageGrid}>
            {attchments.map(image => (
              <div key={image.logo_id} style={styles.imageWrapper}>
                <img
                  src={`${imgPath}${image.file_name}?w=200&h=200&fit=cover&auto=format`}
                  srcSet={`${imgPath}${image.file_name}?w=200&h=200&fit=cover&auto=format&dpr=2 2x`}
                  alt={image.file_name}
                  loading="lazy"
                  style={styles.image}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      
      <Button 
        variant="outline-primary" 
        size="sm" 
        title="Details" 
        onClick={toggleModalAttachment}
        style={styles.addButton}
      >
        <span className="lnr lnr-upload mx-2" /> Add Attachment
      </Button>

      {showAttachmentModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Add Attachment</h3>
                <Button 
                  type="button" 
                  variant="primary" 
                  rounded 
                  size="sm" 
                  onClick={toggleModalAttachment} 
                  aria-label="Close"
                >
                  <IoMdCloseCircle className="close-icon" />
                </Button>
              </div>
              <div className="modal-body">
                <AddAttachment Locationid={Locationid} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

ImageGrid.propTypes = {
  Locationid: PropTypes.string.isRequired,
};

export default ImageGrid;
