import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { AgentContext } from '../../../context/AgentContext';

const AddAgent = ({ onSubmit }) => {
const [selectedImage, setSelectedImage] = useState(null);
const [selectedAdressProof, setSelectedAdressProof] = useState(null);
const [selectedGstProof, setSelectedGSTProof] = useState(null);
const { agentData, setAgentdata } = useContext(AgentContext);


// Handle logo file selection
const handleFileChange = (event, input) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setAgentdata({
        ...agentData,
        logoFilename: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  input.onChange(file);
};

// Handle address proof file selection
const handleAddressProofFileChange = (event, input) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setAgentdata({
        ...agentData,
        gstAddressName: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  input.onChange(file);
};

// Handle GST proof file selection
const handleGstProofFileChange = (event, input) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setAgentdata({
        ...agentData,
        gstProofName: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  input.onChange(file);
};
const required = value => (value ? undefined : 'This field is required');

// Handle geolocation and map link
const CurrentLocationHandler = () => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const maplink = `https://www.google.com/maps?q=${latitude},${longitude}`;
        
        setAgentdata(prevData => ({
          ...prevData,
          latitude,
          longitude,
          maplink,
        }));
      },
      (error) => {
        console.error('Error getting location:', error.message);
      },
    );
  } else {
    console.error('Geolocation is not supported by this browser');
  }
};

// Handle map link parsing
const parseLinkHandler = () => {
  const extractLink = agentData.maplink;
  try {
    let coordinates;
    if (extractLink.includes('?q=')) {
      coordinates = extractLink.split('?q=')[1].split(',');
    } else if (extractLink.includes('@')) {
      coordinates = extractLink.split('@')[1].split(',').slice(0, 2);
    } else {
      throw new Error('Invalid Google Maps link format');
    }

    setAgentdata(prevInfo => ({
      ...prevInfo,
      latitude: parseFloat(coordinates[0]),
      longitude: parseFloat(coordinates[1]),
    }));
  } catch (error) {
    console.error('Error parsing Google Maps link:', error);
    alert('Invalid Google Maps link format');
  }
};

const handleChange = field => (event) => {
  const { value } = event.target;
  setAgentdata(prevState => ({
    ...prevState,
    [field]: value,
  }));
};

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Agent Name</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="agentName"
                        type="text"
                        placeholder="Name"
                        validate={required}
                        value={agentData.agentName} 
                        onChange={handleChange('agentName')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="addressline1"
                        type="text"
                        placeholder="Address line 1"
                        validate={required}
                        value={agentData.addressline1} 
                        onChange={handleChange('addressline1')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="area"
                        type="text"
                        placeholder="Area"
                        validate={required}
                        value={agentData.area} 
                        onChange={handleChange('area')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="state"
                        type="text"
                        placeholder="State"
                        validate={required}
                        value={agentData.state} 
                        onChange={handleChange('state')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="pincode"
                        type="number"
                        placeholder="Pincode"
                        validate={required}
                        value={agentData.pincode} 
                        onChange={handleChange('pincode')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField
                      style={{
                        backgroundColor: '#8080807a',
                        borderRadius: '5px',
                        padding: '5px',
                        border: 'none',
                        marginLeft: '10px',
                    }}
                    >
                      <Field
                        name="file"
                        render={({ input }) => (
                          <div className="row g-2 align-items-center">
                            <div className="col">
                              <input
                                type="file"
                                className="form-control"
                                onChange={e => handleFileChange(e, input)}
                                accept="image/*"
                              />
                            </div>
                            {agentData.logoFilename && (
                              <div className="col-auto">
                                <div 
                                  className="position-relative" 
                                  style={{ 
                                  height: '100px',
                                }}
                                >
                                  <img
                                    src={agentData.logoFilename}
                                    alt="Selected Logo"
                                    style={{ 
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                      backgroundColor: '#f8f9fa',
                                      border: '1px solid #dee2e6',
                                      borderRadius: '4px',
                                      padding: '2px',
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>GST No</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="gst_no"
                        type="text"
                        placeholder="GST No"
                        validate={required}
                        value={agentData.gst_no} 
                        onChange={handleChange('gst_no')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>GST Type</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="gst_type"
                        type="text"
                        placeholder="GST Type"
                        validate={required}
                        value={agentData.gst_type} 
                        onChange={handleChange('gst_type')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {/* Address Proof Upload */}
                  <FormGroup>
                    <FormGroupLabel>Address Proof</FormGroupLabel>
                    <FormGroupField
                      style={{
                        backgroundColor: '#8080807a',
                        borderRadius: '5px',
                        padding: '5px',
                        border: 'none',
                        marginLeft: '10px',
                      }}
                    >
                      <Field
                        name="address_proof"
                        render={({ input }) => (
                          <div className="row g-2 align-items-center">
                            <div className="col">
                              <input
                                type="file"
                                className="form-control"
                                onChange={e => handleAddressProofFileChange(e, input)}
                                accept="image/*"
                              />
                            </div>
                            {agentData.gstAddressName && (
                              <div className="col-auto">
                                <div 
                                  className="position-relative" 
                                  style={{ height: '100px' }}
                                >
                                  <img
                                    src={agentData.gstAddressName}
                                    alt="Selected Address Proof"
                                    style={{ 
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                      backgroundColor: '#f8f9fa',
                                      border: '1px solid #dee2e6',
                                      borderRadius: '4px',
                                      padding: '2px',
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {/* GST Proof Upload */}
                  <FormGroup>
                    <FormGroupLabel>GST Proof</FormGroupLabel>
                    <FormGroupField
                      style={{
                        backgroundColor: '#8080807a',
                        borderRadius: '5px',
                        padding: '5px',
                        border: 'none',
                        marginLeft: '10px',
                      }}
                    >
                      <Field
                        name="gst_proof"
                        render={({ input }) => (
                          <div className="row g-2 align-items-center">
                            <div className="col">
                              <input
                                type="file"
                                className="form-control"
                                onChange={e => handleGstProofFileChange(e, input)}
                                accept="image/*"
                              />
                            </div>
                            {agentData.gstProofName && (
                              <div className="col-auto">
                                <div 
                                  className="position-relative" 
                                  style={{ height: '100px' }}
                                >
                                  <img
                                    src={agentData.gstProofName}
                                    alt="Selected GST Proof"
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                      backgroundColor: '#f8f9fa',
                                      border: '1px solid #dee2e6',
                                      borderRadius: '4px',
                                      padding: '2px',
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                 
                </FormHalfContainer>

                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UAN</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="uan"
                        type="number"
                        placeholder="UAN"
                        validate={required}
                        value={agentData.uan} 
                        onChange={handleChange('uan')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="addressline2"
                        type="text"
                        placeholder="Address Line 2"
                        validate={required}
                        value={agentData.addressline2} 
                        onChange={handleChange('addressline2')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="city"
                        type="text"
                        placeholder="City"
                        validate={required}
                        value={agentData.city} 
                        onChange={handleChange('city')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Select your country</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="country"
                        type="text"
                        placeholder="Country"
                        validate={required}
                        value={agentData.country} 
                        onChange={handleChange('country')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location on Map</FormGroupLabel>
                    <FormGroupField>
                      <Button variant="success" type="button" onClick={CurrentLocationHandler}>Get Location</Button>
                      {agentData.maplink && (
                        <Button 
                          variant="primary" 
                          type="button" 
                          onClick={() => window.open(agentData.maplink, '_blank')}
                        >
                          Go to Map
                        </Button>
                      )}
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupField>
                      <input
                        name="latitude"
                        type="text"
                        placeholder="latitude"
                        style={{ display: 'none' }}
                        readOnly
                        value={agentData.latitude}
                      />
                    </FormGroupField>

                    <FormGroupField>
                      <input
                        name="longitude"
                        type="text"
                        placeholder="longitude"
                        style={{ display: 'none' }}
                        readOnly
                        value={agentData.longitude}
                      />
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>Google Map link</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="mapLink"
                        type="text"
                        placeholder="Google Map link"
                        value={agentData.maplink}
                        onChange={handleChange('maplink')}
                      />
                    </FormGroupField>
                    <Button 
                      variant="warning" 
                      type="button" 
                      onClick={parseLinkHandler}
                      className="mt-2"
                    >
                      Parse Link
                    </Button>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Default Markup</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="markup"
                        type="number"
                        placeholder="Default Markup"
                        validate={required}
                        value={agentData.defaultMarkup} 
                        onChange={handleChange('defaultMarkup')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX Identification</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="taxid"
                        type="text"
                        placeholder="TAX Identification"
                        validate={required}
                        value={agentData.taxId} 
                        onChange={handleChange('taxId')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX City</FormGroupLabel>
                    <FormGroupField>
                      <input
                        name="taxCity"
                        type="text"
                        placeholder="Tax City"
                        validate={required}
                        value={agentData.taxCity} 
                        onChange={handleChange('taxCity')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                    <Button variant="secondary" type="button" onClick={form.reset}>
                      Reset
                    </Button>
                  </FormButtonToolbar>
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

AddAgent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddAgent;
