import React, { useState, useContext } from 'react';
import {
   Nav, Tab, Modal,
} from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import Pending from './Pending';
import Confirm from './Confirmed';
import Past from './Past';



const Tabs = () => {
  const a = 5;
    
  return (
    <>
      {/* Tabs */}
      <Tab.Container defaultActiveKey="2" style={{ marginTop: '500px' }}>
        <TabsWrap>
          
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink eventKey="1">Confirmed</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2">Pending</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="3">Past</NavLink>
            </NavItem>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">

              <Confirm />
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <Pending />
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <Past />
            </Tab.Pane>
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>

     
    </>
  );
};

export default Tabs;
