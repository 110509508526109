import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle } from '@/shared/components/Card';
import Collapse from '@/shared/components/Collapse';
import { borderColor } from 'polished';
import BasicDetails from './INFO/BasicDetails';
import PrimaryDetails from './INFO/PrimaryDetails';
import GSTDetails from './INFO/GSTDetails';

const AgentInfo = ({ agentId }) => {
   const [basicAgent, setBasicAgent] = useState({});
    const [contactAgent, setContactAgent] = useState({});
    const [gstDetails, setGSTDetails] = useState({});




  const fetchAgentData = async () => {  
    try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/agent_data_fetch.php?id=${agentId}`);
        const data = await url.json();
        if (data.status && data.message === 'Success') {
            setBasicAgent({
                name: data.data[0].name || 'NA',
                email: data.data[0].user_email || 'NA',
                area: data.data[0].area || 'NA',
                city: data.data[0].city || 'NA',
                state: data.data[0].state || 'NA',
                pincode: data.data[0].pincode || 'NA',
                country: data.data[0].country || 'NA',
                address1: data.data[0].address1 || 'NA',
                address2: data.data[0].address2 || 'NA',
                logo: data.data[0].logo || 'NA',
                uan: data.data[0].uan || 'NA',
                tin: data.data[0].tax_id || 'NA',
                taxCity: data.data[0].tax_city || 'NA',
              });
              
              setContactAgent({
                name: data.data[0].user_name || 'NA',
                email: data.data[0].user_email || 'NA',
                phone: data.data[0].user_phone || 'NA',
                uin: data.data[0].uin || 'NA',
                accName: data.data[0].acc_name || 'NA',
                accCountry: data.data[0].acc_country || 'NA',
                accCity: data.data[0].acc_city || 'NA',
                accNumber: data.data[0].acc_no || 'NA',
                accBranch: data.data[0].branch || 'NA',
                accIfsc: data.data[0].ifsc || 'NA',
                accBank: data.data[0].bank || 'NA',
              });
              
              setGSTDetails({
                gstNo: data.data[0].gst_no || 'NA',
                gstType: data.data[0].gst_type || 'NA',
                gstProof: data.data[0].gst_proof_file || 'NA',
                addressProof: data.data[0].address_proof_file || 'NA',
              });
        }
    } catch (e) {
        console.error(e);
    }
  };

  useEffect(() => {
    fetchAgentData();
  }, [agentId]);






//   const basicDetails = {
//     title: t('ui_elements.collapse.basic_details'),
//     text: 'Here are the basic details of the user.',
//   };

//   const primaryContactDetails = {
//     title: t('ui_elements.collapse.primary_contact_details'),
//     text: 'Here are the primary contact details of the user.',
//   };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          
          {/* Basic Details Section */}
          <Collapse title="Basic Agent Details" className="with-shadow">
            <BasicDetails basicAgent={basicAgent} />
          </Collapse>

          {/* GST Details Section */}
          <Collapse title="GST Details" className="with-shadow">
            <GSTDetails gstDetails={gstDetails} />
          </Collapse>
          
          {/* Primary Contact Details Section */}
          <Collapse title="Primary contact Details" className="with-shadow">
            <PrimaryDetails contactAgent={contactAgent} />
          </Collapse>
          
        </CardBody>
      </Card>
    </Col>
  );
};

AgentInfo.propTypes = {
    agentId: PropTypes.string.isRequired,
};

export default AgentInfo;
