import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import {
  Card, CardBody, CardTitleWrap,
} from '@/shared/components/Card';
import TimePicker from 'rc-time-picker';
import { renderMaskedField } from '@/shared/components/form/FormField';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';
import GetLocation from './FormCard/GetLocation';

const EditRestaurantLocation = ({ id, onSubmit }) => {
  const [prevData, setPrevData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [time, setTime] = useState({ startTime: '', endTime: '' });

  const fetchdata = async () => {
    try {
      const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_location_data.php?id=${id}`);
      const response = await url.json();

      if (response.status && response.message === 'Success' && response?.data?.[0]) {
        const data = response.data[0];
        console.log(data);

        data.start_time = moment(data.start_time, 'HH:mm:ss');
        data.end_time = moment(data.end_time, 'HH:mm:ss');
        setPrevData(data);
        // setTime({
        //   startTime: data.start_time || '',
        //   endTime: data.end_time || '',
        // });
      } else {
        setPrevData(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
  }, [id]);

  const getMealOfferedChecked = (mealOffered, type) => {
    const mealMap = {
      breakfast: 1,
      snacks: 2,
      lunch: 3,
      dinner: 4,
    };
    return mealOffered && mealOffered.includes(mealMap[type]);
  };

  const getMealFormatChecked = (mealFormat, type) => {
    const mealMap = {
      fit: 1,
      git: 2,
      delivery: 3,
    };
    return mealFormat && mealFormat.includes(mealMap[type]);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const handleTimeOnchange = (value, timeIndex) => {
     if (timeIndex === '1') {
      setTime(ct => ({
        ...ct, startTime: value,
      }));
     } else {
      setTime(ct => ({
        ...ct, endTime: value,
      }));
     }

    console.log(value);
  };


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
          </CardTitleWrap>
          <Form 
            onSubmit={onSubmit} 
            initialValues={prevData}
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Restaurant Name</FormGroupLabel>
                    <FormGroupField>

                      <Field
                        name="id"
                        component="input"
                        type="number"
                        placeholder="UID"
                        style={{ display: 'none' }}
                      />
                      <Field
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Restaurant Name"
                        disabled

                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address1"
                        component="input"
                        type="text"
                        placeholder="Address Line 1"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component={renderMaskedField}
                        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        type="text"
                        placeholder="Pincode"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Discription</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="description"
                        component="textarea"
                        placeholder="Description"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Setting Capacity<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="setting_capacity"
                        component="input"
                        type="number"
                        placeholder="Setting Capacity"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                        initialValue={getMealOfferedChecked(prevData.meal_offered, 'breakfast')}
                        
                      />
                      <Field
                        name="snacks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                        initialValue={getMealOfferedChecked(prevData.meal_offered, 'snacks')}
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"
                        initialValue={getMealOfferedChecked(prevData.meal_offered, 'lunch')}
                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                        initialValue={getMealOfferedChecked(prevData.meal_offered, 'dinner')}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal format <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="fit"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Fit"
                        initialValue={getMealFormatChecked(prevData.meal_format, 'fit')}
                      />
                      <Field
                        name="git"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Git"
                        initialValue={getMealFormatChecked(prevData.meal_format, 'git')}

                      />
                      <Field
                        className="form-check-input"
                        name="delivery"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Delivery"
                        initialValue={getMealFormatChecked(prevData.meal_format, 'delivery')}

                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Capping Capacity *</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="capping_capacity"
                        component="input"
                        type="number"
                        placeholder="Capping Capacity"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="location"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UIN<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uin"
                        component="input"
                        type="number"
                        placeholder="UIN"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroupLabel>Location Details<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                  <br />
                  <GetLocation 
                    isEdit
                    latitude={prevData.latitude}
                    longitude={prevData.longitude}
                    maplink={prevData.map_location} 
                  />


                  <FormGroup>
                    <FormGroupLabel>Cuisines<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Tax identification #<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="tax_id"
                        component="input"
                        type="text"
                        placeholder="Tax identification"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Timing <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <FormGroupLabel className="mt-2 mx-2">From</FormGroupLabel>
                      <Field
                        name="start_time"
                        render={({ input }) => (
                          <TimePicker
                            {...input}
                            showSecond={false}
                            format="HH:mm"
                            // value={input.value ? moment(input.value, 'HH:mm') : moment(time.startTime, 'HH:mm')}
                            // value={time.startTime ? moment(time.startTime, 'HH:mm')
                            //  : moment(time.startTime, 'HH:mm')}
                            // onChange={handleTimeOnchange(input.value, 1)}
                          />
                        )}
                      />
                      <FormGroupLabel className="mt-2 mx-2">to</FormGroupLabel>
                      <Field
                        name="end_time"
                        render={({ input }) => (
                          <TimePicker
                            {...input}
                            showSecond={false}
                            format="HH:mm"
                            // value={input.value ? moment(input.value, 'HH:mm') : moment(time.endTime, 'HH:mm')}
                            // value={time.endTime ? moment(time.endTime, 'HH:mm') : moment(time.endTime, 'HH:mm')}
                            // onChange={value => input.onChange(value ? value.format('HH:mm') : '')}
                            // onChange={handleTimeOnchange(input.value, 2)}
                          />
                        )}
                      /> 
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Markup Percentage</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup_percentage"
                        type="number"
                        component="input"
                        placeholder="Markup Percentage"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                    {/* <Button variant="danger" type="reset">
                      Reset
                    </Button> */}
                  </FormButtonToolbar>
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

EditRestaurantLocation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default EditRestaurantLocation;
