
// import React, {
//    useState,
//     useEffect,
//      useContext,
//      useRef,
//      } from 'react';
// import { Field, Form } from 'react-final-form';
// import Select from 'react-select';
// import makeAnimated from 'react-select/animated';
// import { Col } from 'react-bootstrap';
// import {
//   Card, CardBody, CardTitleWrap,
// } from '@/shared/components/Card';
// import { CiCirclePlus } from 'react-icons/ci';
// import DatePicker from 'react-datepicker';
// import {
//   FormButtonToolbar,
//   FormContainer,
//   FormGroup,
//   FormGroupField,
//   FormGroupLabel,
//   FormHalfContainer,
// } from '@/shared/components/form/FormElements';
// import { parseISO } from 'date-fns';
// import renderCheckBoxField from '@/shared/components/form/CheckBox';
// import renderRadioButtonField from '@/shared/components/form/RadioButton';
// import { Button } from '@/shared/components/Button';
// import { IoIosRemoveCircle } from 'react-icons/io';
// import PropTypes from 'prop-types';
// import { Table } from '@/shared/components/TableElements';
// import toast from 'react-hot-toast';
// import { Mealcontext } from '../../../../context/MealContext';

// const AddEditMeal = ({ 
//   onSubmit, 
//   id, 
//   setRestaurantSelectOption, 
// }) => {
//   const {
//     meals,
//     setMeals,
//     setSelectedId,
//   } = useContext(Mealcontext);
  
//   // for Store initial Value Given By APi
//   const [prevData, setPrevData] = useState(null);

//   // for Handle Image
//   const [selectedImage, setSelectedImage] = useState(null);
  
//   // for store all fetch Location In Initial Mount
//   const [fetchLocationName, setFetchLocationName] = useState([]);

//   // for store fetch Restaurant Name 
//   const [fetchRestaurantName, setFetchRestaurantName] = useState([]);
   

//   const [selectRestaurant, setSelectRestaurant] = useState(null);

//   const [LocationsNames, setLocationsNames] = useState([]);
//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const selectRef = useRef(null);
//   const isMounted = useRef(true);

//   // console.log(startDate);
//   // console.log(endDate);



//   // fetch location Option in Intial Mount Component ✅16-12-2024
//   const fetchLocationOptionOnInitialMount = async (value) => {
//     try {
//       const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=2&id=${value}`);
//       const response = await url.json();
//       // console.log(response);
      
//       if (response.status && response.message === 'Success') {
//         setFetchLocationName(response.data.map(item => ({
//           value: item.id,
//           label: item.restaurant_name,
//         })));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
  

//   const convertDateFormat = (dateString) => {
//     // Check if the date string uses '/' or '-'
//     const separator = dateString.includes('/') ? '/' : '-';
  
//     // Split the string based on the separator
//     const parts = dateString.split(separator);
//     const [day, month, year] = separator === '/' ? parts : [parts[0], parts[1], parts[2]];
//     return `${year}-${month}-${day}`;
//   };
  


//   useEffect(() => {
//     const fetchDataForEdit = async () => {
//       try {
//         const fetchMeal = await fetch(`https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${id}`);
//         const res = await fetchMeal.json();

//         if (res.status && res.message === 'Success') {
//           const mealData = res.data[0];
//           // console.log(mealData);
//           const formattedStartDate = convertDateFormat(mealData.start_date); 
//           const formattedEndDate = convertDateFormat(mealData.end_date); 
            
//           console.log(formattedStartDate); // o/p: 22-12-2024
//           console.log(formattedEndDate); // o/p: 16-12-2025

//           if (isMounted.current) { 
//             const parsedStartDate = parseISO(formattedStartDate); 
//             const parsedEndDate = parseISO(formattedEndDate); 

//             console.log(parsedStartDate);// invalid
//             console.log(parsedEndDate);

//             setStartDate(parsedStartDate); 
//             setEndDate(parsedEndDate);

//             setPrevData({ ...mealData, start_date: parsedStartDate, end_date: parsedEndDate });
//             setRestaurantSelectOption({
//               value: mealData.restaurant_id,
//               label: mealData.restaurant_name,
//             });
//             fetchLocationOptionOnInitialMount(mealData.restaurant_id);
//             setSelectRestaurant({
//               value: mealData.restaurant_id,
//               label: mealData.restaurant_name,
//             });
//             setSelectedId(mealData.restaurant_id);

//             setLocationsNames(
//               res.data[0].locations.map(location => ({
//                 value: location.location_id,
//                 label: location.location_name,
//               })),
//             );

//             try {
//               const itemsArray = JSON.parse(mealData.items);
//               if (isMounted.current) {
//                 setMeals(itemsArray);
//               }
//             } catch (e) {
//               toast.error('Failed to parse meal items.');
//             }

//             if (mealData.photo && isMounted.current) {
//               setSelectedImage(`https://navrang.mvd-tech.io/API/images/meal/${mealData.photo}`);
//             }
//           }
//         } else {
//           toast.error('Failed to fetch meal data for edit.');
//         }
//       } catch (error) {
//         toast.error(`Failed to fetch: ${error.message}`);
//       }
//     };

//     fetchDataForEdit();

//     // Cleanup function to avoid memory leaks
//     return () => {
//       isMounted.current = false;
//     };
//   }, [id]); // Re-run effect when `id` changes
 



//   useEffect(() => {
//     let isMount = true;
  
//     const fetchRestaurant = async () => {
//       try {
//         const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=1');
//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }
//         const data = await response.json();
//         if (data.status && data.message === 'Success') {
//           if (isMount) {
//             setFetchRestaurantName(data.data.map(item => (
//               { value: item.restaurant_id, label: item.restaurant_name })));
//           }
//         }
//       } catch (error) {
//         console.error('There has been a problem with your fetch operation:', error);
//       }
//     };
  
//     fetchRestaurant();
//     return () => {
//       isMount = false;
//     };
//   }, []);
  



//   const handleFileChange = (event, input) => {
//     const file = event.target.files[0];
//     if (file && file.type.startsWith('image/')) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setSelectedImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//       input.onChange(file);
//     } else {
//       setSelectedImage(null);
//       input.onChange(null);
//     }
//   };

//   const handleAddMeal = (values) => {
//     const newMeal = {
//       item_id: meals.length + 1,
//       name: values.Item,
//       description: values.desc,
//     };
//     setMeals(prevMeals => [...prevMeals, newMeal]);
//   };


//   const getMealOfferedChecked = (mealOffered, type) => {
//     const mealMap = {
//       breakfast: 1,
//       snacks: 2,
//       lunch: 3,
//       dinner: 4,
//     };
//     return mealOffered && mealOffered.includes(mealMap[type]);
//   };


//   const getMealFormatChecked = (mealFormat, type) => {
//     const mealMap = {
//       1: 'fit',
//       2: 'git',
//       3: 'delivery',
//     };

//     return mealFormat && mealMap[mealFormat] === type;
//   };

//   const getMealvegChecked = (mealveg, type) => {
//     const mealMap = {
//       1: 'veg',
//       2: 'nonveg',
//       3: 'vegNonveg',
//     };

//     return mealveg && mealMap[mealveg] === type;
//   };
  

//   // function for load location after every restaurant OnChnage
//   const handleSelectoptionChange = async (option) => {
//     if (selectRef.current) {
//           selectRef.current.select.setValue([]);
//         }

//     setSelectRestaurant(option);
//     setRestaurantSelectOption(option);
//     if (option.value) {
//           try {
//             const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_load.php?
//               type=2&id=${option.value}`);
//             const response = await url.json();
//             if (response.status && response.message === 'Success') {
//               setFetchLocationName(response.data.map(item => ({ value: item.id, label: item.restaurant_name })));
//             }
//             } catch (error) {
//             console.log(error);
//           }
//         } else {
//           console.error('No restaurant Select');
//         }
//   };

//   useEffect(() => {
//     setPrevData({ ...prevData, start_date: startDate, end_date: endDate });
//   }, [startDate, endDate]);

  
//   // const breakfastChecked = getMealOfferedChecked(prevData.meal_offered, 'breakfast');
//   // const snaksChecked = getMealOfferedChecked(prevData.meal_offered, 'snacks');
//   // const lunchChecked = getMealOfferedChecked(prevData.meal_offered, 'lunch');
//   // const dinnerChecked = getMealOfferedChecked(prevData.meal_offered, 'dinner');
 
//   // // console.log(getMealOfferedChecked(prevData.meal_offered, 'snacks'));
//   // // console.log(getMealOfferedChecked(prevData.meal_offered, 'lunch'));
//   // // console.log(getMealOfferedChecked(prevData.meal_offered, 'dinner'));


  

//   return (
//     <Col md={12} lg={12}>
//       <Card>
//         <CardBody>
//           <CardTitleWrap>
//             <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>

//           </CardTitleWrap>
//           {prevData ? ( 
//             <Form onSubmit={onSubmit} initialValues={prevData}>
//               {({ handleSubmit, form }) => (
//                 <FormContainer onSubmit={handleSubmit}>
//                   <FormHalfContainer>
//                     <FormGroup>
//                       <FormGroupLabel>Select Restaurant<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <Field
//                         name="restaurantid"
//                         render={({ input }) => (
//                           <Select
//                             {...input}
//                             name="restaurantid"
//                             className="react-select"
//                             classNamePrefix="react-select"
//                             options={fetchRestaurantName}
//                             value={selectRestaurant}
//                             onChange={handleSelectoptionChange}
//                             defaultValue={selectRestaurant}
//                             isClearable={false}
//                             required
//                           />
//                         )}
                        
//                       />
//                     </FormGroup>
//                     <FormGroup>
//                       <FormGroupLabel>Select Location<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <Field
//                         name="locationsid"
//                         render={({ input }) => (
//                           <Select
//                             {...input}
//                             name="locationsid"
//                             ref={selectRef}
//                             components={makeAnimated}
//                             className="react-select"
//                             classNamePrefix="react-select"
//                             isMulti
//                             value={LocationsNames} 
//                             options={fetchLocationName}
//                             onChange={(selected) => {
//                               setLocationsNames(selected); 
//                               input.onChange(selected);
//                             }}
//                             isClearable 
//                             placeholder="Select a Location"
//                             required
//                           />
//                         )}
                        
//                       />
//                     </FormGroup>



//                     <FormGroup>
//                       <FormGroupLabel>Meal Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="id"
//                           component="input"
//                           type="number"
//                           placeholder="UID"
//                           style={{ display: 'none' }}
//                         />
//                         <Field
//                           name="name"
//                           component="input"
//                           type="text"
//                           placeholder="Meal Name"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>
//                     <FormGroup>
//                       <FormGroupLabel>Meal Price<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="price"
//                           component="input"
//                           type="number"
//                           placeholder="Meal Price"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>
//                     <FormGroup>
//                       <FormGroupLabel>FOC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="foc"
//                           component="input"
//                           type="text"
//                           placeholder="Foc"
//                           className="w-60"
//                           required
//                         />
//                         {' '}on
//                         <Field
//                           name="foc_on"
//                           component="input"
//                           type="text"
//                           placeholder="Foc on"
//                           className="w-60"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {/* Meal Items Table */}
//                     <FormGroup>
//                       <Table responsive hover>
//                         <thead>
//                           <tr>
//                             <th>#</th>
//                             <th>Item</th>
//                             <th>Description</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {meals.map((meal, index) => (
//                             <tr key={meal.item_id}>
//                               <td>{index + 1}</td>
//                               <td>{meal.name}</td>
//                               <td>{meal.description}</td>
//                               <td>
//                                 <Button
//                                   type="button"
//                                   variant="outline-danger"
//                                   size="sm"
//                                   className="mt-2"
//                                   onClick={() => setMeals(meals.filter(m => m.item_id !== meal.item_id))}
//                                 >
//                                   <IoIosRemoveCircle />
//                                 </Button>
//                               </td>
//                             </tr>
//     ))}
//                         </tbody>
//                         <tfoot>
//                           <tr>
//                             <th>
//                               <FormGroupLabel>Item</FormGroupLabel>
//                               <Field
//                                 name="Item"
//                                 component="input"
//                                 type="text"
//                                 placeholder="Item"
//                               />
//                             </th>
//                             <th>
//                               <FormGroupLabel>Description</FormGroupLabel>
//                               <Field
//                                 name="desc"
//                                 component="input"
//                                 type="text"
//                                 placeholder="Description"
//                               />
//                             </th>
//                             <th>
//                               <Button
//                                 type="button"
//                                 variant="outline-success"
//                                 rounded
//                                 size="sm"
//                                 style={{ marginTop: '45px' }}
//                                 onClick={() => {
//             const itemValue = document.querySelector('[name="Item"]').value;
//             const descValue = document.querySelector('[name="desc"]').value;

//             handleAddMeal({
//               Item: itemValue,
//               desc: descValue,
//             });

//             form.change('Item', '');
//             form.change('desc', '');
//           }}
//                               >
//                                 <CiCirclePlus />
//                               </Button>
//                             </th>
//                           </tr>
//                         </tfoot>
//                       </Table>

//                     </FormGroup>

//                     {/* Meal Offered Checkboxes */}
//                     <FormGroup>
//                       <FormGroupLabel>Meal Offered</FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="breakfast"
//                           type="checkbox"
//                           component={renderCheckBoxField}
//                           label="Breakfast"
//                           initialValue={getMealOfferedChecked(prevData?.meal_offered, 'breakfast') ? '1' : undefined}
                          
//                         />
//                         <Field
//                           name="snacks"
//                           type="checkbox"
//                           component={renderCheckBoxField}
//                           label="Snacks"
//                           // initialValue={getMealOfferedChecked(prevData.meal_offered, 'snacks')}
//                           initialValue={getMealOfferedChecked(prevData?.meal_offered, 'snacks') ? '2' : undefined}
//                         />
//                         <Field
//                           name="lunch"
//                           type="checkbox"
//                           component={renderCheckBoxField}
//                           label="Lunch"
//                           // initialValue={getMealOfferedChecked(prevData.meal_offered, 'lunch')}
//                           initialValue={getMealOfferedChecked(prevData?.meal_offered, 'lunch') ? '3' : undefined}
//                         />
//                         <Field
//                           name="dinner"
//                           type="checkbox"
//                           component={renderCheckBoxField}
//                           label="Dinner"
//                           // initialValue={getMealOfferedChecked(prevData.meal_offered, 'dinner')}
//                           initialValue={getMealOfferedChecked(prevData?.meal_offered, 'dinner') ? '4' : undefined}
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {/* Additional Fields */}
//                     <FormGroup>
//                       <FormGroupLabel>Meal format</FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="format"
//                           component={renderRadioButtonField}
//                           label="Fit"
//                           radioValue="1"
//                           initialValue={getMealFormatChecked(prevData?.meal_format, 'fit') ? '1' : undefined}
//                         />
//                         <Field
//                           name="format"
//                           component={renderRadioButtonField}
//                           label="Git"
//                           radioValue="2"
//                           initialValue={getMealFormatChecked(prevData?.meal_format, 'git') ? '2' : undefined}
//                         />
//                         <Field
//                           name="format"
//                           component={renderRadioButtonField}
//                           label="Delivery"
//                           radioValue="3"
//                           initialValue={getMealFormatChecked(prevData?.meal_format, 'delivery') ? '3' : undefined}
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {/* Veg/Non-veg Radio Buttons */}
//                     <FormGroup>
//                       <FormGroupLabel>Veg/Non-veg</FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="veg"
//                           component={renderRadioButtonField}
//                           label="Veg"
//                           radioValue="1"
//                           initialValue={getMealvegChecked(prevData?.veg_non_veg, 'veg') ? '1' : undefined}
//                         />
//                         <Field
//                           name="veg"
//                           component={renderRadioButtonField}
//                           label="Non-veg"
//                           radioValue="2"
//                           initialValue={getMealvegChecked(prevData?.veg_non_veg, 'nonveg') ? '2' : undefined}
//                         />
//                         <Field
//                           name="veg"
//                           component={renderRadioButtonField}
//                           label="Veg/Non-veg"
//                           radioValue="3"
//                           initialValue={getMealvegChecked(prevData?.veg_non_veg, 'vegNonveg') ? '3' : undefined}
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {/* Cuisines */}
//                     <FormGroup>
//                       <FormGroupLabel>Cuisines<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="cuisines"
//                           component="input"
//                           type="text"
//                           placeholder="Cuisines"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {/* Meal type */}
//                     <FormGroup>
//                       <FormGroupLabel>Meal type<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="meal_type"
//                           component="input"
//                           type="text"
//                           placeholder="Meal Type"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>
//                   </FormHalfContainer>

//                   {/* File Upload, Date Picker and Markup Percentage */}
//                   <FormHalfContainer>
//                     <FormGroup>
//                       <FormGroupLabel>Description</FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="description"
//                           component="textarea"
//                           placeholder="Description"
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     <FormGroup>
//                       <FormGroupLabel>Logo</FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="logo"
//                           render={({ input }) => (
//                             <input
//                               type="file"
//                               onChange={e => handleFileChange(e, input)}
//                             />
//                           )}
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     {selectedImage && (
//                       <FormGroup>
//                         <FormGroupLabel>Selected Logo</FormGroupLabel>
//                         <FormGroupField>
//                           <img
//                             src={selectedImage}
//                             alt="Selected Logo"
//                             style={{ maxWidth: '200px', maxHeight: '200px' }}
//                           />
//                         </FormGroupField>
//                       </FormGroup>
//                     )}
//                     <FormGroup>
//                       <FormGroupLabel>
//                         Start Date<span style={{ color: 'red' }}>*</span>
//                       </FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="start_date"
//                           render={({ input }) => (
//                             <DatePicker
//                               {...input}
//                               dateFormat="dd-MM-yyyy"
//                               selected={startDate || input.value} 
//                               // onChange={date => input.onChange(date)} 
//                               // selected={startDate}
//                               onChange={date => setStartDate(date)}
//                               value={startDate}
//                             />
//       )}
//                           initialValue={startDate}
//                         />
//                       </FormGroupField>
//                     </FormGroup>

//                     <FormGroup>
//                       <FormGroupLabel>
//                         End Date<span style={{ color: 'red' }}>*</span>
//                       </FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="end_date"
//                           render={({ input }) => (
//                             <DatePicker
//                               {...input}
//                               dateFormat="dd-MM-yyyy"
//                               selected={endDate || input.value} 
//                               // onChange={date => input.onChange(date)} 
//                               // selected={endDate} 
//                               onChange={date => setEndDate(date)}
//                               value={endDate}
//                             />
//       )}
//                           initialValue={endDate}
//                         />
//                       </FormGroupField>
//                     </FormGroup> 



//                     <FormGroup>
//                       <FormGroupLabel>Markup Percentage<span style={{ color: 'red' }}>*</span></FormGroupLabel>
//                       <FormGroupField>
//                         <Field
//                           name="markup_percentage"
//                           component="input"
//                           type="number"
//                           placeholder="Markup Percentage"
//                           required
//                         />
//                       </FormGroupField>
//                     </FormGroup>

                    
//                   </FormHalfContainer>
//                   <FormButtonToolbar>
//                     <Button variant="primary" type="submit">
//                       Submit
//                     </Button>
//                     {/* <Button variant="secondary" type="button">
//                         Reset
//                       </Button> */}
//                   </FormButtonToolbar>
//                 </FormContainer>
//               )}
//             </Form>
//           ) : (
//             <p>Loading data...</p> 
//           )}
//         </CardBody>
//       </Card>
//     </Col>
//   );
// };

// AddEditMeal.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
//   id: PropTypes.string.isRequired,
//   setRestaurantSelectOption: PropTypes.func.isRequired,
//   setDate: PropTypes.func.isRequired,
// };

// export default AddEditMeal;


import React, {
  useState,
   useEffect,
    useContext,
    useRef,
    } from 'react';
import { Field, Form } from 'react-final-form';
import Select from 'react-select';
import { Col } from 'react-bootstrap';
import {
 Card, CardBody, CardTitleWrap,
} from '@/shared/components/Card';
import { CiCirclePlus } from 'react-icons/ci';
import DatePicker from 'react-datepicker';
import {
 FormButtonToolbar,
 FormContainer,
 FormGroup,
 FormGroupField,
 FormGroupLabel,
 FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { parseISO } from 'date-fns';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import { Button } from '@/shared/components/Button';
import { IoIosRemoveCircle } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Table } from '@/shared/components/TableElements';
import toast from 'react-hot-toast';
import { Mealcontext } from '../../../../context/MealContext';

const AddEditMeal = ({ 
 onSubmit, 
 id, 
 setRestaurantSelectOption, 
}) => {
 const {
   meals,
   setMeals,
   setSelectedId,
 } = useContext(Mealcontext);
 
 // for Store initial Value Given By APi
 const [prevData, setPrevData] = useState(null);

 // for Handle Image
 const [selectedImage, setSelectedImage] = useState(null);
 
 // for store all fetch Location In Initial Mount
 const [fetchLocationName, setFetchLocationName] = useState([]);

 // for store fetch Restaurant Name 
 const [fetchRestaurantName, setFetchRestaurantName] = useState([]);
  

 const [selectRestaurant, setSelectRestaurant] = useState(null);

 const [LocationsNames, setLocationsNames] = useState([]);
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState(new Date());
 const selectRef = useRef(null);
 const isMounted = useRef(true);

 // console.log(startDate);
 // console.log(endDate);



 // fetch location Option in Intial Mount Component ✅16-12-2024
 const fetchLocationOptionOnInitialMount = async (value) => {
   try {
     const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=2&id=${value}`);
     const response = await url.json();
     // console.log(response);
     
     if (response.status && response.message === 'Success') {
       setFetchLocationName(response.data.map(item => ({
         value: item.id,
         label: item.restaurant_name,
       })));
     }
   } catch (error) {
     console.log(error);
   }
 };
 

 const convertDateFormat = (dateString) => {
   // Check if the date string uses '/' or '-'
   const separator = dateString.includes('/') ? '/' : '-';
 
   // Split the string based on the separator
   const parts = dateString.split(separator);
   const [day, month, year] = separator === '/' ? parts : [parts[0], parts[1], parts[2]];
   return `${year}-${month}-${day}`;
 };
 
 const getMealOfferedChecked = (mealOffered, type) => {
  const mealMap = {
    breakfast: 1,
    snacks: 2,
    lunch: 3,
    dinner: 4,
  };

  return mealOffered && mealOffered.includes(mealMap[type]);
};

const getMealFormatChecked = (mealFormat, type) => {
  const mealMap = {
    1: 'fit',
    2: 'git',
    3: 'delivery',
  };

  return mealFormat && mealMap[mealFormat] === type;
};

const getMealvegChecked = (mealveg, type) => {
  const mealMap = {
    1: 'veg',
    2: 'nonveg',
    3: 'vegNonveg',
  };
  return mealveg && mealMap[mealveg] === type;
};



 useEffect(() => {
   const fetchDataForEdit = async () => {
     try {
       const fetchMeal = await fetch(`https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${id}`);
       const res = await fetchMeal.json();

       if (res.status && res.message === 'Success') {
         const mealData = res.data[0];
         console.log(mealData);
         const formattedStartDate = convertDateFormat(mealData.start_date); 
         const formattedEndDate = convertDateFormat(mealData.end_date); 
         if (isMounted.current) { 
           const parsedStartDate = parseISO(formattedStartDate); 
           const parsedEndDate = parseISO(formattedEndDate); 


           setStartDate(parsedStartDate); 
           setEndDate(parsedEndDate);

           let format;

if (mealData.format) {
  format = '1';
} else if (getMealFormatChecked(mealData?.meal_format, 'fit')) {
  format = '1';
} else if (getMealFormatChecked(mealData?.meal_format, 'git')) {
  format = '2';
} else if (getMealFormatChecked(mealData?.meal_format, 'delivery')) {
  format = '3';
} else {
  format = undefined;
}


let vegNonVeg;

const mealType = mealData?.veg_non_veg;

switch (true) {
  case mealType && getMealFormatChecked(mealType, 'veg'):
    vegNonVeg = '1';
    break;
  case mealType && getMealFormatChecked(mealType, 'nonveg'):
    vegNonVeg = '2';
    break;
  case mealType && getMealFormatChecked(mealType, 'vegNonveg'):
    vegNonVeg = '3';
    break;
  default:
    vegNonVeg = undefined;
}


           setPrevData({
             ...mealData, 
             start_date: parsedStartDate, 
             end_date: parsedEndDate,
             breakfast: getMealOfferedChecked(mealData.meal_offered, 'breakfast'),
            snacks: getMealOfferedChecked(mealData.meal_offered, 'snacks'),
            lunch: getMealOfferedChecked(mealData.meal_offered, 'lunch'),
            dinner: getMealOfferedChecked(mealData.meal_offered, 'dinner'), 
            format,
            veg: vegNonVeg,
            
            
            });
           setRestaurantSelectOption({
             value: mealData.restaurant_id,
             label: mealData.restaurant_name,
           });
           fetchLocationOptionOnInitialMount(mealData.restaurant_id);
           setSelectRestaurant({
             value: mealData.restaurant_id,
             label: mealData.restaurant_name,
           });
           setSelectedId(mealData.restaurant_id);

           setLocationsNames(
             res.data[0].locations.map(location => ({
               value: location.location_id,
               label: location.location_name,
             })),
           );

           try {
             const itemsArray = JSON.parse(mealData.items);
             if (isMounted.current) {
               setMeals(itemsArray);
             }
           } catch (e) {
             toast.error('Failed to parse meal items.');
           }

           if (mealData.photo && isMounted.current) {
             setSelectedImage(`https://navrang.mvd-tech.io/API/images/meal/${mealData.photo}`);
           }
         }
       } else {
         toast.error('Failed to fetch meal data for edit.');
       }
     } catch (error) {
       toast.error(`Failed to fetch: ${error.message}`);
     }
   };

   fetchDataForEdit();

   // Cleanup function to avoid memory leaks
   return () => {
     isMounted.current = false;
   };
 }, [id]); // Re-run effect when `id` changes




 useEffect(() => {
   let isMount = true;
 
   const fetchRestaurant = async () => {
     try {
       const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=1');
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const data = await response.json();
       if (data.status && data.message === 'Success') {
         if (isMount) {
           setFetchRestaurantName(data.data.map(item => (
             { value: item.restaurant_id, label: item.restaurant_name })));
         }
       }
     } catch (error) {
       console.error('There has been a problem with your fetch operation:', error);
     }
   };
 
   fetchRestaurant();
   return () => {
     isMount = false;
   };
 }, []);
 



 const handleFileChange = (event, input) => {
   const file = event.target.files[0];
   if (file && file.type.startsWith('image/')) {
     const reader = new FileReader();
     reader.onloadend = () => {
       setSelectedImage(reader.result);
     };
     reader.readAsDataURL(file);
     input.onChange(file);
   } else {
     setSelectedImage(null);
     input.onChange(null);
   }
 };

 const handleAddMeal = (values) => {
   const newMeal = {
     item_id: meals.length + 1,
     name: values.Item,
     description: values.desc,
   };
   setMeals(prevMeals => [...prevMeals, newMeal]);
 };

 

 // function for load location after every restaurant OnChnage
 const handleSelectoptionChange = async (option) => {
   if (selectRef.current) {
         selectRef.current.select.setValue([]);
       }

   setSelectRestaurant(option);
   setRestaurantSelectOption(option);
   if (option.value) {
         try {
           const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_load.php?
             type=2&id=${option.value}`);
           const response = await url.json();
           if (response.status && response.message === 'Success') {
             setFetchLocationName(response.data.map(item => ({ value: item.id, label: item.restaurant_name })));
           }
           } catch (error) {
           console.log(error);
         }
       } else {
         console.error('No restaurant Select');
       }
 };

 useEffect(() => {
   setPrevData({ ...prevData, start_date: startDate, end_date: endDate });
 }, [startDate, endDate]);




 
console.log(prevData);
 return (
   <Col md={12} lg={12}>
     <Card>
       <CardBody>
         <CardTitleWrap>
           <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>

         </CardTitleWrap>
         {prevData ? ( 
           <Form onSubmit={onSubmit} initialValues={prevData}>
             {({ handleSubmit, form }) => (
               <FormContainer onSubmit={handleSubmit}>
                 <FormHalfContainer>
                   <FormGroup>
                     <FormGroupLabel>Select Restaurant<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <Field
                       name="restaurantid"
                       render={({ input }) => (
                         <Select
                           {...input}
                           name="restaurantid"
                           className="react-select"
                           classNamePrefix="react-select"
                           options={fetchRestaurantName}
                           value={selectRestaurant}
                           onChange={handleSelectoptionChange}
                           defaultValue={selectRestaurant}
                           isClearable={false}
                           required
                         />
                       )}
                       
                     />
                   </FormGroup>
                   <FormGroup>
                     <FormGroupLabel>Select Location<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <Field
                       name="locationsid"
                       render={({ input }) => (
                         <Select
                           {...input}
                           name="locationsid"
                           ref={selectRef}
                          //  components={makeAnimated}
                           className="react-select"
                           classNamePrefix="react-select"
                           isMulti
                           value={LocationsNames} 
                           options={fetchLocationName}
                           onChange={(selected) => {
                             setLocationsNames(selected); 
                             input.onChange(selected);
                           }}
                           isClearable 
                           placeholder="Select a Location"
                           required
                         />
                       )}
                       
                     />
                   </FormGroup>



                   <FormGroup>
                     <FormGroupLabel>Meal Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="id"
                         component="input"
                         type="number"
                         placeholder="UID"
                         style={{ display: 'none' }}
                       />
                       <Field
                         name="name"
                         component="input"
                         type="text"
                         placeholder="Meal Name"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>
                   <FormGroup>
                     <FormGroupLabel>Meal Price<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="price"
                         component="input"
                         type="number"
                         placeholder="Meal Price"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>
                   <FormGroup>
                     <FormGroupLabel>FOC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="foc"
                         component="input"
                         type="text"
                         placeholder="Foc"
                         className="w-60"
                         required
                       />
                       {' '}on
                       <Field
                         name="foc_on"
                         component="input"
                         type="text"
                         placeholder="Foc on"
                         className="w-60"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>

                   {/* Meal Items Table */}
                   <FormGroup>
                     <Table responsive hover>
                       <thead>
                         <tr>
                           <th>#</th>
                           <th>Item</th>
                           <th>Description</th>
                           <th>Action</th>
                         </tr>
                       </thead>
                       <tbody>
                         {meals.map((meal, index) => (
                           <tr key={meal.item_id}>
                             <td>{index + 1}</td>
                             <td>{meal.name}</td>
                             <td>{meal.description}</td>
                             <td>
                               <Button
                                 type="button"
                                 variant="outline-danger"
                                 size="sm"
                                 className="mt-2"
                                 onClick={() => setMeals(meals.filter(m => m.item_id !== meal.item_id))}
                               >
                                 <IoIosRemoveCircle />
                               </Button>
                             </td>
                           </tr>
   ))}
                       </tbody>
                       <tfoot>
                         <tr>
                           <th>
                             <FormGroupLabel>Item</FormGroupLabel>
                             <Field
                               name="Item"
                               component="input"
                               type="text"
                               placeholder="Item"
                             />
                           </th>
                           <th>
                             <FormGroupLabel>Description</FormGroupLabel>
                             <Field
                               name="desc"
                               component="input"
                               type="text"
                               placeholder="Description"
                             />
                           </th>
                           <th>
                             <Button
                               type="button"
                               variant="outline-success"
                               rounded
                               size="sm"
                               style={{ marginTop: '45px' }}
                               onClick={() => {
           const itemValue = document.querySelector('[name="Item"]').value;
           const descValue = document.querySelector('[name="desc"]').value;

           handleAddMeal({
             Item: itemValue,
             desc: descValue,
           });

           form.change('Item', '');
           form.change('desc', '');
         }}
                             >
                               <CiCirclePlus />
                             </Button>
                           </th>
                         </tr>
                       </tfoot>
                     </Table>

                   </FormGroup>

                   {/* Meal Offered Checkboxes */}
                   <FormGroup>
                     <FormGroupLabel>Meal Offered <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="breakfast"
                         type="checkbox"
                         component={renderCheckBoxField}
                         label="Breakfast"
                         initialValue={getMealOfferedChecked(prevData?.meal_offered, 'breakfast')}
                       />
                       <Field
                         name="snacks"
                         type="checkbox"
                         component={renderCheckBoxField}
                         label="Snacks"
                         initialValue={getMealOfferedChecked(prevData?.meal_offered, 'snacks')}
                       />
                       <Field
                         name="lunch"
                         type="checkbox"
                         component={renderCheckBoxField}
                         label="Lunch"
                         initialValue={getMealOfferedChecked(prevData?.meal_offered, 'lunch')}
                       />
                       <Field
                         name="dinner"
                         type="checkbox"
                         component={renderCheckBoxField}
                         label="Dinner"
                         initialValue={getMealOfferedChecked(prevData?.meal_offered, 'dinner')}
                       />
                     </FormGroupField>
                   </FormGroup>



                   {/* Additional Fields */}
                   <FormGroup>
                     <FormGroupLabel>Meal format <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="format"
                         component={renderRadioButtonField}
                         label="Fit"
                         radioValue="1"
                         initialValue={getMealFormatChecked(prevData?.meal_format, 'fit') ? '1' : undefined}
                       />
                       <Field
                         name="format"
                         component={renderRadioButtonField}
                         label="Git"
                         radioValue="2"
                         initialValue={getMealFormatChecked(prevData?.meal_format, 'git') ? '2' : undefined}
                       />
                       <Field
                         name="format"
                         component={renderRadioButtonField}
                         label="Delivery"
                         radioValue="3"
                         initialValue={getMealFormatChecked(prevData?.meal_format, 'delivery') ? '3' : undefined}
                       />
                     </FormGroupField>
                   </FormGroup>

                   {/* Veg/Non-veg Radio Buttons */}
                   <FormGroup>
                     <FormGroupLabel>Veg/Non-veg <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="veg"
                         component={renderRadioButtonField}
                         label="Veg"
                         radioValue="1"
                         initialValue={getMealvegChecked(prevData?.veg_non_veg, 'veg') ? '1' : undefined}
                       />
                       <Field
                         name="veg"
                         component={renderRadioButtonField}
                         label="Non-veg"
                         radioValue="2"
                         initialValue={getMealvegChecked(prevData?.veg_non_veg, 'nonveg') ? '2' : undefined}
                       />
                       <Field
                         name="veg"
                         component={renderRadioButtonField}
                         label="Veg/Non-veg"
                         radioValue="3"
                         initialValue={getMealvegChecked(prevData?.veg_non_veg, 'vegNonveg') ? '3' : undefined}
                       />
                     </FormGroupField>
                   </FormGroup>

                   {/* Cuisines */}
                   <FormGroup>
                     <FormGroupLabel>Cuisines<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="cuisines"
                         component="input"
                         type="text"
                         placeholder="Cuisines"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>

                   {/* Meal type */}
                   <FormGroup>
                     <FormGroupLabel>Meal type<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="meal_type"
                         component="input"
                         type="text"
                         placeholder="Meal Type"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>
                 </FormHalfContainer>

                 {/* File Upload, Date Picker and Markup Percentage */}
                 <FormHalfContainer>
                   <FormGroup>
                     <FormGroupLabel>Description</FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="description"
                         component="textarea"
                         placeholder="Description"
                       />
                     </FormGroupField>
                   </FormGroup>

                   <FormGroup>
                     <FormGroupLabel>Logo</FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="logo"
                         render={({ input }) => (
                           <input
                             type="file"
                             onChange={e => handleFileChange(e, input)}
                           />
                         )}
                       />
                     </FormGroupField>
                   </FormGroup>

                   {selectedImage && (
                     <FormGroup>
                       <FormGroupLabel>Selected Logo</FormGroupLabel>
                       <FormGroupField>
                         <img
                           src={selectedImage}
                           alt="Selected Logo"
                           style={{ maxWidth: '200px', maxHeight: '200px' }}
                         />
                       </FormGroupField>
                     </FormGroup>
                   )}
                   <FormGroup>
                     <FormGroupLabel>
                       Start Date<span style={{ color: 'red' }}>*</span>
                     </FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="start_date"
                         render={({ input }) => (
                           <DatePicker
                             {...input}
                             dateFormat="dd-MM-yyyy"
                             selected={startDate || input.value} 
                             // onChange={date => input.onChange(date)} 
                             // selected={startDate}
                             onChange={date => setStartDate(date)}
                             value={startDate}
                           />
     )}
                         initialValue={startDate}
                       />
                     </FormGroupField>
                   </FormGroup>

                   <FormGroup>
                     <FormGroupLabel>
                       End Date<span style={{ color: 'red' }}>*</span>
                     </FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="end_date"
                         render={({ input }) => (
                           <DatePicker
                             {...input}
                             dateFormat="dd-MM-yyyy"
                             selected={endDate || input.value} 
                             // onChange={date => input.onChange(date)} 
                             // selected={endDate} 
                             onChange={date => setEndDate(date)}
                             value={endDate}
                           />
     )}
                         initialValue={endDate}
                       />
                     </FormGroupField>
                   </FormGroup> 

                   <FormGroup>
                     <FormGroupLabel>Markup Percentage<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="markup_percentage"
                         component="input"
                         type="number"
                         placeholder="Markup Percentage"
                         required
                       />
                     </FormGroupField>
                   </FormGroup>

                   
                 </FormHalfContainer>
                 <FormButtonToolbar>
                   <Button variant="primary" type="submit">
                     Submit
                   </Button>
                   {/* <Button variant="secondary" type="button">
                       Reset
                     </Button> */}
                 </FormButtonToolbar>
               </FormContainer>
             )}
           </Form>
         ) : (
           <p>Loading data...</p> 
         )}
       </CardBody>
     </Card>
   </Col>
 );
};

AddEditMeal.propTypes = {
 onSubmit: PropTypes.func.isRequired,
 id: PropTypes.string.isRequired,
 setRestaurantSelectOption: PropTypes.func.isRequired,
 setDate: PropTypes.func.isRequired,
};

export default AddEditMeal;

