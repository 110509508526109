import React, { useEffect, useState, useContext } from 'react';
import { Card, CardBody } from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { RestaurantContext } from '../../../../context/RestaurantContext';

const LocationCard = ({ locationid }) => {
  const [locationData, setLocationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const { LocationRefesh } = useContext(RestaurantContext);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      if (!locationid) {
        setErrors('Location ID is required');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(
          `https://navrang.mvd-tech.io/API/restaurant_location_data.php?id=${locationid}`,
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status && data.message === 'Success' && data.data?.[0]) {
          setLocationData({
            latitude: data.data[0].latitude || '',
            longitude: data.data[0].longitude || '',
          });
        } else {
          throw new Error(data.message || 'Failed to fetch location data');
        }
      } catch (error) {
        setErrors(error.message);
        toast.error(`Error fetching location: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocationDetails();
  }, [locationid, LocationRefesh]);


  console.log(locationData);


  // const handleUpdateLocation = () => {
  //   // TODO: Implement location update functionality
  //   toast.info('Location update functionality coming soon');
  // };

  // Generate map URL only if we have valid coordinates
  const mapSrc = locationData?.latitude && locationData?.longitude
  ? `https://maps.google.com/maps?q=${locationData.latitude},
    ${locationData.longitude}&z=17&output=embed&maptype=satellite&t=h`
  : null;



  if (isLoading) {
    return (
      <Card className="w-full max-w-4xl">
        <CardBody>
          <div className="flex items-center justify-center h-[400px]">
            <p>Loading map...</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (errors) {
    return (
      <Card className="w-full max-w-4xl">
        <CardBody>
          <div className="flex items-center justify-center h-[400px]">
            <p className="text-red-500">Error: {errors}</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (!mapSrc) {
    return (
      <Card className="w-full max-w-4xl">
        <CardBody>
          <div className="flex items-center justify-center h-[400px]">
            <p>No location coordinates available</p>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-4xl">
      <CardBody>
        <div className="relative w-full">
          <div style={{ width: '100%' }}>
            <iframe
              width="100%"
              height="400"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={mapSrc}
              title="Google Map"
              loading="lazy"
              allowFullScreen
            />
          </div>
        </div>
        <div className="mt-4">
          {/* <Button 
            variant="outline-primary" 
            size="sm" 
            // onClick={handleUpdateLocation}
            className="flex items-center gap-2"
          >
            <span className="lnr lnr-file-empty" />
            Update Location
          </Button> */}
        </div>
      </CardBody>
    </Card>
  );
};

LocationCard.propTypes = {
  locationid: PropTypes.string.isRequired,
};

export default LocationCard;
