import React from 'react';
import { Container, Row } from 'react-bootstrap';
import DefaultTabsBorderedBottom from './component/DefaultTabsBorderedBottom';

const Restaurant = () => (
  <Container>
    <Row>
      <DefaultTabsBorderedBottom />
    </Row>
  </Container>
  );
export default Restaurant;
