import React, {
    createContext, useState, useEffect, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const RestaurantContext = createContext();
   
   const RestaurantContextProvider = ({ children }) => {
     const [refresh, setRefresh] = useState(false);
     const [statusChanged, setStatusChanged] = useState(false);
     const [showModal, setShowModal] = useState(false);

     // this state only for the handling tab Change
     const [restaurantDetailsId, setRestaurantDetailsId] = useState();
     const [isDetailsClick, setIsDetailsClick] = useState(false);
     const [click, setclick] = useState('searchSelect');
    const [activeKey, setActiveKey] = useState('1');
    const [LocationRefesh, setLocationRefesh] = useState(false);

    // state for restaurant
const [restaurantInfo, setRestaurantInfo] = useState({
  restaurantname: '',
  uin: '',
  addressline1: '',
  addressline2: '',
  area: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  latitude: '',
  longitude: '',
  maplink: '',
  description: '',
  logo: null,
  logo_name: '',
  settingCap: '',
  cuisines: '', 
  taxId: '',
  tStartTime: '', 
  tEndTime: '', 
  cappingCap: '',
  markup: '', 
  mealOffered: {
    breakfast: '',
    lunch: '',
    dinner: '',
    snacks: '',
  },
  mealFormat: {
    git: '',
    fit: '', 
    delivery: '',
  },
});



  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    
    refresh,
    setRefresh,
    statusChanged,
    setStatusChanged,
    showModal, 
    setShowModal,
    isDetailsClick, 
    setIsDetailsClick,
    click,
    setclick,
    activeKey,
    setActiveKey,
    restaurantDetailsId, 
    setRestaurantDetailsId,
    restaurantInfo,
    setRestaurantInfo,
    LocationRefesh, 
    setLocationRefesh,
  }), [refresh, 
    statusChanged,
     showModal, 
     isDetailsClick, 
     click, 
     activeKey, 
     restaurantDetailsId, 
     restaurantInfo, 
     LocationRefesh]);

   
     return (
       <RestaurantContext.Provider value={value}>
         {children}
       </RestaurantContext.Provider>
     );
   };
   
   RestaurantContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default RestaurantContextProvider;
