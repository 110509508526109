import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { MdLunchDining, MdOutlinePeopleOutline } from 'react-icons/md';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { FiAlignRight } from 'react-icons/fi';
import { IoMdTime, IoMdCloseCircle } from 'react-icons/io';
import { Mealcontext } from '../../../../context/MealContext';
import MealDelete from '../utils/MealDeleteHandler';


const MealCard = ({ 
  id, 
  mealname, 
  restaurantid,
  description, 
  type, 
  price,
  finalprice, 
  format, 
  image, 
  isVeg,
  mealOffereds,
  cuisine,
  capacity,
  resName,
}) => {
  const [mealOfferd, setMealOffered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const { 
    setRefresh, 
    meals, 
    setMeals, 
    setRestaurantDetailsId,
    } = useContext(Mealcontext);
  const [restaurantName, seRestaurantName] = useState('');
  const [starttime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [settingCapacity, setSettingCapacity] = useState('');
  const [cappingCapacity, setCappingCapacity] = useState('');

  let label;

  if (isVeg === '1') {
    label = <span className="bg-success text-white p-2 rounded">VEG</span>;
  } else if (isVeg === '2') {
    label = <span className="bg-danger text-white p-2 rounded w-50">NON-VEG</span>;
  } else {
    label = <span className="bg-warning text-white p-2 rounded w-50">VEG / NON-VEG</span>;
  }





  // image source 
  const logo = `https://navrang.mvd-tech.io/API/images/meal/${image}`;

  const mealMapping = {
    1: 'Breakfast',
    2: 'Snacks',
    3: 'Lunch',
    4: 'Dinner',
  };




  return (
    <div className="card mb-3 position-relative" style={{ maxWidth: '100%' }}>
      <div className="row">
        <div className="col-md-4 col-12">
          <img 
            src={logo} 
            alt={mealname} 
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '10px',
            }} 
          />
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-text" style={{ color: 'black' }}>
              {mealname }  {resName ? `/${resName}` : '' }
            </h3>

            <p className="card-text"><FiAlignRight /> {description}</p>
            <p className="card-text">
              <IoMdTime /> Timing: {mealOffereds
    ?.split(',')
    .map(mealId => mealMapping[mealId.trim()])
    .filter(Boolean) // Remove undefined or null values
    .join(', ') || 'N/A'}
            </p>
            <p className="card-text">
              Type : {
                (() => {
                  switch (format) {
                    case '1':
                      return 'FIT';
                    case '2':
                      return 'GIT';
                    default:
                      return 'Delhivery';
                  }
                })()
              }
            </p>
            <p className="card-text">
              Format: {type}
            </p>
            {/* <p className="card-text">
              Meal Offered: {
                 mealOfferd.join(',')
              }
            </p> */}

          </div>
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            {label}
            <p className="card-text mt-2"><MdLunchDining /> Cuisine: {cuisine}</p>
            <p className="card-text"><MdOutlinePeopleOutline /> Capacity: {capacity}</p>
          </div>
          <div className="card-body price d-flex align-items-center">
            <h2 className="card-text me-2" style={{ color: 'red' }}>
              {price} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
            <h2 className="card-text me-2" style={{ color: 'blue' }}>
              {finalprice} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
          </div>
          {/* <div className="card-body price d-flex align-items-center">
            <h5 className="card-text me-2" style={{ color: 'red' }}>{price} <h3 className="d-inline">AED</h3></h5>
            <h5 className="card-text me-2" style={{ color: 'blue' }}>{finalprice} <h3 className="d-inline">AED</h3></h5>
          </div> */}


          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            {/* <Button variant="outline-success" title="Edit" onClick={toggleModal}>
              <span className="lnr lnr-pencil" /> Edit          
            </Button>
            <Button variant="outline-danger" title="Delete" onClick={() => MealDelete(id, setRefresh)}>
              <span className="lnr lnr-trash" /> Delete
            </Button> */}
            <Button variant="outline-primary" title="Details" onClick={() => setRestaurantDetailsId(id)}>
              <span className="lnr lnr-file-empty" /> Details
            </Button>
          </ButtonGroup>
        </div>
      </div>
      

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">Add/Edit</h3>
                <Button type="button" variant="primary" rounded size="sm" onClick={toggleModal} aria-label="Close">
                  <IoMdCloseCircle className="close-icon" />
                </Button>
              </div>
              <div className="modal-body" />
                
             
              {/* <div className="modal-footer">
                <Button type="button" variant="danger" rounded onClick={toggleModal}>
                  Close
                </Button>
                <Button type="button" variant="success" rounded>
                  Save changes
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MealCard.propTypes = {
  id: PropTypes.string.isRequired,
  mealname: PropTypes.string.isRequired,
  restaurantid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  finalprice: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isVeg: PropTypes.string.isRequired,
  mealOffereds: PropTypes.string.isRequired,
  cuisine: PropTypes.string.isRequired,
  capacity: PropTypes.string.isRequired,
  resName: PropTypes.string.isRequired,
};

export default MealCard;
