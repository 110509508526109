import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Col, Modal } from 'react-bootstrap';
import { MdDateRange } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import RestaurantInfo from './INFO/RestaurantInfo';
import RejectReasonForm from '../../../Agent/RejectReasonForm';


const RequestCard = ({
  index, 
  name, 
  id, 
  date,
  logo,
}) => {
  const path = 'https://navrang.mvd-tech.io/API/images/restaurant/';
  const [selectedImage, setSelectedImage] = useState(path + logo);
  const { setStatusChanged, setRefresh } = useContext(RestaurantContext);

  const [showInfoHandler, setshowInfoHandler] = useState(false);
  const [showRejectForm, setshowRejectForm] = useState(false);

  function formatDate(dateString) {
    const [datePart] = dateString.split(' '); // Split the date and time
    const [year, month, day] = datePart.split('-');
    return `${day}-${month}-${year}`;
  }
  
  // Accept action
  const handleAccept = async (ids) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/restaurant_confirmation.php?id=${ids}&isApproved=1`, 
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        setRefresh(prev => !prev);
        toast.success('Restaurant confirmed!');
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to confirm');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  // Reject action
  const handleReject = async (value) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/restaurant_confirmation.php?id=${id}&isApproved=2&reason=${value.reason}`, 
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        toast.success('Restaurant rejected!');
        setRefresh(prev => !prev);
        setshowRejectForm(false);
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to reject');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
      
    
    <Col
      md={12}
      xl={3}
      lg={6}
      xs={12}
      className="m-4"
      style={{
    border: '2px solid #ccc',
    borderRadius: '10px',
    padding: '15px',
    height: '260px',
    width: '240px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    backgroundColor: '#f9f9f9',
  }}
      onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'translateY(-5px)';
    e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  }}
      onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translateY(0)';
    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  }}
    >
      <div>
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
          color: '#ff4861',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
          >
            #{index + 1}
          </span>
          <div
            style={{
          margin: '10px auto',
          width: '75px',
          height: '75px',
          borderRadius: '50%',
          overflow: 'hidden',
          border: '2px solid #ff4861',
        }}
          >
            <img
              src={selectedImage}
              alt={name}
              style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h5
            className="mt-3"
            style={{ color: '#333', fontSize: '1rem', fontWeight: '600' }}
          >
            <CgProfile style={{ marginRight: '5px', color: '#6c757d' }} />
            {name}
          </h5>
          <h5
            style={{ color: '#555', fontSize: '0.95rem', fontWeight: '500' }}
          >
            <MdDateRange style={{ marginRight: '5px', color: '#6c757d' }} />
            {formatDate(date)}
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => handleAccept(id)}
            style={{
          marginRight: '10px',
        }}
          >
            Confirm
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => setshowRejectForm(true)}
          >
            Reject
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            title="View Info"
            onClick={() => setshowInfoHandler(true)}
          >
            <span className="lnr lnr-eye" />
          </Button>
        </div>
      </div>

      <Modal
        show={showInfoHandler}
        onHide={() => setshowInfoHandler(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Restaurant Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RestaurantInfo resID={id} />
          {/* <AgentInfo agentId={id} /> */}
        </Modal.Body>
      </Modal>
      <Modal
        show={showRejectForm}
        onHide={() => setshowRejectForm(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reject Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RejectReasonForm onSubmit={handleReject} isAgent={false} />
        </Modal.Body>
      </Modal>
    </Col>


  );
};

// PropTypes validation
RequestCard.propTypes = {
  index: PropTypes.number.isRequired, 
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired, 
  date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default RequestCard;
