import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const EditAgent = ({ formValues, setFormValues, onSubmit }) => {
  const path = 'https://navrang.mvd-tech.io/API/images/agent/';
  const gstpath = 'https://navrang.mvd-tech.io/API/images/agent/GST/';
  const [selectedImage, setSelectedImage] = useState(path + formValues.logo);
  const [selectedAdressProof, setSelectedAdressProof] = useState(gstpath + formValues.addressProof);
  const [selectedGstProof, setSelectedGSTProof] = useState(gstpath + formValues.gstProof);


  
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); 
      };
      reader.readAsDataURL(file);
      
      input.onChange(file); 
    } else {
      setSelectedImage(null); 
      input.onChange(null); 
    }
  };

  const handAddressFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedAdressProof(reader.result);
      };
      reader.readAsDataURL(file);
  
      input.onChange(file);
    } else {
      setSelectedAdressProof(null);
      input.onChange(null);
    }
  };
  const handGstFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedGSTProof(reader.result);
      };
      reader.readAsDataURL(file);
  
      input.onChange(file);
    } else {
      setSelectedGSTProof(null);
      input.onChange(null);
    }
  };

return (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        {/* <CardTitleWrap>
          <CardTitle>Edit Agent</CardTitle>
        </CardTitleWrap> */}
        <Form onSubmit={onSubmit} initialValues={formValues}>
          {({ handleSubmit, form }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormHalfContainer>
                <FormGroup>
                  <FormGroupLabel>Agent Name</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="agentName"
                      component="input"
                      type="text"
                      placeholder="Agent Name"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Address Line 1</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="addressLine1"
                      component="input"
                      type="text"
                      placeholder="Address Line 1"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Area</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="area"
                      component="input"
                      type="text"
                      placeholder="Area"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>State</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="state"
                      component="input"
                      type="text"
                      placeholder="State"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Pincode</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="pincode"
                      component="input"
                      type="number"
                      placeholder="Pincode"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Logo</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="file"
                      render={({ input }) => (
                        <input
                          type="file"
                          onChange={e => handleFileChange(e, input)}
                        />
                        )}
                    />
                  </FormGroupField>
                </FormGroup>
                {/* Display the selected image below the file input */}
                {selectedImage && (
                <FormGroup>
                  <FormGroupLabel>Selected Logo</FormGroupLabel>
                  <FormGroupField>
                    <img
                      src={selectedImage}
                      alt="Selected Logo"
                      style={{ maxWidth: '200px', maxHeight: '200px' }}
                    />
                  </FormGroupField>
                </FormGroup>
                  )}
                <FormGroup>
                  <FormGroupLabel>GST No</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="gstNo"
                      component="input"
                      type="text"
                      placeholder="GST No"

                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>GST Type</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="gstType"
                      component="input"
                      type="text"
                      placeholder="GST Type"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Address Proof</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="addressProof"
                      render={({ input }) => (
                        <input
                          type="file"
                          onChange={e => handAddressFileChange(e, input)}
                          required
                        />
                        )}
                    />
                  </FormGroupField>
                </FormGroup>
                {selectedAdressProof && (
                  <FormGroup>
                    <FormGroupLabel>Selected Address Proof</FormGroupLabel>
                    <FormGroupField>
                      <img
                        src={selectedAdressProof}
                        alt="Selected Address Proof"
                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                      />
                    </FormGroupField>
                  </FormGroup>
                  )}

                <FormGroup>
                  <FormGroupLabel>GST Proof</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="gstProof"
                      render={({ input }) => (
                        <input
                          type="file"
                          onChange={e => handGstFileChange(e, input)}
                          required
                        />
                      )}
                    />
                  </FormGroupField>
                </FormGroup>
                {selectedGstProof && (
                  <FormGroup>
                    <FormGroupLabel>Selected GST Proof</FormGroupLabel>
                    <FormGroupField>
                      <img
                        src={selectedGstProof}
                        alt="Selected Address Proof"
                        style={{ maxWidth: '200px', maxHeight: '200px' }}
                      />
                    </FormGroupField>
                  </FormGroup>
                  )}                  
              </FormHalfContainer>
              <FormHalfContainer>
                <FormGroup>
                  <FormGroupLabel>UAN</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="uan"
                      component="input"
                      type="number"
                      placeholder="UAN"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Address Line 2</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="addressLine2"
                      component="input"
                      type="text"
                      placeholder="Address Line 2"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>City</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="city"
                      component="input"
                      type="text"
                      placeholder="City"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Select your country</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="country"
                      component="input"
                      type="text"
                      placeholder="Country"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Location on Map</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="location"
                      component="input"
                      type="text"
                      placeholder="Location"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Default Markup</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="defaultMarkup"
                      component="input"
                      type="number"
                      placeholder="Default Markup"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>TAX Identification</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="tin"
                      component="input"
                      type="text"
                      placeholder="Tax Identification"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>TAX City</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="taxCity"
                      component="input"
                      type="text"
                      placeholder="Tax City"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button variant="secondary" type="button" onClick={form.reset}>
                    Cancel
                  </Button>
                </FormButtonToolbar>
              </FormHalfContainer>
            </FormContainer>
          )}
        </Form>
      </CardBody>
    </Card>
  </Col>
);
}; 

EditAgent.propTypes = {
  formValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agentName: PropTypes.string,
    addressline1: PropTypes.string,
    addressline2: PropTypes.string,
    area: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    tin: PropTypes.string,
    taxCity: PropTypes.string,
    addressProof: PropTypes.string,
    gstProof: PropTypes.string,
    gstNo: PropTypes.string,
    gstType: PropTypes.string,
    defaultMarkup: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userPhone: PropTypes.string,
    uin: PropTypes.number,
    accName: PropTypes.string,
    accountNo: PropTypes.number,
    ifscCode: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    accCity: PropTypes.string,
    logo: PropTypes.string,
    accCountry: PropTypes.string,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditAgent;
