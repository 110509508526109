import React from 'react';
import PendingCard from './PendingCard';

const Pending = () => (
  <div>
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
    <PendingCard />
  </div>
  );

export default Pending;
