import React from 'react';
import PropTypes from 'prop-types'; 
import AuthContextProvider from './AuthContext'; 
import AgentContextProvider from './AgentContext';
import MealContextProvider from './MealContext';
import RestaurantContextProvider from './RestaurantContext';
import PermissionContextProvider from './PermissionContext';
import ThemeContextProvider from './ThemeContext';

const AppContextProvider = ({ children }) => (
  <ThemeContextProvider>
    <AuthContextProvider>
      <PermissionContextProvider>
        <AgentContextProvider>
          <MealContextProvider>
            <RestaurantContextProvider>
              {children}  
            </RestaurantContextProvider>
          </MealContextProvider>
        </AgentContextProvider>
      </PermissionContextProvider>
    
    </AuthContextProvider>
  </ThemeContextProvider>
  
  );

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired, 
};

export default AppContextProvider;
