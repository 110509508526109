import React, { useContext, useEffect, useState } from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form, 
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RestaurantContext } from '../../../../../context/RestaurantContext';
import RejectedRequestCard from './RejectedRequestCard';

const RejectedRequest = () => {
  const [rejectedRestaurant, setRejectedRestaurant] = useState([]);
  const [filterRestaurant, setFilterRestaurant] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setRefresh, refresh } = useContext(RestaurantContext);



  const fetchData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_rejected_list.php');
      const data = await response.json();
  
      console.log('Data:', data);
  
      if (data.status && data.message === 'Success') {
        console.log('Data fetched:', data.data);
        setRejectedRestaurant(data.data);
        setFilterRestaurant(data.data);
      } else {
        console.log('No data fetched');
        setFilterRestaurant([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load data');
    }
  };
  


  useEffect(() => {
    console.log('Refreshing data...');
    fetchData();
  }, [refresh]);
  
  

  if (rejectedRestaurant.length <= 0) {
    return (
      <div>
        No Restaurnat Found
      </div>
    );
  }
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = rejectedRestaurant
      .filter(agentdata => agentdata.name.toLowerCase().includes(query.toLowerCase())
        || agentdata.city.toLowerCase().includes(query.toLowerCase())
        || agentdata.state.toLowerCase().includes(query.toLowerCase())
        || agentdata.country.toLowerCase().includes(query.toLowerCase()));
        setFilterRestaurant(filteredData);
    } else {
      setFilterRestaurant(rejectedRestaurant);
    }
  };

  // const refreshHandler = () => {
  //   setRefresh(prev => !prev);
  //   toast.success('Refresh....');
  // };

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>
      <Row>
        <div className="col-md-4 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Name, City, State, or Country"
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1"
            // style={{
            //   backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
            //   color: currentTheme === 'Dark' ? 'white' : 'black',
            // }}
          />
        </div>
      </Row>
      <Row>
        <Container>
          {filterRestaurant.length > 0 ? (
            filterRestaurant.map(resData => (
              <RejectedRequestCard
                key={resData.id}
                id={resData.id}
                name={resData.user_name}
                userPhone={resData.user_phone}
                userEmail={resData.user_email}
                uin={resData.uin}
                accName={resData.acc_name}
                accNumber={resData.account_no}
                ifscCode={resData.ifsc_code}
                remark={resData.reject_reason}
                branch={resData.branch}
                bank={resData.bank}
                accCity={resData.acc_city}
                accCountry={resData.acc_country}
                photo={resData.photo}
                setRefresh={setRefresh}
              />
            ))
          ) : (
            <div>No Restaurant found.</div>
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default RejectedRequest;
