import React from 'react';
import PropTypes from 'prop-types';

const BasicDetails = ({ basicResInfo }) => (
  <div>
    <div className="row">
      <div className="col-md-4">
        <p>Restaurant Name:{basicResInfo.name}</p>
      </div>
      <div className="col-md-4">
        <p>Restaurant Email:{basicResInfo.email}</p>
      </div>

     
      <div className="col-md-4">
        <p>Restaurant Logo</p>
        <img
          src={`https://navrang.mvd-tech.io/API/images/restaurant/${basicResInfo.photo}`}
          alt="Thumbnail"
          className="img-fluid"
          style={{ cursor: 'pointer' }}
        />

      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-6">
        <p>Phone :{basicResInfo.phone}</p>
      </div>
      <div className="col-md-6">
        <p>UIN :{basicResInfo.uin}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <p style={{ marginBottom: '10px', color: 'red' }}>AccounDetails</p>
      <div className="col-md-4">
        <p>Account Name :{basicResInfo.acc_name}</p>
      </div>
      <div className="col-md-4">
        <p>Account No :{basicResInfo.account_no}</p>
      </div>
      <div className="col-md-4">
        <p>IFSC Code :{basicResInfo.ifsc_code}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-4">
        <p>Branch :{basicResInfo.branch}</p>
      </div>
      <div className="col-md-4">
        <p>Bank :{basicResInfo.bank}</p>
      </div>
      <div className="col-md-4">
        <p>City :{basicResInfo.acc_city}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-4">
        <p>Country:{basicResInfo.acc_country}</p>
      </div>

    </div>
    <hr />
  </div>
  
  );

  BasicDetails.propTypes = {
    basicResInfo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      uin: PropTypes.string,
      acc_name: PropTypes.string,
      account_no: PropTypes.string,
      ifsc_code: PropTypes.string,
      branch: PropTypes.string,
      bank: PropTypes.string,
      acc_city: PropTypes.string,
      acc_country: PropTypes.string,
      photo: PropTypes.string,
    }).isRequired,
  };

export default BasicDetails;
