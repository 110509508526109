import React, { useState, useContext, useEffect } from 'react';
import { Nav, Tab, Modal } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { FaPlus } from 'react-icons/fa';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import RequestList from './RequestList';
import Search from './Search';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import ResaurantDetails from './Resaurantdetails';
import PrimaryContactForm from './PrimaryContactForm';
import { PermissionContext } from '../../../../context/PermissionContext';
import { ThemeContext } from '../../../../context/ThemeContext';
import RejectedRequest from './RejectRequestTab/RejectedRequest';


const Tabs = () => {
  // state for component condition rendering 
  const { permissionsAccess } = useContext(PermissionContext);
  const { currentTheme } = useContext(ThemeContext);
  // const [condition, setCondition] = useState('searchRestaurant');
  const {
    setRefresh,
    showModal,
     setShowModal,
     isDetailsClick, 
     click, 
     setclick,
     activeKey, 
     setActiveKey,
     } = useContext(RestaurantContext);




  // Function for Submit Add Restaurant 
//   const AddNewRestaurant = async (values) => {
//     // console.log(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
//     // console.log(`You submitted:\n\n${JSON.stringify(restaurantInfo.latitude, null, 2)}`);
//     const Start = new Date(values.Starttime);
//     const formattedStartTime = Start.toLocaleTimeString('en-IN', {
//       hour: '2-digit',
//       minute: '2-digit',
//     });
//     const End = new Date(values.Endtime);
//     const formattedEndTime = End.toLocaleTimeString('en-IN', {
//       hour: '2-digit',
//       minute: '2-digit',
//     });
    

//      const mealOffered = [];
//      const mealFormat = [];

//      if (values.fit) {
//         mealFormat.push('1');
//      }
//      if (values.git) {
//         mealFormat.push('2');
//      }
//      if (values.delivery) {
//         mealFormat.push('3');
//      }


//     if (values.breakfast) {
//         mealOffered.push('1');
//     }
//     if (values.snacks) {
//         mealOffered.push('2');
//     }
//     if (values.lunch) {
//         mealOffered.push('3');
//     }
//     if (values.dinner) {
//         mealOffered.push('4');
//     }

//     const formData = new FormData();
//     formData.append('name', values.restaurantName); 
//     formData.append('address1', values.address1); 
//     formData.append('area', values.area); 
//     formData.append('state', values.state); 
//     formData.append('pincode', values.pincode); 
//     formData.append('description', values.discription); 
//      formData.append('photo', values.logo); 
//     formData.append('setting_capacity', values.settingCap); 
//     formData.append('capping_capacity', values.capCapacity); 
//     formData.append('uin', values.uin);
//     formData.append('address2', values.address2); 
//     formData.append('city', values.city); 
//     formData.append('country', values.country); 
//     formData.append('map_location', values.maplink); 
//     formData.append('latitude', values.latitude); 
//     formData.append('longitude', values.longitude); 
//     formData.append('cuisines', values.cuisines); 
//     formData.append('start_time', formattedStartTime); 
//     formData.append('end_time', formattedEndTime); 
//     formData.append('tax_id', values.taxID); 
//     formData.append('markup_percentage', values.markup); 
//     formData.append('meal_offered', mealOffered.join(',')); 
//     formData.append('meal_format', mealFormat.join(','));  
    
//     formData.forEach((value, key) => {
//       console.log(`${key}: ${value}`);
//   });


//     try {
//         const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_add.php', {
//             method: 'POST',
//             body: formData,
//         });
//         if (!response.ok) {
//             console.log(`Network response was not ok: ${response.statusText}`);
//         }
//         const responseData = await response.json();
//         if (responseData.status && responseData.message === 'Data inserted successfully.') {
//             toast.success('Restaurant Added Successfully...');
//             setRefresh(prev => !prev);
//             setShowModal(false);
//         } else {
//             toast.error(responseData.message);
//         }
//     } catch (error) {
//         toast.error(error.message);
//     }    
// };

  // Handler for tab selection
  // const handleTabSelect = (key) => {
  //   switch (key) {
  //     case '1':
  //       setCondition('searchRestaurant');
  //       break;
  //     case '2':
  //       setCondition('requestRestaurant');
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const AddNewRestaurant = async (values) => {
    console.log(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
    if (values.accountNo !== values.conAccount) {
      toast.error('Account numbers do not match. Please check and try again.');
      return;
    }
    const formData = new FormData();
    formData.append('user_name', values.userName);
    formData.append('user_phone', values.userPhone);
    formData.append('user_email', values.userEmail);
    formData.append('uin', values.uin);
    formData.append('acc_name', values.accName);
    formData.append('acc_country', values.accCountry);
    formData.append('account_no', values.accountNo);
    formData.append('acc_city', values.accCity);
    formData.append('branch', values.branch);
    formData.append('ifsc_code', values.ifscCode);
    formData.append('bank', values.bank);
    formData.append('photo', values.file);
   
    // for log form Data
    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });
        try {
        const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_add.php', {
            method: 'POST',
            body: formData,
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.status && responseData.message === 'Data inserted successfully.') {
            toast.success('Restaurant Added Successfully...');
            setRefresh(prev => !prev);
            setShowModal(false);
        } else {
            toast.error(responseData.message);
        }
    } catch (error) {
        toast.error(error.message);
    }    
  };
  
    useEffect(() => {
      if (isDetailsClick) {
        setActiveKey('2');
        setclick('detailsClick');
      }
    }, [isDetailsClick]);

  return (
    <>
      {/* Tabs */}
      <Tab.Container activeKey={activeKey} onSelect={key => setActiveKey(key)}>
        <TabsWrap>
          {permissionsAccess?.[1]?.master_id === '2'
        && permissionsAccess?.[1]?.create === 1 && (
          <Button 
            variant="outline-primary" 
            size="sm" 
            onClick={() => setShowModal(true)}
          >
            <span>Add Restaurant</span><FaPlus />
          </Button>
        )}
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink eventKey="1" disabled={isDetailsClick}>Search</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2" disabled={!isDetailsClick}>Details</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="3" disabled={isDetailsClick}>Request</NavLink>
            </NavItem>
            <NavItem>
              <NavLink 
                eventKey="4" 
                disabled={isDetailsClick}
              >
                <span style={{ color: 'red' }}>
                  Rejected Request
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              {click === 'searchSelect' && <Search />}
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              {click === 'detailsClick' && <ResaurantDetails setActiveKey={setActiveKey} />}
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <RequestList />
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <RejectedRequest />
            </Tab.Pane>
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>

      {/* Modal for Adding Restaurant */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
      >
        <Modal.Header
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
          color: currentTheme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
        }}
        >
          <PrimaryContactForm onSubmit={AddNewRestaurant} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tabs;
