import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { renderMaskedField } from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';

const EditPrimary = ({ onSubmit, restaurantDetailsId }) => {
// console.log(restaurantDetailsId);

    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [prevData, setPrevData] = useState(null);

    const fetchRestaurantData = async () => {
        try {
           const url = await 
           fetch(`https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${restaurantDetailsId}`);
            const data = await url.json();
            if (data.status && data.message === 'Success') {
                setPrevData(data.data[0]);
                setSelectedImage(`https://navrang.mvd-tech.io/API/images/restaurant/${data.data[0].photo}`);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error loading meals', error);
        }
    };

    useEffect(() => {
        fetchRestaurantData();
    }, [restaurantDetailsId]);

  
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
  
      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  // console.log(prevData);

  return (
    <Col md={12} lg={12}>
      <Form onSubmit={onSubmit} initialValues={prevData}>
        {({ handleSubmit, form }) => (
          <FormContainer onSubmit={handleSubmit}>
            {/* Primary Contact Details Section */}
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle />
                  <p><span style={{ color: 'red' }}>*{' '}</span>Marks Fields are Required</p>
                </CardTitleWrap>
                {/* First Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="user_name"
                          component="input"
                          type="text"
                          placeholder="Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Phone Number<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="user_phone"
                          component={renderMaskedField}
                          mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          type="test"
                          placeholder="Phone Number"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Second Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Email<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="user_email"
                          component="input"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>UINs #<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="uin"
                          component="input"
                          type="number"
                          placeholder="UIN"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                    
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Logo<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="file"
                          render={({ input }) => (
                            <input
                              type="file"
                              onChange={e => handleFileChange(e, input)}
                            />
                        )}
                        />
                      </FormGroupField>
                    </FormGroup>

                    {selectedImage && (
                      <FormGroup>
                        <FormGroupLabel>Selected Logo</FormGroupLabel>
                        <FormGroupField>
                          <img
                            src={selectedImage}
                            alt="Selected Logo"
                            style={{ maxWidth: '200px', maxHeight: '200px' }}
                            required
                          />
                        </FormGroupField>
                      </FormGroup>
                    )}  
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* Account Details Section */}
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Account Details</CardTitle>
                </CardTitleWrap>
                {/* Third Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="acc_name"
                          component="input"
                          type="text"
                          placeholder="Account Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="acc_country"
                          component="input"
                          type="text"
                          placeholder="Country"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Fourth Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="account_no"
                          component="input"
                          type="number"
                          placeholder="Account Number"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="acc_city"
                          component="input"
                          type="text"
                          placeholder="City"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Fifth Row: 2 columns */}
                <Row>
                  {!prevData.account_no && (
                    <Col md={6}>
                      <FormGroup>
                        <FormGroupLabel>
                          Confirm Account <span style={{ color: 'red' }}>*</span>
                        </FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="conAccount"
                            component="input"
                            type="number"
                            placeholder="Confirm Account"
                            required
                          />
                        </FormGroupField>
                      </FormGroup>
                    </Col>
                )}
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Branch<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="branch"
                          component="input"
                          type="text"
                          placeholder="Branch"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Sixth Row: 2 columns */}
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>IFSC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="ifsc_code"
                          component="input"
                          type="text"
                          placeholder="IFSC"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Bank<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="bank"
                          component="input"
                          type="text"
                          placeholder="Bank"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <FormButtonToolbar style={{ float: 'right' }}>
              <Button variant="primary" type="submit">Submit</Button>
            </FormButtonToolbar>
          </FormContainer>
      )}
      </Form>
    </Col>
);
};

EditPrimary.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  restaurantDetailsId: PropTypes.string.isRequired,
};

export default EditPrimary;
