// import React from 'react';
// import PropTypes from 'prop-types';
// import { CiLogin } from 'react-icons/ci';
// import TopbarLanguage from '../components/topbar/TopbarLanguage';
// import TopbarMail from '../components/topbar/TopbarMail';
// import TopbarProfile from '../components/topbar/TopbarProfile';
// import TopbarNotification from '../components/topbar/TopbarNotification';
// import TopbarSearch from '../components/topbar/TopbarSearch';
// import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
// import {
//   TopbarContainer,
//   TopbarLeft,
//   TopbarLogo,
//   TopbarRight,
//   TopbarRightOver,
//   TopbarSearchWrap,
// } from '../components/topbar/BasicTopbarComponents';
// import TopbarWallet from '../components/topbar/TopbarWallet';

// const Topbar = ({
//   changeMobileSidebarVisibility,
//   changeSidebarVisibility,
// }) => (
//   <TopbarContainer>
//     <TopbarLeft>
//       <TopbarSidebarButton
//         onClickMobile={changeMobileSidebarVisibility}
//         onClickDesktop={changeSidebarVisibility}
//       />
//       <TopbarLogo to="/online_marketing_dashboard" />
//     </TopbarLeft>
//     <TopbarRight>
//       <TopbarSearchWrap>
//         <TopbarSearch />
//       </TopbarSearchWrap>
//       Add toggle button
//       <TopbarRightOver>
//         <TopbarNotification />
//         <TopbarMail new />
//         <TopbarProfile />
         
//         <TopbarLanguage />
//         <TopbarWallet />
//         <CiLogin />
//       </TopbarRightOver>
//     </TopbarRight>
//   </TopbarContainer>
//   );

// Topbar.propTypes = {
//   changeMobileSidebarVisibility: PropTypes.func.isRequired,
//   changeSidebarVisibility: PropTypes.func.isRequired,
// };

// export default Topbar;

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CiLogin } from 'react-icons/ci';
import { useDispatch } from 'react-redux'; // Import useDispatch from react-redux
import { changeThemeToDark, changeThemeToLight } from '@/redux/actions/themeActions'; // Import the actions
import { Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import { Button } from '@/shared/components/Button';
import TopbarLanguage from '../components/topbar/TopbarLanguage';
import TopbarMail from '../components/topbar/TopbarMail';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarNotification from '../components/topbar/TopbarNotification';
import TopbarSearch from '../components/topbar/TopbarSearch';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
  TopbarSearchWrap,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import { AuthContext } from '../../../context/AuthContext';
import { ThemeContext } from '../../../context/ThemeContext';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const [isToggled, setIsToggled] = useState(false);
  const { setIsLoggedIn } = useContext(AuthContext);
  const { setCurrentTheme, currentTheme } = useContext(ThemeContext);
   const history = useHistory();

  // Dispatch hook from react-redux
  const dispatch = useDispatch();

  const handleToggle = () => {
    setIsToggled(!isToggled);

    // Directly dispatch the theme change action for immediate effect
    if (!isToggled) {
      dispatch(changeThemeToLight());
      setCurrentTheme('Dark');
    } else {
      dispatch(changeThemeToDark());
      setCurrentTheme('light');
    }
  };
     
  const onlogoutHandler = async () => {
     const url = await fetch('https://navrang.mvd-tech.io/API/logout.php');
     const response = await url.json();
     if (response.loggedOut === true) {
       toast.success('Logout Successfull Redirecting....');
       setIsLoggedIn(false);
       setTimeout(() => {
         history.push('/');
       }, 2000);
     } else {
      toast.error('Something Went Wrong....');
     }
  };

  return (
    <TopbarContainer>
      <TopbarLeft>
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <TopbarLogo to="/online_marketing_dashboard" />
      </TopbarLeft>
      <TopbarRight>
        {/* <TopbarSearchWrap>
          <TopbarSearch />
        </TopbarSearchWrap> */}

        <button
          type="button"
          onClick={handleToggle}
          style={{
    background: isToggled
      ? 'linear-gradient(145deg, #6e7dff, #4757d8)' // Dark mode gradient
      : 'linear-gradient(145deg, #ffaf7b, #ff6a3d)', // Light mode gradient
    color: 'white',
    fontSize: '12px', // Slightly larger font size for readability
    fontWeight: '600', // Slightly bolder font for emphasis
    padding: '5px 12px', // Compact padding
    margin: '13px', // Minimal margin
    border: 'none',
    borderRadius: '50px', // Rounded button with a smooth curve
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    width: '60px', // Compact width
    height: '30px', // Short height to match the compact style
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    willChange: 'background, transform',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  }}
          onMouseEnter={(e) => {
    e.target.style.boxShadow = '0px 6px 15px rgba(0, 0, 0, 0.2)';
  }}
          onMouseLeave={(e) => {
    e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)';
  }}
        >
          <span
            style={{
      position: 'absolute',
      top: '50%',
      left: isToggled ? '50%' : '0%',
      transform: 'translateY(-50%)',
      width: '50%',
      height: '100%',
      backgroundColor: 'white',
      borderRadius: '50%',
      transition: 'all 0.3s ease', // Smooth slider transition
    }}
          />
          <span
            style={{
      position: 'absolute',
      top: '50%',
      left: '8px', // Position sun emoji
      transform: 'translateY(-50%)',
      zIndex: 1,
      opacity: isToggled ? 0 : 1, // Fade effect for sun emoji
      transition: 'opacity 0.2s ease',
    }}
          >
            🌄
          </span>
          <span
            style={{
      position: 'absolute',
      top: '50%',
      right: '8px', // Position moon emoji
      transform: 'translateY(-50%)',
      zIndex: 1,
      opacity: isToggled ? 1 : 0, // Fade effect for moon emoji
      transition: 'opacity 0.2s ease',
    }}
          >
            🌔
          </span>
        </button>




        <TopbarRightOver
          style={{
            display: 'flex', // Use flexbox for alignment
            justifyContent: 'flex-end', // Align items to the right
            alignItems: 'center', // Center items vertically
            gap: '10px', // Space between elements
          }}
        >
          {/* <TopbarNotification />
          <TopbarMail new /> */}
          <TopbarProfile />
          {/* <TopbarLanguage />
          <TopbarWallet /> */}
          <button
            type="button"
            onClick={onlogoutHandler}
            style={{
              color: currentTheme === 'Dark' ? 'white' : 'red',
              background: 'transparent', // No background for button
              border: 'none',
              padding: '5px', // Add padding around the icon for click area
              cursor: 'pointer', // Ensure it's clickable
            }}
            aria-label="Logout" // Add accessibility label
          >
            <CiLogin
              style={{
                fontSize: '24px',
                transition: 'transform 0.3s ease', // Optional hover effect
              }}
            />
          </button>
          
        </TopbarRightOver>
      </TopbarRight>
    </TopbarContainer>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

