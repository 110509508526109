import React, { 
    useState,
     useEffect, 
     useContext,
     useRef,
    } from 'react';
  import { Field, Form } from 'react-final-form';
  import { Col } from 'react-bootstrap';
  import {
    Card, CardBody, CardTitleWrap,
  } from '@/shared/components/Card';
  import PasswordField from '@/shared/components/form/Password';
  import { renderMaskedField } from '@/shared/components/form/FormField';
  import {
      FormButtonToolbar,
      FormContainer,
      FormGroup,
      FormGroupField,
      FormGroupLabel,
      FormHalfContainer,
    } from '@/shared/components/form/FormElements';
    import Select from 'react-select';
    import { Button } from '@/shared/components/Button';
    import PropTypes from 'prop-types';
    import { AuthContext } from '../../../../../context/AuthContext';
  
    const EditUser = ({ onSubmit, userDetails }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const { restaurantid, apiGlobal } = useContext(AuthContext);
    const [fetchLocationName, setFetchLocationName] = useState([]);
    const [preSelectedLocation, setPreSelectedLocation] = useState([]);

    

   
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <CardTitleWrap>
              <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
            </CardTitleWrap>
            <Form onSubmit={onSubmit} initialValues={userDetails}>
              {({ handleSubmit, form }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormHalfContainer>
                    <FormGroup>
                      <FormGroupLabel>Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field 
                          name="id"
                          component="input"
                          type="hidden"
                        />
                        <Field
                          name="name"
                          component="input"
                          type="text"
                          placeholder="Enter Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>User Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="username"
                          component="input"
                          type="email"
                          placeholder="Enter UserName"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>Mobile No 1<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="mobile1"
                          component={renderMaskedField}
                          type="text"
                          mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          placeholder="Enter Mobile 1"
                          required
                          
                        />
                      </FormGroupField>
                    </FormGroup>
                  </FormHalfContainer>
                  <FormHalfContainer>
                    <FormGroup>
                      <FormGroupLabel>Designation<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="designation"
                          component="input"
                          type="text"
                          placeholder="Enter Degisnation"
                          required
                          
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>Password<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="password"
                          component={PasswordField}
                          placeholder="Enter Password"
                          required
                          
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>Mobile No 2<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="mobile2"
                          component={renderMaskedField}
                          type="text"
                          mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          placeholder="Enter Mobile 2"
                          required
                          
                        />
                      </FormGroupField>
                    </FormGroup>
                  </FormHalfContainer>
                  <FormButtonToolbar>
                    <Button variant="primary" type="submit">Submit</Button>
                  </FormButtonToolbar>
                </FormContainer>
              )}
            </Form>
          </CardBody>
        </Card>
      </Col>
      
    );
  };

  EditUser.propTypes = {
      onSubmit: PropTypes.func.isRequired,
      userDetails: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        password: PropTypes.string, 
        designation: PropTypes.string,
        locationids: PropTypes.string,
      }).isRequired,
    };

  export default EditUser;

