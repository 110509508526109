import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
  FaLocationDot, 
  FaPercent, 
  FaUser, 
  FaPhone,
 } from 'react-icons/fa6'; 
import { MdAlternateEmail } from 'react-icons/md';
import { Button } from '@/shared/components/Button';
import EditPrimary from '../EditPrimary';
// import EditAgent from '../EditAgent';






const RejectedRequestCard = ({
    id,
    name,
    userPhone,
    userEmail,
    uin,
    accName,
    accNumber,
    ifscCode,
    remark,
    branch,
    bank,
    accCity,
    accCountry,
    photo,
    setRefresh,
}) => {
     const [showEditModel, setShowEditModel] = useState(false);
     const [resId, setResId] = useState(id);

      const handleEditResDetails = async (values) => {
        const formData = new FormData();
        formData.append('id', resId);
    formData.append('user_name', values.user_name);
    formData.append('user_phone', values.user_phone);
    formData.append('user_email', values.user_email);
    formData.append('uin', values.uin);
    formData.append('acc_name', values.acc_name);
    formData.append('acc_country', values.acc_country);
    formData.append('account_no', values.account_no);
    formData.append('acc_city', values.acc_city);
    formData.append('branch', values.branch);
    formData.append('ifsc_code', values.ifsc_code);
    formData.append('bank', values.bank);
    formData.append('photo', values.file);
    formData.append('type', '1');

    try {
      const url = await fetch('https://navrang.mvd-tech.io/API/restaurant_edit.php', {
       method: 'POST',
       body: formData,
      });
       const response = await url.json();
       if (response.status && response.message === 'Data updated successfully.') {
           toast.success('Data updated successfully.');
           setRefresh(prev => !prev);
           setShowEditModel(false);
       } else {
           toast.error(response.message);
       }
  } catch (error) {
      toast.error(error.message);
  }
      };

  
  

  return (
    <div 
      className="card mb-3 position-relative" 
      style={{
        backgroundColor: '#f9f9f9',
        maxWidth: '100%',
        border: '2px solid',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.02)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      
    >

      <div className="row g-0">
        <div className="col-md-12">
          <div className="remark mt-2">
            <i>
              Remark : <span style={{ color: 'red' }}>{remark}</span>
            </i> 
          </div>
          <hr />
        </div>
        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-title">
              Restaurant Name : {name}

            </h3>
            <p className="card-text"><FaLocationDot />
              {' '} {userPhone}, {userEmail}
            </p>
            <p className="card-text">
              {uin}
              
            </p>
          </div>
        </div>

        {/* <div className="col-md-4 col-12 mt-4">
          <div className="card-body">
            <br />
            <p className="card-title"><FaPercent />{' '}TIN #: N/A</p>
            <p className="card-text"><FaPercent />{' '}Tax City: N/A</p>
            <p className="card-text">
              <FaPercent /> {' '}Default Markup: 0%
            </p>
          </div>
        </div> */}

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h5 className="card-title">Account Details
              
            </h5>
            <p className="card-text">{' '}Acc Name: {accName}</p>
            <p className="card-text">{' '}Acc No: {accNumber}</p>
            <p className="card-text">{' '}Acc IFCS: {ifscCode}</p>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card-body">
            <div className="col-md-12">
              <p className="card-text">{' '}Branch: {branch},Bank:{bank}</p>
              <p className="card-text">{' '}Bank: {bank},</p>
              <p className="card-text">{' '}Acc City: {accCity}</p>
              <p className="card-text">{' '}Acc Country: {accCountry}</p>
            </div>
          </div>
        </div>
       
      </div>
      <Button 
        variant="outline-danger"
        title="Edit Info"
        className="w-[12%] align-self-end"
        size="sm"
        style={{
          marginRight: '10px',
        }}
        onClick={() => setShowEditModel(true)}
        
      >
        Edit Restaurant 
      </Button>    

      {/* <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => onDeleteHandler(id)}
        message="Are you sure you want to delete this Agent?"
      />
      <DeleteConfirmationModal
        show={contactdeleteModalShow}
        handleClose={handlecontactDeleteModalClose}
        handleDelete={() => onDeletecontact(id)}
        message="Are you sure you want to delete this Contact details?"
      /> */}
      
      <Modal show={showEditModel} onHide={() => setShowEditModel(false)} size="xl">
        <Modal.Header 
      //     style={{
      //    backgroundColor: theme === 'Dark' && '#232329',
      //    color: theme === 'Dark' && '#fff',
      //  }}
          closeButton
          // closeVariant={theme === 'Dark' && 'white'}
          
        >
          
          <Modal.Title>Edit Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPrimary onSubmit={handleEditResDetails} restaurantDetailsId={id} />
        </Modal.Body>
      </Modal>

      {/* <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="lg">
        <Modal.Header
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
          color: theme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={theme === 'Dark' && 'white'}
        >
          <Modal.Title>Additional Information
            <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
        }}
        >
          <AddContactInfo
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditContactSubmit}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={showModalpassword} onHide={handleClosepassword} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangedPassword
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onAddPasswordHander}
          />
        </Modal.Body>
      </Modal> */}

    </div>
  );
}; 

RejectedRequestCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
    userPhone: PropTypes.string.isRequired,
    userEmail: PropTypes.string.isRequired,
    uin: PropTypes.string.isRequired,
    accName: PropTypes.string.isRequired,
    accNumber: PropTypes.string.isRequired,
    ifscCode: PropTypes.string.isRequired,
    remark: PropTypes.string.isRequired,
    branch: PropTypes.string.isRequired,
    bank: PropTypes.string.isRequired,
    accCity: PropTypes.string.isRequired,
    accCountry: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    setRefresh: PropTypes.func.isRequired,
    

};



export default RejectedRequestCard;
