import React from 'react';
import DefaultTabsBorderedBottom from './component/DefaultTabsBorderedBottom';

const Booking = () => (
  <div>
    <DefaultTabsBorderedBottom />
  </div>
  );

export default Booking;
