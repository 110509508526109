import React from 'react';
import ConfirmCard from './ConfirmCard';

const Confirm = () => (
  <div>
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
    <ConfirmCard />
  </div>
  );

export default Confirm;
