import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Container, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GrNotes } from 'react-icons/gr';
import { MdOutlineLunchDining } from 'react-icons/md';
import { ImSpoonKnife } from 'react-icons/im';
import { FaLocationDot } from 'react-icons/fa6';
import { IoTimeOutline } from 'react-icons/io5';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { FiAlignRight } from 'react-icons/fi';
import { IoMdTime, IoMdCloseCircle, IoMdPeople } from 'react-icons/io';
import EditRestaurant from './EditRestaurant';
import EditRestaurantData from '../utils/EditRestaurant';
import DeleteConfirmationModal from './DeleteModal';
import AddAttachment from './AddAttachment';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import LocationCard from './LocationCard';
import ImageGrid from './Images';
import EditLocation from './EditLocation';
import EditRestaurantLocation from './EditRestaurantLocation';
import { PermissionContext } from '../../../../context/PermissionContext';



const RestaurantLocation = ({ alldetails, theme }) => {
  const { setLocationRefesh, setRestaurantInfo, restaurantInfo } = useContext(RestaurantContext);
  const { permissionsAccess } = useContext(PermissionContext);
  // State management
  const [mealOfferds, setMealOffereds] = useState([]);
  const [formatOfferds, setFormatOffereds] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);

  // Event handlers
  const handleEditModalOpen = () => setShowEditModal(true);
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setRestaurantInfo({
      latitude: '',
      longitude: '',
      maplink: '',
    });
  }; 
  
  const onEditLocationHandler = async (values) => {
       console.log(JSON.stringify(values, null, 2));

        // convert time into ist Standared
        const utcStartTime = new Date(values.start_time);
        // Convert to IST and format it to "HH:mm" (24-hour format)
        const formattedStartTime = utcStartTime.toLocaleTimeString('en-IN', {
          timeZone: 'Asia/Kolkata',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        
        const utcEndTime = new Date(values.end_time);
        // Convert to IST and format it to "HH:mm" (24-hour format)
        const formattedEndTime = utcEndTime.toLocaleTimeString('en-IN', {
          timeZone: 'Asia/Kolkata',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, 
        });

     const mealFormat = [];
     const mealOffereds = [];
     if (values.fit) {
        mealFormat.push('1');
     }
     if (values.git) {
        mealFormat.push('2');
     }
     if (values.delivery) {
        mealFormat.push('3');
     }


    if (values.breakfast) {
      mealOffereds.push('1');
    }
    if (values.snacks) {
      mealOffereds.push('2');
    }
    if (values.lunch) {
      mealOffereds.push('3');
    }
    if (values.dinner) {
      mealOffereds.push('4');
    }

    if (mealFormat.length === 0) {
      toast.error('Atleast select one meal format');
      return;
    }
    
    if (mealOffereds.length === 0) {
      toast.error('Atleast select one meal offered');
      return;
    }
    
    if (formattedStartTime === 'Invalid Date' || formattedEndTime === 'Invalid Date') {
      toast.error('Select Valid Start And End Time');
      return;
    }

    if (!restaurantInfo.latitude || !restaurantInfo.longitude || !restaurantInfo.maplink) {
      toast.error('Please select a restaurant location co-ordinates');
      return;
    }

    const formData = new FormData();
    formData.append('id', values.id); 
    formData.append('name', values.name); 
    formData.append('address1', values.address1); 
    formData.append('area', values.area); 
    formData.append('state', values.state); 
    formData.append('pincode', values.pincode); 
    formData.append('description', values.description); 
    formData.append('setting_capacity', values.setting_capacity); 
    formData.append('capping_capacity', values.capping_capacity); 
    formData.append('uin', values.uin);
    formData.append('address2', values.address2); 
    formData.append('city', values.city); 
    formData.append('country', values.country); 
    formData.append('location', values.location);
    formData.append('latitude', restaurantInfo.latitude);
    formData.append('longitude', restaurantInfo.longitude);
    formData.append('map_location', restaurantInfo.maplink);
    formData.append('cuisines', values.cuisines); 
    formData.append('start_time', formattedStartTime); 
    formData.append('end_time', formattedEndTime);  
    formData.append('tax_id', values.tax_id); 
    formData.append('markup_percentage', values.markup_percentage); 
    formData.append('meal_offered', mealOffereds.join(',')); 
    formData.append('meal_format', mealFormat.join(','));  

  //   formData.forEach((value, key) => {
  //     console.log(`${key}: ${value}`);
  // });

    try {
        const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_location_edit.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data inserted successfully.') {
            toast.success('Restaurant Location Edit Successfully...');
            setRestaurantInfo({
              latitude: '',
              longitude: '',
              maplink: '',
            });
             setLocationRefesh(prev => !prev);
             handleEditModalClose();
        } else {
            toast.error('error');
        }
    } catch (error) {
        toast.error(error.message);
    }
  };

  const handleDeleteClick = async (ids) => {
    try {
      const deleteurl = await fetch(`https://navrang.mvd-tech.io/API/restaurant_delete.php?id=${ids}`);
      const response = await deleteurl.json();
       if (response.status && response.message === 'Data deleted successfully') {
         toast.success('Restaurant Location deleted successfully');
         setLocationRefesh(prev => !prev);
          handleDeleteModalClose(); 
       } else {
         toast.error('Error deleting restaurant');
       }
    } catch (error) {
      toast.error(`Error deleting restaurant,${error.message}`);
    }
};

  // Map meal formats on component mount
  useEffect(() => {
    if (alldetails.meal_format) {
      const mealOffer = alldetails.meal_format.split(',');
      const mappedMeals = mealOffer.map((meal) => {
        switch (meal) {
          case '1': return 'Fit';
          case '2': return 'Git';
          case '3': return 'Delivery';
          default: return '';
        }
      });
      setMealOffereds(mappedMeals);
    }
  }, [alldetails.meal_format]);

  // Map meal offerings on component mount
  useEffect(() => {
    if (alldetails.meal_offered) {
      const mealFormat = alldetails.meal_offered.split(',');
      const mappedFormat = mealFormat.map((meal) => {
        switch (meal) {
          case '1': return 'Breakfast';
          case '2': return 'Snacks';
          case '3': return 'Lunch';
          case '4': return 'Dinner';
          default: return '';
        }
      });
      setFormatOffereds(mappedFormat);
    }
  }, [alldetails.meal_offered]);

  return (
    <Container>
      <div 
        className="card mb-3 position-relative" 
        style={{ 
          maxWidth: '100%', 
          backgroundColor: theme === 'Dark' && '#232329',
          border: theme === 'Dark' && '1px solid grey',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            {permissionsAccess?.[1]?.master_id === '2'
        && permissionsAccess?.[1]?.edit === 1 && (
          <Button 
            variant="outline-primary" 
            size="sm" 
            title="edit" 
            onClick={handleEditModalOpen}
          >
            <span className="lnr lnr-file-empty" /> Edit
          </Button>
        )}
            {permissionsAccess?.[1]?.master_id === '2'
        && permissionsAccess?.[1]?.delete === 1 && (
          <Button 
            variant="outline-danger" 
            size="sm" 
            title="Delete"
            onClick={handleDeleteModalShow}
          >
            <span className="lnr lnr-trash" /> Delete
          </Button>
        )}
            
          </ButtonGroup>
        </div>

        <div className="row">
          <div className="col-md-12 mx-2">
            <h2>{alldetails.location}</h2>
            <p>
              {alldetails.address1},
              {alldetails.address2 && ` ${alldetails.address2}`}
            </p>
            <br />
            <p>
              {alldetails.city}, {alldetails.state} {alldetails.country}
            </p>
            <p>
              {alldetails.pincode}
            </p>
          </div>

          <div className="row mt-2">
            <div className="col-md-3 mt-2">
              <p><GrNotes />{' '}Format : {formatOfferds.join(', ')}</p>
            </div>
            <div className="col-md-3 mt-2">
              <p><ImSpoonKnife />{' '}Meals : {mealOfferds.join(', ')}</p>
            </div>
            <div className="col-md-3 mt-2">
              <p><FaLocationDot />{' '}Location : {alldetails.location}</p>
            </div>
            <div className="col-md-3 mt-2">
              <p><MdOutlineLunchDining />{' '}Cuisines : {alldetails.cuisines}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 mt-2">
              <p><IoMdPeople />{' '}Capacity : {alldetails.setting_capacity}</p>
            </div>
            <div className="col-md-3 mt-2">
              <p><IoMdPeople />{' '}Clip Capacity : {alldetails.capping_capacity}</p>
            </div>
            <div className="col-md-3 mt-2">
              <p><IoTimeOutline />{' '}Timing : {alldetails.start_time}/{alldetails.end_time}</p>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div
            className="col-md-6"
          >
            <LocationCard locationid={alldetails.id} theme={theme} />
          </div>
          <div
            className="col-md-6 mb-4"
          >
            <ImageGrid Locationid={alldetails.id} theme={theme} />
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          show={deleteModalShow}
          handleClose={handleDeleteModalClose}
          handleDelete={() => handleDeleteClick(alldetails.id)}
          message="Are you sure you want to delete this Location?"
        />

        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)} // Corrected
          centered
          size="xl"
        >
          <Modal.Header
            style={{
      backgroundColor: theme === 'Dark' ? '#232329' : '',
      color: theme === 'Dark' ? '#fff' : '',
    }}
            closeButton
            closeVariant={theme === 'Dark' ? 'white' : ''}
          >
            <Modal.Title>Edit Location</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
      backgroundColor: theme === 'Dark' ? '#232329' : '',
    }}
          >
            <EditRestaurantLocation id={alldetails.id} onSubmit={onEditLocationHandler} />
          </Modal.Body>
        </Modal>

      </div>
    </Container>
  );
};

RestaurantLocation.propTypes = {
  alldetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    pincode: PropTypes.string.isRequired,
    meal_format: PropTypes.string.isRequired,
    meal_offered: PropTypes.string.isRequired,
    cuisines: PropTypes.string.isRequired,
    setting_capacity: PropTypes.string.isRequired,
    capping_capacity: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.string.isRequired,
};

export default RestaurantLocation;
