import React, { useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
  FaLocationDot, 
  FaPercent, 
  FaUser, 
  FaPhone,
 } from 'react-icons/fa6'; 
import { MdAlternateEmail } from 'react-icons/md';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { AgentContext } from '../../../context/AgentContext';
import EditAgent from './EditAgent';
import AddContactInfo from './AddContactInfo';
import ChangedPassword from './ChangePassword';
import DeleteConfirmationModal from './DeleteModal';




const AgentCard = ({
  id, agentName, uan, addressline1, addressline2, area, city,
  state, country, pincode, location, defaultMarkup, tin, taxCity, userName,
  userEmail, userPhone, uin, accName, accountNo, ifscCode,
  branch, bank, accCity, accCountry, password, logo, gstNo, gstType, gstProof, addressProof,
}) => {
  const {
 setAgentdata, isDelete, setIsDelete, refresh, setRefresh, 
} = useContext(AgentContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalcontact, setShowModalcontact] = useState(false);
  const [showModalpassword, setShowModalpassword] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const [contactdeleteModalShow, setcontactDeleteModal] = useState(false);

  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);
  const handlecontactDeleteModalShow = () => setcontactDeleteModal(true);
  const handlecontactDeleteModalClose = () => setcontactDeleteModal(false);


  const [formValues, setFormValues] = useState({
    id,
    agentName,
    uan,
    addressLine1: addressline1,
    addressLine2: addressline2,
    area,
    city,
    state,
    country,
    pincode,
    location,
    logo,
    gstNo,
    gstType,
    gstProof,
    addressProof,
    defaultMarkup,
    tin,
    taxCity,
    userName,
    userEmail,
    userPhone,
    uin,
    accName,
    accountNo,
    ifscCode,
    branch,
    bank,
    accCity,
    accCountry,
    password,
  });
    // console.log(formValues);

  const onPasswordBtn = () => {
    setFormValues({
      id,
    });
    console.log(formValues);
    setShowModalpassword(true); 
    setShowModalcontact(false);
  };
  const onAddPasswordHander = async (values) => {
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('password', values.passwordSet);
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_password_update.php', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.status && data.message === 'password updated successfully.') {
        toast.success('Password updated successfully');
        setAgentdata(formValues); 
         
         
        setRefresh(prev => !prev);
        setTimeout(() => setShowModalpassword(false), 1000);
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      alert(`An Error Occurred: ${error.message}`);
    }
  };

  const handleClosepassword = () => {
    setShowModalpassword(false); 
    setShowModalcontact(false);
  };

  const onEditHandler = () => {
    setFormValues({
      id,
      agentName,
      addressLine1: addressline1,
      addressLine2: addressline2,
      uan,
      pincode,
      location,
      area,
      city,
      state,
      country,
      logo,
      gstNo,
      gstType,
      gstProof,
      addressProof,
      defaultMarkup,
      tin,
      taxCity,
      userName,
      userEmail,
      userPhone,
      uin,
      accName,
      accountNo,
      ifscCode,
      branch,
      bank,
      accCity,
      accCountry,
    });
    console.log(formValues);
    setShowModal(true);
  };

  const onDeletecontact = async (ids) => {
    // console.log(ids);
      try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/agent_account_delete.php?id=${ids}`);
        const response = await url.json();

        if (response.status && response.message === 'Data Deleted successfully.') {
            toast.success('Data Deleted successfully.');
            handlecontactDeleteModalClose();
            setRefresh(prev => !prev);
        }
      } catch (error) {
        toast.error('Something went Wrong');
      }
   };

  const onEditSubmit = async (values) => {
    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('agentName', values.agentName);
    formData.append('addressLine1', values.addressLine1);
    formData.append('addressLine2', values.addressLine2);
    formData.append('area', values.area);
    formData.append('city', values.city);
    formData.append('state', values.state);
    formData.append('country', values.country);
    formData.append('logo', values.file);
    formData.append('gst_no', values.gst_no);
    formData.append('gst_type', values.gst_type);
    formData.append('gst_proof_file', values.gstProof);
    formData.append('address_proof_file', values.gstAddressProof);
    formData.append('defaultMarkup', values.defaultMarkup);
    formData.append('defaultMarkup', values.file);
    formData.append('taxId', values.taxId);
    formData.append('taxCity', values.taxCity);

    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_edit.php', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.status) {
        toast.success('Agent Edit successful!');
        setAgentdata(formValues); 
        setRefresh(prev => !prev);
        setTimeout(() => setShowModal(false), 1000);
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      toast.error(`An Error Occurred: ${error.message}`);
    }
  };

  const onAddContactInfo = () => {
    setFormValues({
      id,
      userName,
      userEmail,
      userPhone,
      uin,
      accName,
      accountNo,
      ifscCode,
      branch,
      bank,
      accCity,
      accCountry,
    });
    setShowModalcontact(true);
    console.log(formValues);
  };

  const onEditContactSubmit = async (values) => {
    if (values.accNo !== values.confirmAccount) {
      toast.info('Account numbers do not match. Please check and try again.');
      return;
    }

    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('user_name', values.userName);
    formData.append('user_phone', values.userPhone);
    formData.append('user_email', values.userEmail);
    formData.append('uin', values.uin);
    formData.append('acc_name', values.accName);
    formData.append('acc_country', values.accCountry);
    formData.append('account_no', values.accountNo);
    formData.append('acc_city', values.accCity);
    formData.append('branch', values.branch);
    formData.append('ifsc_code', values.ifscCode);
    formData.append('bank', values.bank);

    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_account_update.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.status && data.message === 'Record updated successfully.') {
        toast.success('Record updated successfully.');
        setRefresh(prev => !prev);
        setTimeout(() => {
          setShowModalcontact(false); // Close contact info modal after successful submission
        }, 1000);
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      toast.error(`An Error Occurred: ${error.message}`);
    }
  };

  const onDeleteHandler = async (ids) => {
    try {
      const response = await fetch(`https://navrang.mvd-tech.io/API/agent_delete.php?id=${ids}`, {
        method: 'GET', 
      });
  
     
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      if (data.status === true && data.message === 'Agent record deleted successfully') {
        toast.success('Agent deleted successfully.');
        handleDeleteModalClose();
        setIsDelete(false);
        setRefresh(prev => !prev); 
      } else {
        toast.error('Failed to delete agent. Please try again.');
      }
    } catch (error) {
      toast.error(`Error during deletion: ${error.message}`);
    }
  };
  

  return (
    <div className="card mb-3 position-relative" style={{ maxWidth: '100%' }}>
      {/* <Button
        variant="outline-danger"
        className="position-absolute"
        style={{ top: '10px', right: '10px' }}
        onClick={() => onDeleteHandler(id)}
      >
        <span className="lnr lnr-trash" />
      </Button> */}

      <div className="row g-0">
        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-title">
              {agentName || 'Agent Name'}
              {/* <Button
                variant="outline-secondary"
                className=""
                title="Edit"
                size="sm"
                onClick={onEditHandler}
              >
                <span className="lnr lnr-pencil" />
              </Button> */}
              {/* <Button
                variant="outline-danger"
                className="ms-3 "
                size="sm"
                onClick={() => onDeleteHandler(id)}
              >
                <span className="lnr lnr-trash" />
              </Button> */}
              <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
                <Button 
                  variant="outline-success" 
                  title="Edit" 
                  onClick={onEditHandler}
                >
                  <span className="lnr lnr-pencil" />
                </Button>
                <Button 
                  variant="outline-danger"
                  title="Delete" 
                  onClick={handleDeleteModalShow}
                >
                  <span className="lnr lnr-trash" />
                </Button>
              </ButtonGroup>
            </h3>
            <p className="card-text"><FaLocationDot />
              {' '}{addressline1 || 'Address 1'}, {addressline2 || 'Address 2'} 
            </p>
            <p className="card-text">
              {area || 'Area'}, {city || 'City'}
              
            </p>
            <p className="text-muted"> {state || 'State'}, {country || 'Country'}</p>
          </div>
        </div>

        <div className="col-md-4 col-12 mt-4">
          <div className="card-body">
            <br />
            <p className="card-title"><FaPercent />{' '}TIN #: {tin || 'N/A'}</p>
            <p className="card-text"><FaPercent />{' '}Tax City: {taxCity || 'N/A'}</p>
            <p className="card-text">
              <FaPercent /> {' '}Default Markup: {defaultMarkup || '0'}%
            </p>
          </div>
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h5 className="card-title">Primary Contact
              {/* <Button
                variant="outline-secondary"
                className="ms-3"
                title="Edit"
                size="sm"
                onClick={onAddContactInfo}
              >
                <span className="lnr lnr-pencil" />
              </Button> */}
              <ButtonGroup icons dir="ltr" className="mt-2 mx-4">
                <Button 
                  variant="outline-success" 
                  title="Edit" 
                  onClick={onAddContactInfo}
                >
                  <span className="lnr lnr-pencil" />
                </Button>
                <Button 
                  variant="outline-danger"
                  title="Delete" 
                  onClick={handlecontactDeleteModalShow}
                >
                  <span className="lnr lnr-trash" />
                </Button>
              </ButtonGroup>
            </h5>
            <p className="card-text"><FaUser />{' '}Name: {userName || 'N/A'}</p>
            <p className="card-text"><MdAlternateEmail />{' '}Email: {userEmail || 'N/A'}</p>
            <p className="card-text"><FaPhone />{' '}Phone: {userPhone || 'N/A'}</p>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => onDeleteHandler(id)}
        message="Are you sure you want to delete this Agent?"
      />
      <DeleteConfirmationModal
        show={contactdeleteModalShow}
        handleClose={handlecontactDeleteModalClose}
        handleDelete={() => onDeletecontact(id)}
        message="Are you sure you want to delete this Contact details?"
      />

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAgent
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditSubmit}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Additional Information
            <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddContactInfo
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditContactSubmit}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showModalpassword} onHide={handleClosepassword} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangedPassword
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onAddPasswordHander}
          />
        </Modal.Body>
      </Modal>

    </div>
  );
}; 

AgentCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  agentName: PropTypes.string,
  addressline1: PropTypes.string,
  addressline2: PropTypes.string,
  uan: PropTypes.string,
  pincode: PropTypes.string,
  location: PropTypes.string,
  logo: PropTypes.string,
  gstNo: PropTypes.string,
  gstType: PropTypes.string,
  gstProof: PropTypes.string,
  addressProof: PropTypes.string,
  area: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  tin: PropTypes.string,
  taxCity: PropTypes.string,
  defaultMarkup: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
  uin: PropTypes.number,
  accName: PropTypes.string,
  accountNo: PropTypes.number,
  ifscCode: PropTypes.string,
  branch: PropTypes.string,
  bank: PropTypes.string,
  accCity: PropTypes.string,
  accCountry: PropTypes.string,
  password: PropTypes.string,
};

AgentCard.defaultProps = {
  agentName: '',
  addressline1: '',
  addressline2: '',
  area: '',
  city: '',
  state: '',
  uan: '',
  pincode: '',
  location: '',
  country: '',
  tin: '',
  taxCity: '',
  logo: '',
  gstNo: '',
  gstType: '',
  gstProof: '',
  addressProof: '',
  defaultMarkup: '',
  userName: '',
  userEmail: '',
  userPhone: '',
  uin: '',
  accName: '',
  accountNo: '',
  ifscCode: '',
  branch: '',
  bank: '',
  accCity: '',
  accCountry: '',
  password: '',
};

export default AgentCard;
