import React from 'react';
import PropTypes from 'prop-types';
import LogInForm from '@/shared/components/account/loginForm/LogInForm';
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements';


const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = () => (
  <AccountWrap>
    <AccountContent>
      <AccountCard>
        <AccountHead>
          <AccountTitle>
            Welcome to
            <AccountLogo> Bit
              <AccountLogoAccent>Vise</AccountLogoAccent>
            </AccountLogo>
          </AccountTitle>
          {/* <h4 className="subhead" /> */}
        </AccountHead>
        <LogInForm form="log_in_form" />
        {/* <AccountOr>
          <p>Or Easily Using</p>
        </AccountOr> */}
        {/* Add other social login buttons as needed */}
        
      </AccountCard>
    </AccountContent>
  </AccountWrap>
  );

// LogIn.propTypes = {
//   changeIsOpenModalFireBase: PropTypes.func.isRequired,
// };

export default LogIn;
