import React, { useState, useContext } from 'react';
import { FormGroupLabel } from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { CiCirclePlus } from 'react-icons/ci';
import { IoIosRemoveCircle } from 'react-icons/io';
import { Table } from '@/shared/components/TableElements';
import { Mealcontext } from '../../../../context/MealContext';

const AddItems = () => {
  const { meals, setMeals } = useContext(Mealcontext);

  const [itemValue, setItemValue] = useState('');
  const [descValue, setDescValue] = useState('');

  const handleAddMeal = () => {
    if (!itemValue.trim() || !descValue.trim()) return;

    const newMeal = {
      item_id: Date.now(),
      name: itemValue.trim(),
      description: descValue.trim(),
    };

    setMeals(prevMeals => [...prevMeals, newMeal]);

    // Clear fields
    setItemValue('');
    setDescValue('');
  };

  const handleRemoveMeal = (mealId) => {
    setMeals(prevMeals => prevMeals.filter(meal => meal.item_id !== mealId));
  };

  return (
    <div className="container">
      <Table responsive hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Item</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {meals.map((meal, index) => (
            <tr key={meal.item_id}>
              <td>{index + 1}</td>
              <td>{meal.name}</td>
              <td>{meal.description}</td>
              <td>
                <Button
                  type="button"
                  variant="outline-danger"
                  size="sm"
                  className="mt-2"
                  onClick={() => handleRemoveMeal(meal.item_id)}
                >
                  <IoIosRemoveCircle />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <FormGroupLabel>Item</FormGroupLabel>
              <input
                type="text"
                placeholder="Item"
                className="form-control"
                value={itemValue}
                onChange={e => setItemValue(e.target.value)}
              />
            </td>
            <td>
              <FormGroupLabel>Description</FormGroupLabel>
              <input
                type="text"
                placeholder="Description"
                className="form-control"
                value={descValue}
                onChange={e => setDescValue(e.target.value)}
              />
            </td>
            <td colSpan="2">
              <Button
                type="button"
                variant="outline-success"
                rounded
                size="sm"
                style={{
                  marginTop: '45px',
                }}
                onClick={handleAddMeal}
              >
                <CiCirclePlus />
              </Button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default AddItems;
