import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { MdLunchDining, MdOutlinePeopleOutline } from 'react-icons/md';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { FiAlignRight } from 'react-icons/fi';
import { IoMdTime, IoMdCloseCircle } from 'react-icons/io';
import { Mealcontext } from '../../../../context/MealContext';
import MealDelete from '../utils/MealDeleteHandler';
import DeleteConfirmationModal from './DeleteModal';
import AddEditMeal from './AddEditMeal';
import AddAttachment from './AddAttachment';
import { PermissionContext } from '../../../../context/PermissionContext';

const MealCard = ({ 
  id, 
  mealname, 
  // restaurantid,
  restaurantname,
  description,
  capacity, 
  type, 
  price,
  finalprice, 
  format, 
  image, 
  isVeg,
  mealOffereds,
  cuisine,
  theme,
}) => {
  const [mealOfferd, setMealOffered] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);

 
  const { 
    refresh,
    setRefresh, 
    meals, 
    setMeals, 
    setIsDetailsClick, 
    setRestaurantDetailsId,
    selectedid,
    isDetailsClick,
   } = useContext(Mealcontext);
   const { permissionsAccess } = useContext(PermissionContext);
  // const [restaurantName, seRestaurantName] = useState('');
  // const [starttime, setStartTime] = useState('');
  // const [endTime, setEndTime] = useState('');
  // const [settingCapacity, setSettingCapacity] = useState('');
  // const [cappingCapacity, setCappingCapacity] = useState('');
  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);
  const [restaurantSelectoption, setRestaurantSelectOption] = useState(null);
  const [showAttachmentModal, setShowAtttachmentModal] = useState(false);
  const [date, setDate] = useState(null);
  const toggleModalAttachment = () => setShowAtttachmentModal(!showAttachmentModal);
  const toggleModal = () => {
    setShowModal(!showModal);
    setMeals([]);
  };

  let label;

  if (isVeg === '1') {
    label = <span className="bg-success text-white p-2 rounded">VEG</span>;
  } else if (isVeg === '2') {
    label = <span className="bg-danger text-white p-2 rounded w-50">NON-VEG</span>;
  } else {
    label = (
      <div>
        <span className="bg-success text-white p-2 rounded w-50">VEG</span>
        <span className="bg-danger text-white p-2 rounded w-50">NON-VEG</span>
      </div>
);
  }

  
  // const fetchrestaurantName = async () => {
  //   try {
  //     const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${restaurantid}`);
  //     const response = await url.json();
  //     if (response.status && response.message === 'Success') {
  //       seRestaurantName(response?.data[0]?.name);
  //       setStartTime(response?.data[0]?.start_time);
  //       setEndTime(response?.data[0]?.end_time);
  //       setSettingCapacity(response?.data[0]?.setting_capacity);
  //       setCappingCapacity(response?.data[0]?.capping_capacity);
  //     }
  //   } catch (error) {
  //     toast.error('Failed to fetch restaurant name');
  //     // console.error(error.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchrestaurantName();
  // }, [restaurantid]); 

  useEffect(() => {
    if (mealOffereds) {
      const mealOffer = mealOffereds.split(','); 

      const mappedMeals = mealOffer.map((meal) => {
        switch (meal) {
          case '1':
            return 'Breakfast';
          case '2':
            return 'Snacks';
          case '3':
            return 'Lunch';
          case '4':
            return 'Dinner';
          default:
            return ''; 
        }
      });

      setMealOffered(mappedMeals); 
    }
  }, [mealOffereds]); 

  // Edit Meal Data Handler
  const EditMealData = async (values) => {
    // console.log(restaurantSelectoption);
      console.log(JSON.stringify(values, null, 2));
    // let selectedRestaurant = values.restaurant_id.value;
    // if (!selectedRestaurant) {
    //   selectedRestaurant = selectedid;
    // }
 

    // console.log(selectedid);
    const formattedValues = {
      items: meals,
  };
  const utcStartDate = values.start_date;
  const utcEndDate = values.end_date;

  // Convert to IST
  const startDate = new Date(utcStartDate);
  const endDate = new Date(utcEndDate);

  // IST offset is +5:30
 const istOffset = 5.5 * 60 * 60 * 1000;
 const startDateIST = new Date(startDate.getTime() + istOffset);
 const endDateIST = new Date(endDate.getTime() + istOffset); 
 
 // Format the dates (DD-MM-YYYY)
 const formatDate = dates => `${dates.getFullYear()}-${String(dates.getMonth() + 1)
  .padStart(2, '0')}-${String(dates.getDate()).padStart(2, '0')}`;


const formattedStartDate = formatDate(startDateIST);
const formattedEndDate = formatDate(endDateIST);

    const mealOffered = [];

    if (values.breakfast) {
        mealOffered.push('1');
    }
    if (values.snacks) {
        mealOffered.push('2');
    }
    if (values.lunch) {
        mealOffered.push('3');
    }
    if (values.dinner) {
        mealOffered.push('4');
    }

    const location = [];
    

    if (values.locationsid && Array.isArray(values.locationsid)) {
      values.locationsid.forEach((locationItem) => {
        if (locationItem?.value) {
          location.push(locationItem.value); 
        }
      });
    } else if (values.locations && Array.isArray(values.locations)) {
      values.locations.forEach((locationItem) => {
        if (locationItem?.location_id) {
          location.push(locationItem.location_id); 
        }
      });
    }

    if (location.length === 0) {
      toast.error('location not selected....');
      return;
    }


    if (formattedStartDate === 'NaN-NaN-NaN' || formattedEndDate === 'NaN-NaN-NaN') {
      toast.error('Select Date');
      return;
    }

    const formData = new FormData();
    formData.append('id', values.id);
    formData.append('name', values.name); 
    formData.append('restaurant_id', restaurantSelectoption.value);
    formData.append('location_ids', location.join(','));  
    formData.append('price', values.price); 
    formData.append('foc', values.foc); 
    formData.append('foc_on', values.foc_on); 
    formData.append('meal_type', values.meal_type); 
    formData.append('discription', values.description); 
     formData.append('photo', values.logo); 
    formData.append('markup_percentage', values.markup_percentage);
    formData.append('cuisine', values.cuisines); 
    formData.append('start_date', formattedStartDate); 
    formData.append('end_date', formattedEndDate); 
    formData.append('defaultMarkup', values.markup_percentage); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', values.format); 
    formData.append('veg_non_veg', values.veg); 
    
    if (Array.isArray(formattedValues.items)) {
      formData.append('items', JSON.stringify(formattedValues)); 
  } else {
      console.error('Items format is invalid');
  }

      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
    });

    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_edit.php', {
          method: 'POST',
          body: formData,
      });
      if (!response.ok) {
          console.log(`Network response was not ok: ${response.statusText}`);
      }
      const responseData = await response.json();
      if (responseData.status && responseData.message === 'Data updated successfully.') {
          toast.success('Meal Edit Successfully...');
          setRefresh(prev => !prev);
          setShowModal(false);
          setMeals([]);
      } else {
          toast.error(responseData.message);
      }
  } catch (error) {
      toast.error(error.message);
  }   
  };
  // image source 
  const logo = `https://navrang.mvd-tech.io/API/images/meal/${image}`;
 const MealDetails = () => {
  setIsDetailsClick(true);
  setRefresh(prev => !prev);
  // console.log(`mealDetailsClick${isDetailsClick}`);
  setRestaurantDetailsId(id);
 };


  return (
    <div
      className="card mb-3 position-relative" 
    >
      <div className="row">
        <div className="col-md-4 col-12">
          <img 
            src={logo} 
            alt={mealname} 
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '10px',
            }} 
          />
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-text" style={{ color: theme === 'Dark' ? 'white' : 'black' }}>
              {mealname }  {restaurantname ? `/${restaurantname}` : '' }
            </h3>

            <p className="card-text"><FiAlignRight /> {description}</p>
            <p className="card-text">
              <IoMdTime /> Timing: {
                 mealOfferd.join(',')
              }
            </p>
            <p className="card-text">
              Type : {
                (() => {
                  switch (format) {
                    case '1':
                      return 'FIT';
                    case '2':
                      return 'GIT';
                    default:
                      return 'Delhivery';
                  }
                })()
              }
            </p>
            <p className="card-text">
              Format: {type}
            </p>

          </div>
        </div>

        <div className="col-md-4 col-12">
          <div className="card-body">
            {label}
            <p className="card-text mt-2"><MdLunchDining /> Cuisine: {cuisine}</p>
            <p className="card-text"><MdOutlinePeopleOutline /> Capacity: {capacity}</p>
          </div>
          <div className="card-body price d-flex align-items-center">
            <h2 className="card-text me-2" style={{ color: 'red' }}>
              {price} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
            <h2 className="card-text me-2" style={{ color: 'blue' }}>
              {finalprice} <p className="d-inline" style={{ fontSize: '0.75em' }}>AED</p>
            </h2>
          </div>
          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            {permissionsAccess?.[2]?.master_id === '3'
        && permissionsAccess?.[2]?.create === 1 && (
          <Button variant="outline-warning" size="sm" title="Add Attachment" onClick={toggleModalAttachment}>
            +
          </Button>
        )} 

            {permissionsAccess?.[2]?.master_id === '3'
        && permissionsAccess?.[2]?.edit === 1 && (
          <Button variant="outline-success" title="Edit" onClick={toggleModal}>
            <span className="lnr lnr-pencil" /> Edit          
          </Button>
        )} 

            {permissionsAccess?.[2]?.master_id === '3'
        && permissionsAccess?.[2]?.delete === 1 && (
          <Button variant="outline-danger" title="Delete" onClick={handleDeleteModalShow}>
            <span className="lnr lnr-trash" /> Delete
          </Button>
        )}

            <Button variant="outline-primary" title="Details" onClick={() => MealDetails()}>
              <span className="lnr lnr-file-empty" /> Details
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => MealDelete(id, setRefresh)}
        message="Are you sure you want to delete this meal?"
      />
      {showModal && (
        <div 
          className="modal fade show" 
          style={{ display: 'block' }} 
          tabIndex="-1" 
          aria-labelledby="exampleModalLabel"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">Edit Meal</h3>

                <button 
                  type="button" 
                  style={{ 
                    backgroundColor: 'transparent', 
                    border: 'none', 
                    outlineColor: 'green', 
                    fontSize: 'large', 
                    cursor: 'pointer',
                    color: 'grey', 
                  }} 
                  size="large" 
                  onClick={toggleModal} 
                  aria-label="Close"
                >
                  X 
                </button>
              </div>
              <div className="modal-body">
                <AddEditMeal 
                  onSubmit={EditMealData} 
                  id={id} 
                  setRestaurantSelectOption={setRestaurantSelectOption} 
                  setDate={setDate}
                />
              </div>
              {/* <div className="modal-footer">
                <Button type="button" variant="danger" rounded onClick={toggleModal}>
                  Close
                </Button>
                <Button type="button" variant="success" rounded>
                  Save changes
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      )}
      {showAttachmentModal && (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">Add Attachment</h3>
              <button 
                type="button" 
                style={{ 
                  backgroundColor: 'transparent', 
                  border: 'none', 
                  color: 'grey',
                  fontSize: 'large', 
                  cursor: 'pointer', 
                }} 
                onClick={toggleModalAttachment} 
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <AddAttachment uid={id} />
            </div>
          </div>
        </div>
      </div>
            )}
    </div>
  );
};

MealCard.propTypes = {
  id: PropTypes.string.isRequired,
  mealname: PropTypes.string.isRequired,
  restaurantname: PropTypes.string.isRequired,
  // restaurantid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  finalprice: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isVeg: PropTypes.string.isRequired,
  mealOffereds: PropTypes.string.isRequired,
  cuisine: PropTypes.string.isRequired,
  capacity: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default MealCard;
