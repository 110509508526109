import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Manager from './Manager';




const UsersManagement = () => (
  <Container>
    <Manager />
  </Container>
  );

export default UsersManagement;
