import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
 
} from '@/shared/components/form/FormElements';
import { renderMaskedField } from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';

const EditAgent = ({ formValues, onSubmit }) => {
  const path = 'https://navrang.mvd-tech.io/API/images/agent/';
  const gstpath = 'https://navrang.mvd-tech.io/API/images/agent/GST/';
  const [selectedImage, setSelectedImage] = useState(path + formValues.logo);
  const [selectedAdressProof, setSelectedAdressProof] = useState(gstpath + formValues.addressProof);
  const [selectedGstProof, setSelectedGSTProof] = useState(gstpath + formValues.gstProof);

  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); 
      };
      reader.readAsDataURL(file);
      input.onChange(file); 
    } else {
      setSelectedImage(null); 
      input.onChange(null); 
    }
  };

  const handAddressFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedAdressProof(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedAdressProof(null);
      input.onChange(null);
    }
  };


  const handGstFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedGSTProof(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedGSTProof(null);
      input.onChange(null);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <Form onSubmit={onSubmit} initialValues={formValues}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                 
                  <FormGroup>
                    <FormGroupLabel>Agent Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="agentName"
                        component="input"
                        type="text"
                        placeholder="Agent Name"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="addressLine1"
                        component="input"
                        type="text"
                        placeholder="Address Line 1"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component={renderMaskedField}
                        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        type="text"
                        placeholder="Pincode"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="file"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormGroupLabel>GST No</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gstNo"
                        component="input"
                        type="text"
                        placeholder="GST No"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>GST Type</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gstType"
                        component="input"
                        type="text"
                        placeholder="GST Type"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Proof</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="addressProof"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handAddressFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedAdressProof !== gstpath
? (
  <FormGroup>
    <FormGroupLabel>Selected Address Proof</FormGroupLabel>
    <FormGroupField>
      <img
        src={selectedAdressProof}
        alt="Selected Address Proof"
        style={{ maxWidth: '200px', maxHeight: '200px' }}
      />
    </FormGroupField>
  </FormGroup>
) : (
  <div>
    <span style={{ color: 'red' }}>
      No Prior Address Proof Upload
    </span>
  </div>
)}
          
                  <FormGroup>
                    <FormGroupLabel>GST Proof</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gstProof"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handGstFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedGstProof !== gstpath ? (
                    <FormGroup>
                      <FormGroupLabel>Selected GST Proof</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedGstProof}
                          alt="Selected Address Proof"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  ) : (
                    <div>
                      <span style={{ color: 'red' }}>
                        No Prior Gst Proof Upload
                      </span>
                    </div>
                  ) }
                 
                </FormHalfContainer>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UAN<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uan"
                        component="input"
                        type="number"
                        placeholder="UAN"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="addressLine2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Select your country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Location<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="Location"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Default Markup<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="defaultMarkup"
                        component="input"
                        type="number"
                        placeholder="Default Markup"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX Identification<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxId"
                        component="input"
                        type="text"
                        placeholder="Tax Identification"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxCity"
                        component="input"
                        type="text"
                        placeholder="Tax City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  
                </FormHalfContainer>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}; 

EditAgent.propTypes = {
  formValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    agentName: PropTypes.string,
    addressline1: PropTypes.string,
    addressline2: PropTypes.string,
    area: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    taxId: PropTypes.string,
    taxCity: PropTypes.string,
    addressProof: PropTypes.string,
    gstProof: PropTypes.string,
    gstNo: PropTypes.string,
    gstType: PropTypes.string,
    defaultMarkup: PropTypes.string,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userPhone: PropTypes.string,
    uin: PropTypes.number,
    accName: PropTypes.string,
    accountNo: PropTypes.number,
    ifscCode: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    accCity: PropTypes.string,
    logo: PropTypes.string,
    accCountry: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EditAgent;
