import React, { useEffect, useState, useContext } from 'react';
import { 
  Col,
  Container, 
  Row, 
  Modal,
} from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import toast from 'react-hot-toast';
import { FaRegUser } from 'react-icons/fa6';
import { MdOutlineMailOutline, MdOutlineMapsHomeWork } from 'react-icons/md';
import { IoLocationOutline } from 'react-icons/io5';
import { FaPhoneAlt, FaBraille } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Carousel from '@/shared/components/carousel/CarouselMultiply';
import Image from 'react-bootstrap/Image';
import { CgDetailsMore, CgNotes } from 'react-icons/cg';
import { IoMdTime } from 'react-icons/io';
import { Button } from '@/shared/components/Button';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import RestaurantLocation from './RestautantLocation';
import PrimaryContactForm from './PrimaryContactForm';
import AddLocation from './AddLocation';
import EditPrimary from './EditPrimary';
import AddRestaurant from './AddRestaurant';
import LocationDetails from './LocationDetails';
import { PermissionContext } from '../../../../context/PermissionContext';
import { ThemeContext } from '../../../../context/ThemeContext';

const ResaurantDetails = () => {
  const { 
      restaurantDetailsId,
      refresh,
      setRefresh, 
      setclick,
      setActiveKey,
      setIsDetailsClick,
      setRestaurantInfo,
      restaurantInfo,
       setLocationRefesh,
       LocationRefesh,
  } = useContext(RestaurantContext);
  const { permissionsAccess } = useContext(PermissionContext);
  const { currentTheme } = useContext(ThemeContext);

  const [restaurantdata, setRestaurantData] = useState([]);
  const [parsedImages, setParsedImages] = useState([]);
  const [showModalcontact, setShowModalcontact] = useState(false);
  const [showModalLocation, setShowModalLocation] = useState(false);


  if (!restaurantDetailsId) {
    toast.error('No restaurant selected!');
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!restaurantDetailsId) {
        toast.error('No restaurant selected!');
        return;
      }

      try {
        const response = await fetch(
          `https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${restaurantDetailsId}`,
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        if (data.status && data.message === 'Success') {
          if (isMounted) {
            setRestaurantData(data?.data[0] || []);
            // handle state 
            // setRestaurantInfo({
            //   restaurantname: data.data[0].name,
            // });
          }
        } else {
          toast.error('Failed to fetch restaurant data');
          if (isMounted) {
            setRestaurantData([]);
          }
        }
      } catch (error) {
        toast.error('Error fetching restaurant details');
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [refresh, LocationRefesh]);

  const backHandler = () => {
    setActiveKey('1');
    setclick('searchSelect');
    setIsDetailsClick(false);
  };

  const onAddContactInfo = () => {
    setShowModalcontact(true);
  };
  const onAddLocation = () => {
    setShowModalLocation(true);
  };

  const onEditContactSubmit = async (values) => {
    const formData = new FormData();
    formData.append('id', restaurantDetailsId);
    formData.append('user_name', values.user_name);
    formData.append('user_phone', values.user_phone);
    formData.append('user_email', values.user_email);
    formData.append('uin', values.uin);
    formData.append('acc_name', values.acc_name);
    formData.append('acc_country', values.acc_country);
    formData.append('account_no', values.account_no);
    formData.append('acc_city', values.acc_city);
    formData.append('branch', values.branch);
    formData.append('ifsc_code', values.ifsc_code);
    formData.append('bank', values.bank);
    formData.append('photo', values.file);

    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });

       try {
           const url = await fetch('https://navrang.mvd-tech.io/API/restaurant_edit.php', {
            method: 'POST',
            body: formData,
           });
            const response = await url.json();
            if (response.status && response.message === 'Data updated successfully.') {
                toast.success('Data updated successfully.');
                setRefresh(prev => !prev);
                setShowModalcontact(false);
            } else {
                toast.error(response.message);
            }
       } catch (error) {
           toast.error(error.message);
       }
  };


  const onAddLocationSubmit = async (values) => {
     // console.log(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);

    // convert time into ist Standared
    const utcStartTime = new Date(values.fromTime);
// Convert to IST and format it to "HH:mm" (24-hour format)
const formattedStartTime = utcStartTime.toLocaleTimeString('en-IN', {
  timeZone: 'Asia/Kolkata',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
});

const utcEndTime = new Date(values.toTime);
// Convert to IST and format it to "HH:mm" (24-hour format)
const formattedEndTime = utcEndTime.toLocaleTimeString('en-IN', {
  timeZone: 'Asia/Kolkata',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false, 
});

// console.log(formattedStartTime); 
// console.log(formattedEndTime); 

    

     const mealOffered = [];
     const mealFormat = [];

     if (values.fit) {
        mealFormat.push('1');
     }
     if (values.git) {
        mealFormat.push('2');
     }
     if (values.delivery) {
        mealFormat.push('3');
     }


    if (values.breakfast) {
        mealOffered.push('1');
    }
    if (values.snacks) {
        mealOffered.push('2');
    }
    if (values.lunch) {
        mealOffered.push('3');
    }
    if (values.dinner) {
        mealOffered.push('4');
    }

    if (mealFormat.length === 0) {
      toast.error('Atleast select one meal format');
      return;
    }
    
    if (mealOffered.length === 0) {
      toast.error('Atleast select one meal offered');
      return;
    }
    
    if (formattedStartTime === 'Invalid Date' || formattedEndTime === 'Invalid Date') {
      toast.error('Select Valid Start And End Time');
      return;
    }

    if (!restaurantInfo.latitude || !restaurantInfo.longitude || !restaurantInfo.maplink) {
      toast.error('Please select a restaurant location co-ordinates');
      return;
    }

    

    const formData = new FormData();
    formData.append('restaurant_id', restaurantDetailsId);
    formData.append('name', values.restaurantName); 
    formData.append('address1', values.address1); 
    formData.append('area', values.area); 
    formData.append('state', values.state); 
    formData.append('pincode', values.pincode); 
    formData.append('description', values.discription); 
     formData.append('photo', values.logo); 
    formData.append('setting_capacity', values.settingCap); 
    formData.append('capping_capacity', values.capCapacity); 
    formData.append('uin', values.uin);
    formData.append('address2', values.address2); 
    formData.append('city', values.city); 
    formData.append('country', values.country); 
    formData.append('cuisines', values.cuisines); 
    formData.append('start_time', formattedStartTime); 
    formData.append('end_time', formattedEndTime); 
    formData.append('latitude', restaurantInfo.latitude); 
    formData.append('longitude', restaurantInfo.longitude); 
    formData.append('map_location', restaurantInfo.maplink); 
    formData.append('location', values.location); 
    formData.append('tax_id', values.taxID); 
    formData.append('markup_percentage', values.markup); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', mealFormat.join(','));  
    
    // formData.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });


    try {
        const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_location_add.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data inserted successfully.') {
            toast.success('Restaurant Location Added Successfully...');
            setRestaurantInfo({
              latitude: '',
              longitude: '',
              maplink: '',
            });
            setLocationRefesh(prev => !prev);
            setShowModalLocation(false);
        } else {
            toast.error(responseData.message);
        }
    } catch (error) {
        toast.error(error.message);
    }    
  };


  // useEffect(() => {
  //   if (restaurantdata && restaurantdata.attachment) {
  //     try {
  //       setParsedImages(JSON.parse(restaurantdata.attachment));
  //     } catch (error) {
  //       console.error('Error parsing attachment:', error);
  //     }
  //   }
  // }, [restaurantdata]);


  const hideaddlocationModal = () => {
    setRestaurantInfo({
      latitude: '',
      longitude: '',
      maplink: '',
    });
    setShowModalLocation(false);
  };

  return (
    <Container
      style={{
      padding: '10px',
    }}
    >
      <div style={{ float: 'right' }}>
        {permissionsAccess?.[1]?.master_id === '2'
        && permissionsAccess?.[1]?.edit === 1 && (
          <Button variant="waning" size="sm" onClick={onAddContactInfo}>Edit</Button>
        )}
        
        <Button variant="primary" size="sm" onClick={backHandler}>Back</Button>
      </div>

      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-12">
                  <div className="restaurantname">
                    <h2> {restaurantdata.name}</h2>
                  </div>
                  <div className="restaurantPrimaryData">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ marginTop: '10px', fontWeight: 'bold' }}>Primary Contact Details</h5>
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                          <div className="col-md-3">
                            <p> <FaRegUser /> Name : {restaurantdata.user_name}</p>
                          </div>
                          <div className="col-md-3">
                            <p><MdOutlineMailOutline /> Email : {restaurantdata.user_email}</p>
                          </div>
                          <div className="col-md-3">
                            <p><FaPhoneAlt />{' '}Phone No : {restaurantdata.user_phone}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="restaurantAccountData">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 style={{ marginTop: '10px', fontWeight: 'bold' }}>Account Details</h5>
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                          <div className="col-md-3">
                            <p><FaRegUser />{' '}Acc Name : {restaurantdata.acc_name}</p>
                          </div>
                          <div className="col-md-3">
                            <p><FaBraille />{' '}AC # : {restaurantdata.account_no}</p>
                          </div>
                          <div className="col-md-3">
                            <p><FaBraille />{' '}IFSC : {restaurantdata.ifsc_code}</p>
                          </div>
                          <div className="col-md-3">
                            <p><MdOutlineMapsHomeWork />{' '}Bank : {restaurantdata.bank}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                          <div className="col-md-3">
                            <p><IoLocationOutline />{' '}Branch : {restaurantdata.branch}</p>
                          </div>
                          <div className="col-md-3">
                            <p><IoLocationOutline />{' '}City : {restaurantdata.acc_city}</p>
                          </div>
                          <div className="col-md-3">
                            <p><IoLocationOutline />{' '}Country : {restaurantdata.acc_country}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        
        <Col xs={12} md={12}>
          <div style={{ float: 'right' }}>
            {permissionsAccess?.[1]?.master_id === '2'
        && permissionsAccess?.[1]?.create === 1 && (
          <Button variant="primary" size="sm" onClick={onAddLocation}>Add Location</Button>
        )}
            
          </div>
          <Card>
            
            <CardBody>
              
              <div className="row">
                <div className="col-md-12"> 

                  <LocationDetails 
                    restaurantDetailsId={restaurantDetailsId}
                    theme={currentTheme}
                  />
                  {/* <RestaurantLocation images={parsedImages} />
                  <RestaurantLocation images={parsedImages} />
                  <RestaurantLocation images={parsedImages} />
                  <RestaurantLocation images={parsedImages} />
                  <RestaurantLocation images={parsedImages} /> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="xl">
        <Modal.Header
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
          color: currentTheme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        
        >
          <Modal.Title>Primary Information
            {/* <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
          
        }}
        >
          <EditPrimary 
            onSubmit={onEditContactSubmit}
            restaurantDetailsId={restaurantDetailsId}
          />
          {/* <PrimaryContactForm
            onSubmit={onEditContactSubmit}
            restaurantDetailsId={restaurantDetailsId}
          /> */}
        </Modal.Body>
      </Modal>
      <Modal show={showModalLocation} onHide={hideaddlocationModal} size="xl">
        <Modal.Header 
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
          color: currentTheme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Location
            {/* <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: currentTheme === 'Dark' && '#232329',
            
          }}
        >
          <AddRestaurant
            restaurantname={restaurantdata.name} 
            onSubmit={onAddLocationSubmit}
          />

          {/* <AddLocation
            onSubmit={onAddLocationSubmit}
            
          /> */}
        </Modal.Body>
      </Modal>
    </Container>
);
};


// MealDetails.prototype = {
//   setActiveKey: PropTypes.func.isRequired,
// };

export default ResaurantDetails;
