import React, { useContext, useEffect, useState } from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form, 
} from 'react-bootstrap';
import { Button, ButtonGroup } from '@/shared/components/Button';
import { FaFilter } from 'react-icons/fa';
import toast from 'react-hot-toast';
import AgentCard from './AgentCard';
import { AgentContext } from '../../context/AgentContext';
import { ThemeContext } from '../../context/ThemeContext';

const Search = () => {
  const [agent, setAgent] = useState([]);
  const [filteragent, setFilterAgent] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setRefresh, refresh } = useContext(AgentContext);
  const { currentTheme } = useContext(ThemeContext);



    const fetchData = async () => {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_data.php');
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setAgent(data.data);
        setFilterAgent(data.data);
      } else {
        console.log('No data fetched');
        setFilterAgent([]);
      }
    };


  useEffect(() => {
    fetchData();
  }, [refresh]);
  

  if (agent.length <= 0) {
    return (
      <div>
        No Agent Found
      </div>
    );
  }
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = agent.filter(agentdata => agentdata.name.toLowerCase().includes(query.toLowerCase())
        || agentdata.city.toLowerCase().includes(query.toLowerCase())
        || agentdata.state.toLowerCase().includes(query.toLowerCase())
        || agentdata.country.toLowerCase().includes(query.toLowerCase()));
      setFilterAgent(filteredData);
    } else {
      setFilterAgent(agent);
    }
  };

  const refreshHandler = () => {
    setRefresh(prev => !prev);
    toast.success('Refresh....');
  };

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>
      <Row>
        <div className="col-md-4 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Name, City, State, or Country"
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1"
            style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
          />

          <ButtonGroup icons dir="ltr" className="mt-2">
            <Button
              variant="outline-secondary"
              className="ms-3"
              title="Refresh"
              size="sm"
              onClick={refreshHandler}
            >
              <span className="lnr lnr-sync" />
            </Button>
          </ButtonGroup>
          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            <Button variant="outline-info" title="Filter">
              <FaFilter />
            </Button>
          </ButtonGroup>
        </div>
      </Row>
      <Row>
        <Container>
          {filteragent.length > 0 ? (
            filteragent.map(agentdata => (
              <AgentCard
                key={agentdata.id}
                id={agentdata.id}
                agentName={agentdata.name}
                uan={agentdata.uan}
                addressline1={agentdata.address1}
                addressline2={agentdata.address2}
                area={agentdata.area}
                city={agentdata.city}
                state={agentdata.state}
                country={agentdata.country}
                pincode={agentdata.pincode}
                logo={agentdata.logo}
                gstNo={agentdata.gst_no}
                gstType={agentdata.gst_type}
                gstProof={agentdata.gst_proof_file}
                addressProof={agentdata.address_proof_file}
                location={agentdata.location}
                defaultMarkup={agentdata.markup}
                taxId={agentdata.tax_id}
                taxCity={agentdata.tax_city}
                userName={agentdata.user_name}
                userEmail={agentdata.user_email}
                userPhone={agentdata.user_phone}
                uin={agentdata.uin}
                accName={agentdata.acc_name}
                accountNo={agentdata.account_no}
                ifscCode={agentdata.ifsc_code}
                branch={agentdata.branch}
                bank={agentdata.bank}
                accCity={agentdata.acc_city}
                accCountry={agentdata.acc_country}
                password={agentdata.password}
                theme={currentTheme}
              />
            ))
          ) : (
            <div>No agents found.</div>
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default Search;
