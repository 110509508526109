import React from 'react';
import PropTypes from 'prop-types';

const GSTDetails = ({ gstDetails }) => (
  <div>
    <div className="row">
      <div className="col-md-6">
        <p>GST No : {gstDetails.gstNo}</p>
      </div>
      <div className="col-md-6">
        <p>GST Type : {gstDetails.gstType}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-6">
        <img
          src={`https://navrang.mvd-tech.io/API/images/agent/${gstDetails.gstProof}`}
          alt="gstProof"
          className="img-fluid"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="col-md-6">
        <img
          src={`https://navrang.mvd-tech.io/API/images/agent/${gstDetails.addressProof}`}
          alt="addressProof"
          className="img-fluid"
          style={{ cursor: 'pointer' }}
        />
      </div>

    </div>

  </div>

  
  );

GSTDetails.propTypes = {
    gstDetails: PropTypes.shape({
        gstNo: PropTypes.string.isRequired,
        gstType: PropTypes.string.isRequired,
        gstProof: PropTypes.string.isRequired,
        addressProof: PropTypes.string.isRequired,
    }).isRequired,
};


export default GSTDetails;
