import React, {
 createContext, useState, useEffect, useMemo, 
} from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [userid, setUserId] = useState('');
  const [apiGlobal, setApiGlobal] = useState('https://navrang.mvd-tech.io/API/');

  const value = useMemo(() => ({ 
    isLoggedIn, 
    setIsLoggedIn, 
    username, 
    setUsername,
    apiGlobal,
    setApiGlobal,
    userid,
    setUserId,
  }), [isLoggedIn, username, apiGlobal, userid]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;

