import React, { 
  useState,
   useEffect, 
   useContext,
   useRef,
  } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import DatePicker from 'react-datepicker';
import makeAnimated from 'react-select/animated';
import renderSelectField from '@/shared/components/form/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
  Checkbox, 
  MenuItem, 
  ListItemText, 
   } from '@mui/material';
import {
    FormButtonToolbar,
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupLabel,
    FormHalfContainer,
  } from '@/shared/components/form/FormElements';
  import renderCheckBoxField from '@/shared/components/form/CheckBox';
  import Select from 'react-select';
  import { Button } from '@/shared/components/Button';
  import PropTypes from 'prop-types';
  import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import AddItems from './AddItems';
import { Mealcontext } from '../../../../context/MealContext';


const AddMeal = ({ onSubmit, theme }) => { 
  const { meals, setMeals } = useContext(Mealcontext);
 const [selectedImage, setSelectedImage] = useState(null);
 const [restaurantSelect, setRestaurantSelect] = useState({});
 const [fetchRestaurantName, setFetchRestaurantName] = useState([]);
 const [fetchLocationName, setFetchLocationName] = useState([]);
 const selectRef = useRef(null);

//  const [selectedRestaurants, setSelectedRestaurants] = React.useState([30]);

//  const handleChange = (event) => {
//   const values = event.target.value;
//   setSelectedRestaurants(values);
// };

  const fetchRestaurant = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=1');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setFetchRestaurantName(data.data.map(item => ({ value: item.restaurant_id, label: item.restaurant_name })));
        // console.log(JSON.stringify(fetchRestaurantName));
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };
  // console.log(meals);

  useEffect(() => {
    fetchRestaurant();
  }, []);

    // handle file
    const handleFileChange = (event, input) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        input.onChange(file);
      } else {
        setSelectedImage(null);
        input.onChange(null);
      }
    };

  //   const [meals, setMeals] = useState([]);
  //    const handleAddMeal = (values) => {
  //   const newMeal = {
  //     item_id: meals.length + 1, 
  //     name: values.Item, 
  //     description: values.desc, 
  //   };
  //   setMeals(prevMeals => [...prevMeals, newMeal]);
  // };

       
     
  const [isChecked, setIsChecked] = useState(false);
  const [rememberme, setRemember] = useState(false);

  // const handleCheckboxChange = () => {
  //   console.log('first');
  // };
  
  

  const onChangeRestaurantHandler = async (e) => {
    if (selectRef.current) {
      console.log(selectRef.current.select.setValue([]));
      selectRef.current.state.value = [{}];
    }
    setRestaurantSelect({
      value: e.value,
      lable: e.label,
    });
    



    if (e.value) {
      try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_load.php?type=2&id=${e.value}`);
        const response = await url.json();
        if (response.status && response.message === 'Success') {
          setFetchLocationName(response.data.map(item => ({ value: item.id, label: item.restaurant_name })));
        }
        } catch (error) {
        console.log(error);
      }
    } else {
      console.error('No restaurant Select');
    }
  };


 
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
          </CardTitleWrap>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Select Restaurant<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <Field
                      name="restaurantid"
                      style={{
                        backgroundColor: theme === 'Dark' ? '#232329' : 'white',
                        color: theme === 'Dark' ? 'white' : 'black',
                      }} 
                      render={({ input, meta }) => (
                        <Select
                          {...input} 
                          name="restaurantid"
                          className="react-select"
                          classNamePrefix="react-select"
                          options={fetchRestaurantName} 
                          value={fetchRestaurantName.find(option => option.value === input.value) || null} 
                          onChange={(selectedOption) => {
                            input.onChange(selectedOption ? selectedOption.value : null); 
                            onChangeRestaurantHandler(selectedOption); 
                          }}
                          placeholder="Select a restaurant"
                          // styles={{
                          //   control: provided => ({
                          //     ...provided,
                          //     backgroundColor: theme === 'Dark' ? '#232329' : 'white',
                          //     color: theme === 'Dark' ? 'white' : 'black',
                          //   }),
                          //   singleValue: provided => ({
                          //     ...provided,
                          //     color: theme === 'Dark' ? 'white' : 'black',
                          //   }),
                          // }}
                        />
                      )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Select Location<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <Field
                      name="locationsid"
                      style={{
                        backgroundColor: theme === 'Dark' ? '#232329' : 'white',
                        color: theme === 'Dark' ? 'white' : 'black',
                      }} 
                      render={({ input, meta }) => (
                        <Select
                          {...input} 
                          name="locationsid"
                          component={makeAnimated}
                          className="react-select"
                          classNamePrefix="react-select"
                          isMulti
                          options={fetchLocationName}
                          isClearable
                          placeholder="Select a Location"
                          ref={selectRef} 
                          
                        />
                        
                      )}
                    />
                  </FormGroup>


                  {/* <FormGroup>
                    <FormGroupLabel>Select Location</FormGroupLabel>
                    <Select
                      name="restaurantid"
                      className="react-select"
                      classNamePrefix="react-select"
                      options={fetchRestaurantName}
                      onChange={onChangeRestaurantHandler}
                      clearable={false}
                    />
                  </FormGroup> */}
                  {/* <FormGroup>
                    <FormGroupLabel>Select Restaurant<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="restaurantid"
                        component={renderSelectField}
                        type="text"
                        placeholder="Select Restaurant"
                        options={fetchRestaurantName}
                        onChange={onChangeRestaurantHandler}
                        clearable 
                        required
                          
                      />
                    </FormGroupField>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <InputLabel htmlFor="restaurant-select">Select Restaurants</InputLabel>
                    <Select
                      multiple
                      value={selectedRestaurants}
                      onChange={handleChange}
                      inputProps={{
                        name: 'restaurants',
                        id: 'restaurant-select',
                      }}
                      renderValue={selected => selected.join(', ')}
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: 1,
                        padding: '10px',
                        '&:hover': {
                          backgroundColor: '#f0f0f0',
                        },
                      }}
                    >
                      <MenuItem value={10}>
                        <Checkbox checked={selectedRestaurants.indexOf(10) > -1} />
                        <ListItemText primary="Restaurant Ten" />
                      </MenuItem>
                      <MenuItem value={20}>
                        <Checkbox checked={selectedRestaurants.indexOf(20) > -1} />
                        <ListItemText primary="Restaurant Twenty" />
                      </MenuItem>
                      <MenuItem value={30}>
                        <Checkbox checked={selectedRestaurants.indexOf(30) > -1} />
                        <ListItemText primary="Restaurant Thirty" />
                      </MenuItem>
                    </Select>
                  </FormGroup> */}
                  
                    
                  
                  <FormGroup>
                    <FormGroupLabel>Meal Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealName"
                        component="input"
                        type="text"
                        placeholder="Meal Name"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Price<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealPrice"
                        component="input"
                        type="number"
                        placeholder="Meal Price"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>FOC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="foc"
                        component="input"
                        type="text"
                        placeholder="Foc"
                        className="w-60"
                        required
                      />
                      {' '}on
                      <Field
                        name="focon"
                        component="input"
                        type="text"
                        placeholder="Foc on"
                        className="w-60"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  {/* <FormGroup>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {meals.map((meal, index) => (
                          <tr key={meal.id}>
                            <td>{index + 1}</td>
                            <td>{meal.name}</td>
                            <td>{meal.description}</td>
                            <td>
                              <Button 
                                type="button"
                                variant="outline-danger" 
                                size="sm"
                                className="mt-2"
                                onClick={() => setMeals(meals.filter(m => m.id !== meal.id))}
                              >
                                <IoIosRemoveCircle />
                              </Button>
                            </td>
                          </tr>
                           ))}

                      </tbody>
                      <tfoot>
                        <tr>
                          <th>
                            <FormGroupLabel>Item</FormGroupLabel>
                            <Field
                              name="Item"
                              component="input"
                              type="text"
                              placeholder="Item"
                        
                            />
                          </th>
                          <th>
                            <FormGroupLabel>Description</FormGroupLabel>
                            <Field
                              name="desc"
                              component="input"
                              type="text"
                              placeholder="description"
                            />
                          </th>
                          <th>
                            
                            <Button 
                              type="button"
                              variant="outline-success" 
                              rounded
                              size="sm"
                              style={{
                                marginTop: '45px',
                              }}
                              onClick={() => {
                                handleAddMeal({
                                  Item: document.querySelector('[name="Item"]').value,
                                  desc: document.querySelector('[name="desc"]').value,
                                });
                                document.querySelector('[name="Item"]').value = '';
                                document.querySelector('[name="desc"]').value = '';
                              }}
                              
                            >
                              <CiCirclePlus />
                            </Button>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </FormGroup> */}
                  <FormGroup>
                    <AddItems />
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                      />
                      <Field
                        name="snaks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"

                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal format <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Fit"
                        radioValue="1"
                      />
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Git"
                        radioValue="2"
                      />
                      <Field
                        name="format"
                        component={renderRadioButtonField}
                        label="Delivery"
                        radioValue="3"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Veg/Non-veg <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Veg"
                        radioValue="1"
                      />
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Non-veg"
                        radioValue="2"
                      />
                      <Field
                        name="veg"
                        component={renderRadioButtonField}
                        label="Veg/Non-veg"
                        radioValue="3"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Cuisines<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal type<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="mealType"
                        component="input"
                        type="text"
                        placeholder="Meal Type"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>

                <FormHalfContainer>
                  
                  <FormGroup>
                    <FormGroupLabel>Description</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="discription"
                        component="textarea"
                        type="text"
                        placeholder="Description"
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field 
                        name="logo"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                            required
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                  
                  {/* <FormGroup>
                    <FormGroupLabel>Start Date<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="startDate"
                        component={renderDatePickerField}
                        type="date"
                        placeholder="Start Date"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>End Date<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="endDate"
                        component={renderDatePickerField}
                        type="date"
                        placeholder="End Date"
                        required
                        
                      />
                    </FormGroupField>
                  </FormGroup> */}

                  <FormGroup>
                    <FormGroupLabel>
                      Start Date<span style={{ color: 'red' }}>*</span>
                    </FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="startDate"
                        render={({ input }) => (
                          <DatePicker 
                            {...input}
                            selected={input.value || null} 
                            onChange={date => input.onChange(date)} 
                            dateFormat="dd-MM-yyyy"
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>
                      End Date<span style={{ color: 'red' }}>*</span>
                    </FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="endDate"
                        render={({ input }) => (
                          <DatePicker 
                            {...input}
                            selected={input.value || null} // Ensure value is displayed
                            onChange={date => input.onChange(date)} // Update value on change
                            dateFormat="dd-MM-yyyy" // Optional: Specify desired date format
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>Markup Percentage<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        component="input"
                        type="number"
                        placeholder="Markup Percentage"
                        required
                        
                      />
                    </FormGroupField>
                  </FormGroup>
                  
                </FormHalfContainer>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                  {/* <Button variant="secondary" type="button">
                      Reset
                    </Button> */}
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
    
  );
};
AddMeal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
  };
export default AddMeal;
