import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import PasswordField from '@/shared/components/form/Password';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const ChangedPassword = ({ formValues, onSubmit }) => {
  const { t } = useTranslation('common');

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          
          <Form onSubmit={onSubmit} initialValues={formValues}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Password</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="passwordSet"
                        component={PasswordField}
                        className="col-md-12"
                        placeholder="Enter your New password"
                      />
                      
                    </FormGroupField>
                    <Button
                      variant="primary" 
                      classname="mt-4" 
                      size="sm" 
                      type="submit"
                      style={{
                        marginTop: '12px',
                      }}
                    >
                      Change Password
                    </Button>
                  </FormGroup>
                 
                </FormHalfContainer>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

ChangedPassword.propTypes = {
    formValues: PropTypes.shape({
      currentPassword: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
  

export default ChangedPassword;
