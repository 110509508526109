import React from 'react';
import { Container, Row } from 'react-bootstrap';
import DefaultTabsBorderedBottom from './DefaultTabsBorderedBottom';

const Agent = () => (
  <Container>
    <Row>
      <DefaultTabsBorderedBottom />
    </Row>
  </Container>
);

export default Agent;
