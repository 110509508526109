import React, { useContext, useEffect, useState } from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form, 
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { AgentContext } from '../../../context/AgentContext';
import { ThemeContext } from '../../../context/ThemeContext';
import RejectedRequestCard from './RejectedRequestCard';

const RejectedRequest = () => {
  const [rejectedAgent, setRejectedAgent] = useState([]);
  const [filteragent, setFilterAgent] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { setRefresh, refresh } = useContext(AgentContext);
  const { currentTheme } = useContext(ThemeContext);



  const fetchData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/agent_rejectedRequest.php');
      const data = await response.json();
  
      console.log('Data:', data);
  
      if (data.status && data.message === 'Success') {
        console.log('Data fetched:', data.data);
        setRejectedAgent(data.data);
        setFilterAgent(data.data);
      } else {
        console.log('No data fetched');
        setFilterAgent([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load data');
    }
  };
  


  useEffect(() => {
    console.log('Refreshing data...');
    fetchData();
  }, [refresh]);
  
  

  if (rejectedAgent.length <= 0) {
    return (
      <div>
        No Agent Found
      </div>
    );
  }
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = rejectedAgent.filter(agentdata => agentdata.name.toLowerCase().includes(query.toLowerCase())
        || agentdata.city.toLowerCase().includes(query.toLowerCase())
        || agentdata.state.toLowerCase().includes(query.toLowerCase())
        || agentdata.country.toLowerCase().includes(query.toLowerCase()));
      setFilterAgent(filteredData);
    } else {
      setFilterAgent(rejectedAgent);
    }
  };

  const refreshHandler = () => {
    setRefresh(prev => !prev);
    toast.success('Refresh....');
  };

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>
      <Row>
        <div className="col-md-4 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Name, City, State, or Country"
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1"
            style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
          />
        </div>
      </Row>
      <Row>
        <Container>
          {filteragent.length > 0 ? (
            filteragent.map(agentdata => (
              <RejectedRequestCard
                key={agentdata.id}
                id={agentdata.id}
                name={agentdata.name}
                address1={agentdata.address1}
                address2={agentdata.address2}
                area={agentdata.area}
                city={agentdata.city}
                state={agentdata.state}
                country={agentdata.country}
                remark={agentdata.reject_reason}
                username={agentdata.user_name}
                useremail={agentdata.user_email}
                userphone={agentdata.user_phone}
                setRefresh={setRefresh}
              />
            ))
          ) : (
            <div>No agents found.</div>
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default RejectedRequest;
