import React, { useState, useEffect, useContext } from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form,
 } from 'react-bootstrap';
import { Button, ButtonGroup } from '@/shared/components/Button';
import { FaFilter } from 'react-icons/fa';
import RestaurantCard from './RestaurantCard';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import { ThemeContext } from '../../../../context/ThemeContext';


const Search = () => {
  const { refresh } = useContext(RestaurantContext);
  const { currentTheme } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [restaurantdata, setRestaurantData] = useState([]);
  const [filteredRestaurantData, setFilteredRestaurantData] = useState([]);

  const fetchMealsData = async () => {
    try {
      console.log('search');
      const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_data.php');
      const RestaurantResponse = await response.json();
      if (RestaurantResponse && Array.isArray(RestaurantResponse.data)) {
       //  console.log('Loaded successfully');
        setRestaurantData(RestaurantResponse.data);
        setFilteredRestaurantData(RestaurantResponse.data); 
        console.log(RestaurantResponse.data);
      } else {
        console.log('No data found');
        setRestaurantData([]);
        setFilteredRestaurantData([]);
      }
    } catch (error) {
      console.error('Error loading meals', error);
    }
  };

  useEffect(() => {
    fetchMealsData();
  }, [refresh]); 

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = restaurantdata.filter(meal => meal.name.toLowerCase().includes(query.toLowerCase()));
      setFilteredRestaurantData(filteredData); 
    } else {
        setFilteredRestaurantData(restaurantdata); 
    }
  };

  // // Refresh handler
  // const refreshHandler = () => {
  //   fetchMealsData();
  // };

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>

      <Row>
        <div className="col-md-4 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Restaurant Name..."
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1 rounded"
            style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
          />
          <ButtonGroup icons dir="ltr" className="mt-2">
            {/* <Button
              variant="outline-secondary"
              className="ms-3"
              title="Refresh"
              size="sm"
              onClick={refreshHandler}
            >
              <span className="lnr lnr-sync" />
            </Button> */}
          </ButtonGroup>

          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            <Button 
              variant="outline-info"
              title="Filter" 
            >
              <FaFilter />
            </Button>
          </ButtonGroup>
        </div>
      </Row>

      <Row>
        <div>
          {filteredRestaurantData.length === 0 ? (
            <p>No Restaurant found</p>
          ) : (
            filteredRestaurantData.map(restaurant => (
              <div key={restaurant.id}>
                <RestaurantCard
                  id={restaurant.id}
                  name={restaurant.name}
                  format={restaurant.meal_format}
                  mealOffered={restaurant.meal_offered}
                  cuisines={restaurant.cuisines}
                  capacity={restaurant.capping_capacity}
                  location={restaurant.location}
                  clipcapacity={restaurant.setting_capacity}
                  image={restaurant.photo}
                  count={restaurant.location_count}
                  theme={currentTheme}
                />
              </div>
            ))
          )}
        </div>
      </Row>
    </Container>
  );
};

export default Search;
