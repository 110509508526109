import React, { useState, useContext, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import renderTimePickerField from '@/shared/components/form/date-pickers/TimePicker';
import { useSelector } from 'react-redux';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { renderMaskedField } from '@/shared/components/form/FormField';
import TimePicker from 'rc-time-picker';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import GetLocation from './FormCard/GetLocation';

const AddRestaurant = ({ onSubmit, restaurantname }) => {
  const timeFormat = 'HH:mm';
  const themeName = useSelector(state => state.theme.className);
  const [selectedImage, setSelectedImage] = useState(null);
  const { restaurantInfo, setRestaurantInfo } = useContext(RestaurantContext);
  const [time, setTime] = useState(null);

  // Handle file change
  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  const CurrentLocationHandler = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Geolocation fetched:', position.coords);
          setRestaurantInfo(prevInfo => ({
            ...prevInfo,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        },
        (error) => {
          console.error('Error getting location:', error.message);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  };


  // Update map link when latitude and longitude are set
  useEffect(() => {
    if (restaurantInfo.latitude && restaurantInfo.longitude) {
      const mapDirect = `https://www.google.com/maps?q=${restaurantInfo.latitude},${restaurantInfo.longitude}`;
      setRestaurantInfo(prevInfo => ({
        ...prevInfo,
        maplink: mapDirect,
      }));
    }
  }, [restaurantInfo.latitude, restaurantInfo.longitude]);

  // // Handle input change for other fields
  // const handleChange = field => (event) => {
  //   const { value } = event.target;
  //   setRestaurantInfo(prevState => ({
  //     ...prevState,
  //     [field]: value,
  //   }));
  // };

  // Parse the Google Map link and extract coordinates
  const parseLinkHandler = () => {
    const extractLink = restaurantInfo.maplink;
    console.log('Parsing link:', extractLink);
    try {
      let coordinates;
      
      if (extractLink.includes('?q=')) {
        coordinates = extractLink.split('?q=')[1].split(',');
      } else if (extractLink.includes('@')) {
        coordinates = extractLink.split('@')[1].split(',').slice(0, 2);
      } else {
        throw new Error('Invalid Google Maps link format');
      }
      
      console.log('Extracted coordinates:', coordinates);
      setRestaurantInfo(prevInfo => ({
        ...prevInfo,
        latitude: parseFloat(coordinates[0]),
        longitude: parseFloat(coordinates[1]),
      }));
    } catch (error) {
      console.error('Error parsing Google Maps link:', error);
      alert('Invalid Google Maps link format');
    }
  };
  console.log(restaurantInfo.latitude);
  console.log(restaurantInfo);

  // const handleCheckboxChange = fieldName => (event) => {
  //   const { checked } = event.target;
  //    setRestaurantInfo({
  //     mealFormat: {
  //       [fieldName]: checked, 
  //     },
  //    });
  // };
  

  // const handleClick = () => {
  //    console.log('hello');
  // };
  
  
  

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
          </CardTitleWrap>
          <Form 
            onSubmit={onSubmit} 
          >
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Restaurant Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="restaurantName"
                        component="input"
                        type="text"
                        placeholder="Restaurant Name"
                        initialValue={restaurantname}
                        disabled

                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 1<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address1"
                        component="input"
                        type="text"
                        placeholder="Address Line 1"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component={renderMaskedField}
                        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        type="text"
                        placeholder="Pincode"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Discription</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="discription"
                        component="textarea"
                        placeholder="Description"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Setting Capacity<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="settingCap"
                        component="input"
                        type="number"
                        placeholder="Setting Capacity"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal Offered<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="breakfast"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Breakfast"
                      />
                      <Field
                        name="snacks"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Snacks"
                      />
                      <Field
                        name="lunch"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Lunch"
                      />
                      <Field
                        name="dinner"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Dinner"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Meal format<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="fit"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Fit"
                      />
                      <Field
                        name="git"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Git"

                      />
                      <Field
                        className="form-check-input"
                        name="delivery"
                        type="checkbox"
                        component={renderCheckBoxField}
                        label="Delivery"

                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Capping Capacity *</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="capCapacity"
                        component="input"
                        type="number"
                        placeholder="Capping Capacity"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  
                </FormHalfContainer>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Location <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="location"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>UIN<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uin"
                        component="input"
                        type="number"
                        placeholder="UIN"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Line 2</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address2"
                        component="input"
                        type="text"
                        placeholder="Address Line 2"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="city"
                        component="input"
                        type="text"
                        placeholder="City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="country"
                        component="input"
                        type="text"
                        placeholder="Country"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroupLabel>Location Details<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                  <br />
                  <GetLocation 
                    isEdit={false}
                  />
                  <FormGroup>
                    <FormGroupLabel>Cuisines<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="cuisines"
                        component="input"
                        type="text"
                        placeholder="Cuisines"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Tax identification #<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxID"
                        component="input"
                        type="text"
                        placeholder="Tax identification"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  {/* <FormGroup>
                    <FormGroupField>
                      <FormGroupLabel className="mt-2 mx-2">From</FormGroupLabel>
                      
                      <TimePicker
                        defaultValue={dayjs('12:08:00', timeFormat)} 
                        format={timeFormat} 
                        getPopupContainer={trigger => trigger.parentNode}
                      />
                      
                    </FormGroupField>
                  </FormGroup> */}

                  <FormGroup>
                    <FormGroupLabel>Timing <span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <FormGroupLabel className="mt-2 mx-2">From</FormGroupLabel>
                      <Field
                        name="fromTime"
                        render={({ input }) => (
                          <TimePicker
                            {...input}
                            name="fromTime"
                            showSecond={false}
                            format="HH:mm"
                          />
                        )}
                        theme={themeName}
                      />
                      <FormGroupLabel className="mt-2 mx-2">to</FormGroupLabel>
                      <Field
                        name="toTime"
                        render={({ input }) => (
                          <TimePicker
                            {...input}
                            name="toTime"
                            showSecond={false}
                            format="HH:mm"
                          />
                        )}
                        theme={themeName}
                      />
                      
                      
                    </FormGroupField>

                    {/* <FormGroupField style={{ display: 'flex' }}>
                      <FormGroupLabel className="mt-2 mx-2">From</FormGroupLabel>
                      <Field
                        name="Starttime"
                        component={renderTimePickerField}
                        theme={themeName}
                        className="w-40"
                        initialValue="2:20pm"
                      />
                      <FormGroupLabel className="mt-2 mx-2">To</FormGroupLabel>
                      <Field
                        name="Endtime"
                        component={renderTimePickerField}
                        theme={themeName}
                        className="w-40"
                      />
                    </FormGroupField> */}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel style={{ color: 'red' }}>Markup Percentage</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        type="number"
                        component="input"
                        placeholder="Markup Percentage"
                      />
                    </FormGroupField>
                  </FormGroup>

                  
                </FormHalfContainer>
                <FormButtonToolbar>
                    
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                  {/* <Button variant="danger" type="reset">
                      Reset
                    </Button> */}
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

AddRestaurant.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  restaurantname: PropTypes.string.isRequired,
};

export default AddRestaurant;
