import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { renderMaskedField } from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';

const AddAgent = ({ onSubmit }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAdressProof, setSelectedAdressProof] = useState(null);
  const [selectedGstProof, setSelectedGSTProof] = useState(null);

  const handleFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedImage(null);
      input.onChange(null);
    }
  };

  const handAddressFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedAdressProof(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedAdressProof(null);
      input.onChange(null);
    }
  };

  const handGstFileChange = (event, input) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedGSTProof(reader.result);
      };
      reader.readAsDataURL(file);
      input.onChange(file);
    } else {
      setSelectedGSTProof(null);
      input.onChange(null);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
          <Form onSubmit={onSubmit} horizantal initialValues={{ gender: 'male' }}>
            {({ handleSubmit, form }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Agent Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="agentName"
                        component="input"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Email Address<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="email"
                        component="input"
                        type="text"
                        placeholder="Email Address"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Area<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="area"
                        component="input"
                        type="text"
                        placeholder="Area"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>State<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="state"
                        component="input"
                        type="text"
                        placeholder="State"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Pincode<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="pincode"
                        component={renderMaskedField}
                        mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        type="text"
                        placeholder="Pincode"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Logo<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="file"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handleFileChange(e, input)}
                            required
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedImage && (
                    <FormGroup>
                      <FormGroupLabel>Selected Logo</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedImage}
                          alt="Selected Logo"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormGroupLabel>GST No</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gst_no"
                        component="input"
                        type="text"
                        placeholder="GST No"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>GST Type</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gst_type"
                        component="input"
                        type="text"
                        placeholder="GST Type"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Address Proof</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="address_proof"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handAddressFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedAdressProof && (
                    <FormGroup>
                      <FormGroupLabel>Selected Address Proof</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedAdressProof}
                          alt="Selected Address Proof"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormGroupLabel>GST Proof</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="gst_proof"
                        render={({ input }) => (
                          <input
                            type="file"
                            onChange={e => handGstFileChange(e, input)}
                          />
                        )}
                      />
                    </FormGroupField>
                  </FormGroup>
                  {selectedGstProof && (
                    <FormGroup>
                      <FormGroupLabel>Selected GST Proof</FormGroupLabel>
                      <FormGroupField>
                        <img
                          src={selectedGstProof}
                          alt="Selected Address Proof"
                          style={{ maxWidth: '200px', maxHeight: '200px' }}
                        />
                      </FormGroupField>
                    </FormGroup>
                  )}
                </FormHalfContainer>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>UAN<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="uan"
                        component="input"
                        type="number"
                        placeholder="UAN"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroupLabel>
                          Address Line 1<span style={{ color: 'red' }}>*</span>
                        </FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="addressline1"
                            component="input"
                            type="text"
                            placeholder="Address Line 1"
                            required
                          />
                        </FormGroupField>
                      </div>
                      <div className="col-md-6">
                        <FormGroupLabel>
                          Address Line 2
                        </FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="addressline2"
                            component="input"
                            type="text"
                            placeholder="Address Line 2"
                          />
                        </FormGroupField>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroupLabel>
                          City<span style={{ color: 'red' }}>*</span>
                        </FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="city"
                            component="input"
                            type="text"
                            placeholder="City"
                            required
                          />
                        </FormGroupField>
                      </div>
                      <div className="col-md-6">
                        <FormGroupLabel>
                          Select your country<span style={{ color: 'red' }}>*</span>
                        </FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="country"
                            component="input"
                            type="text"
                            placeholder="Country"
                            required
                          />
                        </FormGroupField>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>Location<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="location"
                        component="input"
                        type="text"
                        placeholder="Location"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Default Markup<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="markup"
                        component="input"
                        type="number"
                        placeholder="Default Markup"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX Identification<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxId"
                        component="input"
                        type="text"
                        placeholder="TAX Identification"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>TAX City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="taxCity"
                        component="input"
                        type="text"
                        placeholder="Tax City"
                        required
                      />
                    </FormGroupField>
                  </FormGroup>
                  
                </FormHalfContainer>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button variant="secondary" type="button" onClick={form.reset}>
                    Reset
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

AddAgent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddAgent;
