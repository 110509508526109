import React from 'react';
import PropTypes from 'prop-types';

const PrimaryDetails = ({ contactAgent }) => (
  <div>
    <div className="row">
      <div className="col-md-4">
        <p>User Name:{contactAgent.name}</p>
      </div>
      <div className="col-md-4">
        <p>User Email:{contactAgent.email}</p>
      </div>
      <div className="col-md-4">
        <p>User Phone:{contactAgent.phone}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-4">
        <p>Account name :{contactAgent.accName}</p>
      </div>
      <div className="col-md-4">
        <p>Account Number :{contactAgent.accNumber}</p>
      </div>
      <div className="col-md-4">
        <p>Branch :{contactAgent.accBranch}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-4">
        <p>Ifsc :{contactAgent.accIfsc}</p>
      </div>
      <div className="col-md-4">
        <p>City:{contactAgent.accCity}</p>
      </div>
      <div className="col-md-4">
        <p>Country:{contactAgent.accCountry}</p>
      </div>
    </div>
    <hr />
    <div className="row">
      <div className="col-md-4">
        <p>Bank:{contactAgent.accBank}</p>
      </div>
      <div className="col-md-4">
        <p>Pincode:{contactAgent.uin}</p>
      </div>
     
    </div>
    <hr />
  </div>
  );

PrimaryDetails.propTypes = {
    contactAgent: PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        uin: PropTypes.string.isRequired,
        accName: PropTypes.string.isRequired,
        accCountry: PropTypes.string.isRequired,
        accCity: PropTypes.string.isRequired,
        accNumber: PropTypes.string.isRequired,
        accBranch: PropTypes.string.isRequired,
        accIfsc: PropTypes.string.isRequired,
        accBank: PropTypes.string.isRequired,

    }).isRequired,
};


export default PrimaryDetails;
