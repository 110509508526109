import React from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { BorderedBottomTabs } from '@/shared/components/Tabs';
import Tabs from './Tabs';

const DefaultTabsBorderedBottom = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle />
        </CardTitleWrap>
        <BorderedBottomTabs>
          <Tabs />
        </BorderedBottomTabs>
      </CardBody>
    </Card>
  </Col>
  );

export default DefaultTabsBorderedBottom;
