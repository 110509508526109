import React, { useState, useContext, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import Select from 'react-select';
import { Button } from '@/shared/components/Button';
import { Table } from '@/shared/components/TableElements';
import toast from 'react-hot-toast';
import { EmojiSmileUpsideDown } from 'react-bootstrap-icons';
import { AuthContext } from '../../../../context/AuthContext';
import { PermissionContext } from '../../../../context/PermissionContext';



const Manager = () => {
  const { userid, apiGlobal } = useContext(AuthContext);
  const { setPermissionUpdate } = useContext(PermissionContext);
  const [userName, setUserName] = useState([]);
  const [userId, setUserId] = useState('');
  const [modules, setModules] = useState(['agent', 'restaurant', 'meal', 'booking', 'accounts']);
  const [permissions, setPermissions] = useState({});

  const PermissionsAccess = async () => {
    const response = await fetch(`${apiGlobal}/admin_permission?id=${userid}`);
    const data = await response.json();
    console.log(data);
  };
  
  useEffect(() => {
    PermissionsAccess();
  }, []);


  useEffect(() => {
    const newPermissions = {};

    modules.forEach((module) => {
      newPermissions[module] = {
        view: false,
        create: false,
        edit: false,
        delete: false,
      };
    });
    setPermissions(newPermissions);
  }, [modules]);

  const permissionColors = {
    view: '#007bff',
    create: '#28a745',
    edit: '#ffc107',
    delete: '#dc3545',
  };

  const fetchUser = async () => {
    try {
      const response = await fetch(`${apiGlobal}/user_admin_data.php`);
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setUserName(data.data.map(user => ({ label: user.name, value: user.id })));
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleCheckboxChange = (module, permission) => {
    setPermissions(prev => ({
      ...prev,
      [module]: {
        ...prev[module],
        [permission]: !prev[module][permission],
      },
    }));
  };

  useEffect(() => {
    console.log('Permissions:', permissions);
  }, [permissions]);

  const fetchUserPermissions = async (e) => {
    setUserId(e.value);
    let fetchedPermissions = [];
    const newPermissions = {};
  
    try {
      const url = `${apiGlobal}/admin_get_role.php?user_id=${e.value}`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
  
      if (data.status && data.message === 'Success') {
        fetchedPermissions = data.permissions || [];
  
        // Initialize permissions for each module
        modules.forEach((module) => {
          newPermissions[module] = {
            view: fetchedPermissions.includes(`view_${module}`),
            create: fetchedPermissions.includes(`create_${module}`),
            edit: fetchedPermissions.includes(`edit_${module}`),
            delete: fetchedPermissions.includes(`delete_${module}`),
          };
        });
      } else {
        toast.error(data.message || 'Failed to fetch permissions');
        // Default permissions when fetching fails or no success message
        modules.forEach((module) => {
          newPermissions[module] = {
            view: false,
            create: false,
            edit: false,
            delete: false,
          };
        });
      }
  
      setPermissions(newPermissions);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      // Set default permissions in case of error
      modules.forEach((module) => {
        newPermissions[module] = {
          view: false,
          create: false,
          edit: false,
          delete: false,
        };
      });
      setPermissions(newPermissions);
    }
  };
  
  
  // const fetchUserPermissions = async (e) => {
  //   setUserId(e.value);
  //   try {
  //     const url = `${apiGlobal}/admin_get_role.php?user_id=${e.value}`;
  //     const response = await fetch(url);
  //     const data = await response.json();
  //     console.log(data);
  //     if (data.status && data.message === 'Success') {
  //       const fetchedPermissions = data.permissions;
  //       // console.log('Fetched permissions:', fetchedPermissions);
  //       const newPermissions = {};
  //       if (fetchedPermissions) {
  //         modules.forEach((module) => {
  //           newPermissions[module] = {
  //             view: fetchedPermissions.includes(`view_${module}`) || false,
  //             create: fetchedPermissions.includes(`create_${module}`) || false,
  //             edit: fetchedPermissions.includes(`edit_${module}`) || false,
  //             delete: fetchedPermissions.includes(`delete_${module}`) || false,
  //           };
  //         });
  //       } else {
  //         modules.forEach((module) => {
  //           newPermissions[module] = {
  //             view: false,
  //             create: false,
  //             edit: false,
  //             delete: false,
  //           };
  //         });
  //       }
        

  //       setPermissions(newPermissions);
  //     } else {
  //       toast.error(data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  

  const saveUserPermissions = async () => {
    console.log(userId);
    try {
      if (!userId) {
        console.error('User not selected');
        return;
      }
  
      const formData = new FormData();
      formData.append('user_id', userId);
  
      const permissionsObject = {
        view: {},
        create: {},
        edit: {},
        delete: {},
      };
  
      // Populate the permissionsObject with values
      modules.forEach((module) => {
        Object.keys(permissions[module]).forEach((permission) => {
          permissionsObject[permission][module] = permissions[module][permission];
        });
      });
  
      // Append permissions as a JSON string
      formData.append('permissions', JSON.stringify(permissionsObject));
  
    //   // Print FormData for debugging purposes
    Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(`${key}: ${value}`);
      });
      
  
      // Send the form data to the backend
      const response = await fetch(`${apiGlobal}/admin_role.php`, {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
  
      if (data.status && data.message === 'Permissions updated successfully.') {
        toast.success('Permissions updated successfully!');
        setPermissionUpdate(prev => !prev);
      } else {
        toast.error(data.message || 'An error occurred while updating permissions.');
      }
    } catch (error) {
      console.error('Error saving user permissions:', error);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardSubhead>
              All Users <span className="red-text">BitWize-Admin</span>
            </CardSubhead>
            <Select
              name="userid"
              className="react-select w-25 mt-4"
              classNamePrefix="react-select"
              options={userName}
              placeholder="Select a User"
              onChange={e => fetchUserPermissions(e)}
            />
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Permission Name</th>
                <th style={{ color: '#007bff' }}>View</th>
                <th style={{ color: '#28a745' }}>Create</th>
                <th style={{ color: '#ffc107' }}>Edit</th>
                <th style={{ color: '#dc3545' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(permissions).map(module => (
                <tr key={module}>
                  <th>{module.charAt(0).toUpperCase() + module.slice(1)}</th>
                  {Object.keys(permissions[module]).map(permission => (
                    <td key={permission}>
                      <input
                        type="checkbox"
                        checked={permissions[module][permission]}
                        onChange={() => handleCheckboxChange(module, permission)}
                        style={{
                          width: '20px',
                          height: '20px',
                          accentColor: permissionColors[permission] || '#6c757d',
                          cursor: 'pointer',
                        }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            style={{ float: 'right', marginTop: '10px' }}
            variant="success"
            size="sm"
            onClick={() => saveUserPermissions()}
          >
            Update Permission
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Manager;
