import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { LoadScript } from '@react-google-maps/api';
import initAuth0 from '@/shared/components/account/auth/withAuth0';
import TimepickerStyles from '@/shared/components/form/date-pickers/timepickerStyles';
import Loading from '@/shared/components/Loading';
import WalletConnectProvider from '@/shared/components/account/auth/WalletConnect';
import { Toaster } from 'react-hot-toast';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import GlobalStyles from './globalStyles';
import RechartStyles from './rechartStyles';
import NotificationStyles from './notificationStyles';
import CalendarStyles from './calendarStyles';
import AppContextProvider from '../../context';
import { AuthContext } from '../../context/AuthContext';
import { PermissionContext } from '../../context/PermissionContext';

i18n.init(i18nextConfig);

const ThemeComponent = ({
  children,
}) => {
  const {
    mode, direction, shadow, border,
  } = useSelector(state => ({
    mode: state.theme.className,
    direction: state.rtl.direction,
    shadow: state.shadow.className,
    border: state.border.className,
  }));

  const theme = createTheme({
    palette: {
      type: mode,
    },
  });

  const { apiGlobal, userid } = useContext(AuthContext);
  const { setPermissionsAccess, permissionUpdate } = useContext(PermissionContext);

  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${apiGlobal}/admin_permission.php?id=${userid}`);
      const data = await response.json();

      console.log('Fetched Permissions:', data);
      if (data.status && data.message === 'Success') {
        const permissions = data.data[0].permission;
        setPermissionsAccess(permissions);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        console.log('fetch permisssion function called');
      } else {
        setPermissionsAccess([]);
        localStorage.removeItem('permissions');
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setPermissionsAccess([]);
      localStorage.removeItem('permissions');
    }
  };

  useEffect(() => {
    const storedPermissions = localStorage.getItem('permissions');
   
     if (userid) {
      console.log('No stored permissions, fetching from API');
      fetchPermissions();
     } else if (storedPermissions) {
      console.log('Loaded permissions from localStorage');
      setPermissionsAccess(JSON.parse(storedPermissions));
     }

    // if (storedPermissions) {
    //   console.log('Loaded permissions from localStorage');
    //   setPermissionsAccess(JSON.parse(storedPermissions));
    // } else if (userid) {
    //   console.log('No stored permissions, fetching from API');
    //   fetchPermissions();
    // }
  }, [userid, permissionUpdate]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider
        theme={{
          mode,
          direction,
          shadow,
          border,
        }}
      >
        <GlobalStyles />
        <NotificationStyles />
        <RechartStyles />
        <TimepickerStyles />
        <CalendarStyles />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ConnectedThemeComponent = ThemeComponent;

const App = () => {
  useEffect(() => {
    window.addEventListener('load', initAuth0);
  }, []);
   
  
// basename="/bitVise"
  return (
    <AppContextProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ConnectedThemeComponent>
            <LoadScript
              loadingElement={<Loading loading />}
              googleMapsApiKey=""
            >
              <ScrollToTop>
                <Router />
                <Toaster position="top-right" reverseOrder={false} />
              </ScrollToTop>
            </LoadScript>
          </ConnectedThemeComponent>
        </BrowserRouter>
      </Provider>
    </AppContextProvider>
    
  );
};

export default App;
