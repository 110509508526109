import React, { useState, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import toast from 'react-hot-toast';
import { CgDetailsMore, CgNotes } from 'react-icons/cg';
import { IoMdTime } from 'react-icons/io';
import { MdOutlineLunchDining, MdRestaurantMenu } from 'react-icons/md';
import { FaPeopleLine, FaMapLocationDot } from 'react-icons/fa6';
import { Button } from '@/shared/components/Button';
import MealCardForDetails from './MealCardForDetails';
import { Mealcontext } from '../../../../context/MealContext';
import LocationCard from './LocationCard';
import ImageGallery from './ImageGallery';

const MealDetails = () => {
  const [mealData, setMealData] = useState(null);
  const [similarMeals, setSimilarMeals] = useState([]);
  const [imagesphoto, setImagesphoto] = useState([]);
  const { 
    resaurantDetailsId,
    refresh, 
    setclick,
    setActiveKey,
    setIsDetailsClick,
  } = useContext(Mealcontext);

  const imgPath = 'https://navrang.mvd-tech.io/API/images/meal/attachment/';

  // Fetch meal data
  useEffect(() => {
    const fetchMeal = async () => {
      if (!resaurantDetailsId) {
        toast.error('Restaurant details ID not found.');
        return;
      }

      try {
        const url = `https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${resaurantDetailsId}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data.status && data.message === 'Success') {
          setMealData(data.data[0]);
        } else {
          toast.error('Failed to fetch meal data.');
        }
      } catch (e) {
        console.error('Error fetching meal data:', e.message);
      }
    };

    fetchMeal();
  }, [resaurantDetailsId, refresh]);

  // Fetch similar meals
  useEffect(() => {
    if (!mealData?.restaurant_id) return;

    const fetchSimilarMeals = async () => {
      try {
        const response = await fetch(
          `https://navrang.mvd-tech.io/API/
           meal_restaurant_fetch.php?restaurant_id=${mealData.restaurant_id}&id=${resaurantDetailsId}`,
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setSimilarMeals(data.data || []);
      } catch (e) {
        console.error('Error fetching similar meals:', e.message);
      }
    };

    fetchSimilarMeals();
  }, [mealData, resaurantDetailsId]);

  // Parse attachment images
  useEffect(() => {
    if (mealData?.attachment) {
      try {
        const images = JSON.parse(mealData.attachment);
        setImagesphoto(images);
      } catch (error) {
        console.error('Failed to parse images:', error);
      }
    }
  }, [mealData]);

  const backHandler = () => {
    setActiveKey('1');
    setclick('searchSelect');
    setIsDetailsClick(false);
  };

  const mealMapping = {
    1: 'Breakfast',
    2: 'Snacks',
    3: 'Lunch',
    4: 'Dinner',
  };

  if (!mealData) {
    return <div className="text-center my-4">Loading...</div>;
  }

  return (
    <Container>
      <div style={{ float: 'right' }}>
        <Button variant="primary" onClick={backHandler}>Back</Button>
      </div>

      <Row>
        <Col xs={12} md={12}>
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-12 col-md-4">
                  <h1>{mealData.name}</h1>
                </div>
                <div className="col-12 col-md-4">
                  <div className="price d-flex">
                    <div className="card-body price d-flex align-items-center">
                      <h2 className="card-text me-2" style={{ color: 'red' }}>
                        {mealData.price} <h3 className="d-inline" style={{ fontSize: '0.75em' }}>AED</h3>
                      </h2>
                      <h2 className="card-text me-2" style={{ color: 'blue' }}>
                        {mealData.final_price} <h3 className="d-inline" style={{ fontSize: '0.75em' }}>AED</h3>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <h5><CgDetailsMore /> {mealData.description}</h5>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-md-4">
                  <p><IoMdTime />: {mealData.meal_offered
                    ?.split(',')
                    .map(mealId => mealMapping[mealId.trim()] || 'Unknown Meal')
                    .join(', ') || 'N/A'}
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <p><MdOutlineLunchDining />: {mealData.cuisines}</p>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-md-4">
                  <p><MdRestaurantMenu />: {mealData.meal_type}</p>
                </div>
                <div className="col-12 col-md-4">
                  <p><FaPeopleLine />: {mealData.capacity}</p>
                </div>
                <div className="col-12 col-md-4">
                  <p><FaMapLocationDot />: {mealData.locations?.length > 0 
                    ? mealData.locations.map(location => location.location_name?.trim()).join(', ') 
                    : 'No locations available'}
                  </p>
                </div>
              </div>

              <div className="row mt-2">
                <LocationCard />
              </div>

              <div className="mealitems mt-2">
                <h5><CgNotes /> Meal Items</h5>
                <div>{JSON.parse(mealData.items || '[]').map(item => item.name).join(', ')}</div>
              </div>

              <div className="row mt-4">
                <h2>Restaurant - {mealData.restaurant_name}</h2>
              </div>

              <div className="row mt-2">
                <p><CgDetailsMore /> {mealData.restaurant_description}</p>
              </div>

              <div className="row mt-2">
                {imagesphoto.length > 0 ? <ImageGallery parsedimages={imagesphoto} /> : <p>No images available</p>}
              </div>



              {similarMeals.length > 0 ? (
                <div className="row mt-4">
                  <h2>Similar Meals</h2>
                  <div className="similarMeals">
                    {similarMeals.map(meal => (
                      <MealCardForDetails
                        key={meal.id}
                        id={meal.id}
                        mealname={meal.name}
                        restaurantid={meal.restaurant_id}
                        description={meal.description}
                        type={meal.meal_type}
                        price={meal.price}
                        finalprice={meal.final_price}
                        format={meal.meal_format}
                        image={meal.photo}
                        isVeg={meal.veg_non_veg}
                        mealOffereds={meal.meal_offered}
                        cuisine={meal.cuisines}
                        capacity={meal.capacity}
                        resName={meal.restaurant_name}
                      />
                    ))}
                  </div>
                </div>
              ) : <h6> No Similar Meal Found!</h6>}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MealDetails;
