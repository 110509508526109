import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import { renderMaskedField } from '@/shared/components/form/FormField';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

const AddContactInfo = ({ formValues, onSubmit }) => {
     const [showConfirmAccountField, setShowConfirmAccountField] = useState(false);

  return (
    <Col md={12} lg={12}>
      <Form onSubmit={onSubmit} initialValues={formValues}>
        {({ handleSubmit }) => (
          <FormContainer onSubmit={handleSubmit}>
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Primary Contact Details</CardTitle>
                </CardTitleWrap>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userName"
                          component="input"
                          type="text"
                          placeholder="Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Phone Number<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userPhone"
                          component={renderMaskedField}
                          type="text"
                          mask={[/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                          placeholder="Phone Number"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Email<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="userEmail"
                          component="input"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>UIN #<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="uin"
                          component="input"
                          type="number"
                          placeholder="UIN"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitleWrap>
                  <CardTitle>Account Details</CardTitle>
                </CardTitleWrap>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account Name<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accName"
                          component="input"
                          type="text"
                          placeholder="Account Name"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Country<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accCountry"
                          component="input"
                          type="text"
                          placeholder="Country"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accountNo"
                          component="input"
                          type="number"
                          placeholder="Account Number"
                          onInput={() => setShowConfirmAccountField(true)}
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>City<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="accCity"
                          component="input"
                          type="text"
                          placeholder="City"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {/* {!formValues.accountNo && (
                  <Col md={6}>
                    
                    <FormGroup>
                      <FormGroupLabel>Confirm Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="conAccount"
                          component="input"
                          type="number"
                          placeholder="Confirm Account"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  )}
                  {showConfirmAccountField && (
                    <Col md={6}>
                      <FormGroup>
                        <FormGroupLabel>Confirm Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="conAccount"
                            component="input"
                            type="number"
                            placeholder="Confirm Account"
                            required
                          />
                        </FormGroupField>
                      </FormGroup>
                    </Col>
                  )} */}
                  {(!formValues.accountNo || showConfirmAccountField) && (
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Confirm Account<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="conAccount"
                          component="input"
                          type="number"
                          placeholder="Confirm Account"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
  )}
                  
  
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Branch<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="branch"
                          component="input"
                          type="text"
                          placeholder="Branch"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>IFSC<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="ifscCode"
                          component="input"
                          type="text"
                          placeholder="IFSC"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormGroupLabel>Bank<span style={{ color: 'red' }}>*</span></FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="bank"
                          component="input"
                          type="text"
                          placeholder="Bank"
                          required
                        />
                      </FormGroupField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right mt-3">
              <FormButtonToolbar>
                <Button variant="primary" type="submit">Submit</Button>
              </FormButtonToolbar>
            </div>
          </FormContainer>
        )}
      </Form>
    </Col>
  );
};

AddContactInfo.propTypes = {
  formValues: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userName: PropTypes.string,
    userPhone: PropTypes.string,
    userEmail: PropTypes.string,
    uin: PropTypes.number,
    accName: PropTypes.string,
    accountNo: PropTypes.number,
    ifscCode: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    accCity: PropTypes.string,
    accCountry: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddContactInfo;
