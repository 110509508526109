import React, { useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
  FaLocationDot, 
  FaPercent, 
  FaUser, 
  FaPhone,
 } from 'react-icons/fa6'; 
import { MdAlternateEmail } from 'react-icons/md';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';






const ConfirmCard = () => {
       const a = 6;
  return (
    <div className="card mb-4 shadow-sm" style={{ maxWidth: '100%' }}>
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-12">
            <h5 className="fw-bold">United World Tours & Tourism</h5>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4" style={{ display: 'flex' }}>
            <h6 className="fw-bold">Trip:<p className="">Dubai Rondevoz-156487ADHE14</p></h6>
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <p className="mb-1"><strong>Start Date:</strong> 23-08-2024</p>
            <p style={{ marginLeft: '12px' }}><strong>End Date:</strong> 23-08-2024</p>
          </div>
          <div className="col-md-4 text-end">
            
            <Button variant="outline-success" size="sm" className="mx-1" title="Edit">
              Email
            </Button>
            <Button variant="outline-warning" size="sm" className="mx-1" title="Edit">
              Edit Trip
            </Button>
            <Button variant="outline-primary" size="sm" className="mx-1" title="Add">
              Add Meal
            </Button>
          </div>
        </div>

        <div className="card mb-4 shadow-sm" style={{ maxWidth: '100%' }}>
          <div className="card-body">
            {/* Meal Day Header with Actions */}
            <div className="row mb-2 align-items-center" style={{ display: 'flex' }}>
              <div className="col-md-8 d-flex align-items-center">
                <h6 className=" me-2">Meal Day 1 - 04 May 2019</h6>
                <Button variant="outline-success" size="sm" className="mx-1" title="Email">
                  Email
                </Button>
                <Button variant="outline-danger" size="sm" className="mx-1" title="Print">
                  Print
                </Button>
              </div>
            </div>

            {/* Meal Details */}
            <div className="row border p-3 rounded">
              <div className="col-md-3">
                <p><strong>Rajasthani Thali</strong></p>
                <p className="text-muted">Locations: Karana, Dubai</p>
              </div>
              <div className="col-md-3">
                <p><strong>Timing:</strong> Lunch, Dinner</p>
                <p><strong>Total Meals:</strong> 30</p>
              </div>
              <div className="col-md-3">
                <p><strong>Type:</strong> GIT, FIT, Delivery</p>
                <p><strong>Format:</strong> Thali</p>
              </div>
              <div className="col-md-3">
                <p><strong>Amount:</strong> AED 450</p>
                <p><strong>Status:</strong> <span className="text-success"> Approved</span></p>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => onDeleteHandler(id)}
        message="Are you sure you want to delete this Agent?"
      /> */}
      {/* <DeleteConfirmationModal
        show={contactdeleteModalShow}
        handleClose={handlecontactDeleteModalClose}
        handleDelete={() => onDeletecontact(id)}
        message="Are you sure you want to delete this Contact details?"
      /> */}

      {/* <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAgent
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditSubmit}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button> */}
      {/* </Modal.Footer>
      </Modal> */}

      {/* <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Additional Information
            <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddContactInfo
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditContactSubmit}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={showModalpassword} onHide={handleClosepassword} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangedPassword
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onAddPasswordHander}
          />
        </Modal.Body>
      </Modal> */}

    </div>
  );
}; 



export default ConfirmCard;
