import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Card, CardBody } from '@/shared/components/Card';
import Collapse from '@/shared/components/Collapse';
import BasicDetails from './BasicDetails';

const RestaurantInfo = ({ resID }) => {
   const [basicResInfo, setBasicResInfo] = useState({});
  const fetchRestaurnatData = async () => {  
    try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/restaurant_data_fetch.php?id=${resID}`);
        const data = await url.json();
        if (data.status && data.message === 'Success') {
          setBasicResInfo({
                name: data.data[0].user_name || 'NA',
                email: data.data[0].user_email || 'NA',
                phone: data.data[0].user_phone || 'NA',
                uin: data.data[0].uin || 'NA',
                acc_name: data.data[0].acc_name || 'NA',
                account_no: data.data[0].account_no || 'NA',
                ifsc_code: data.data[0].ifsc_code || 'NA',
                branch: data.data[0].branch || 'NA',
                bank: data.data[0].bank || 'NA',
                acc_city: data.data[0].acc_city || 'NA',
                acc_country: data.data[0].acc_country || 'NA',
                photo: data.data[0].photo || 'NA',
              });
        }
    } catch (e) {
        console.error(e);
    }
  };
  useEffect(() => {
    fetchRestaurnatData();
  }, []);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          {/* Basic Details Section */}
          <Collapse title="Restaurant Details" className="with-shadow">
            <BasicDetails basicResInfo={basicResInfo} />
          </Collapse>
        </CardBody>
      </Card>
    </Col>
  );
};

RestaurantInfo.propTypes = {
  resID: PropTypes.string.isRequired,
};

export default RestaurantInfo;
