import React, {
    createContext, useState, useEffect, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const PermissionContext = createContext();
   
   const PermissionContextProvider = ({ children }) => {
    const [permissionsAccess, setPermissionsAccess] = useState({});
    const [permissionUpdate, setPermissionUpdate] = useState(false);


  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    permissionsAccess,
    setPermissionsAccess,
    permissionUpdate, 
    setPermissionUpdate,
  }), [permissionsAccess, permissionUpdate]);

   
     return (
       <PermissionContext.Provider value={value}>
         {children}
       </PermissionContext.Provider>
     );
   };
   
   PermissionContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default PermissionContextProvider;
