import React, {
    createContext, useState, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const ThemeContext = createContext();
   
   const ThemeContextProvider = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState('Light');

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => ({
    currentTheme, 
    setCurrentTheme,
  }), [currentTheme]);

   
     return (
       <ThemeContext.Provider value={value}>
         {children}
       </ThemeContext.Provider>
     );
   };
   
   ThemeContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default ThemeContextProvider;
