import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { GrNotes } from 'react-icons/gr';
import { MdOutlineLunchDining } from 'react-icons/md';
import { ImSpoonKnife } from 'react-icons/im';
import { FaLocationDot } from 'react-icons/fa6';
import { Button, ButtonGroup } from '@/shared/components/Button';
import { IoMdCloseCircle, IoMdPeople } from 'react-icons/io';
import { margin } from 'polished';
import EditRestaurant from './EditRestaurant';

import DeleteConfirmationModal from './DeleteModal';
import { RestaurantContext } from '../../../../context/RestaurantContext';
import { PermissionContext } from '../../../../context/PermissionContext';

const RestaurantCard = ({ 
   id,
   name,
   format,
   mealOffered,
   cuisines,
   capacity,
   clipcapacity,
   image,
   count,
   theme,
}) => {
  const logo = `https://navrang.mvd-tech.io/API/images/restaurant/${image}`;
  
  const [mealOfferds, setMealOffereds] = useState([]);
  const [formatOfferds, setFormatOffereds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAttachmentModal, setShowAtttachmentModal] = useState(false);
  const [deleteModalShow, setDeleteModal] = useState(false);
  const { setIsDetailsClick, setRefresh, setRestaurantDetailsId } = useContext(RestaurantContext);
  const { permissionsAccess } = useContext(PermissionContext);



  
  // Toggle the Edit Restaurant modal
  const toggleModal = () => setShowModal(!showModal);
  const toggleModalAttachment = () => setShowAtttachmentModal(!showAttachmentModal);

  
  const handleDeleteModalShow = () => setDeleteModal(true);
  const handleDeleteModalClose = () => setDeleteModal(false);

  const restaurantDetails = () => {
    setIsDetailsClick(true);
    setRefresh(prev => !prev);
    setRestaurantDetailsId(id);
  };

  const EditRestaurantData = async (values) => {
    const Start = new Date(values.start_time);
    const formattedStartTime = Start.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const End = new Date(values.end_time);
    const formattedEndTime = End.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
    });

     const mealFormat = [];
     const mealOffereds = [];
     if (values.fit) {
        mealFormat.push('1');
     }
     if (values.git) {
        mealFormat.push('2');
     }
     if (values.delivery) {
        mealFormat.push('3');
     }


    if (values.breakfast) {
      mealOffereds.push('1');
    }
    if (values.snacks) {
      mealOffereds.push('2');
    }
    if (values.lunch) {
      mealOffereds.push('3');
    }
    if (values.dinner) {
      mealOffereds.push('4');
    }

    const formData = new FormData();
    formData.append('id', values.id); 
    formData.append('name', values.name); 
    formData.append('address1', values.address1); 
    formData.append('area', values.area); 
    formData.append('state', values.state); 
    formData.append('pincode', values.pincode); 
    formData.append('description', values.description); 
     formData.append('photo', values.logo); 
    formData.append('setting_capacity', values.setting_capacity); 
    formData.append('capping_capacity', values.capping_capacity); 
    formData.append('uin', values.uin);
    formData.append('address2', values.address2); 
    formData.append('city', values.city); 
    formData.append('country', values.country); 
    formData.append('location', values.location); 
    formData.append('cuisines', values.cuisines); 
    formData.append('start_time', formattedStartTime); 
    formData.append('end_time', formattedEndTime); 
    formData.append('tax_id', values.tax_id); 
    formData.append('markup_percentage', values.markup_percentage); 
    formData.append('meal_offered', mealOffereds.join(',')); 
    formData.append('meal_format', mealFormat.join(','));  

    try {
        const response = await fetch('https://navrang.mvd-tech.io/API/restaurant_edit.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data updated successfully.') {
            toast.success('Restaurant Edit Successfully...');
            setRefresh(prev => !prev);
            toggleModal();
        } else {
            toast.error('error');
        }
    } catch (error) {
        toast.error(error.message);
    }    
};

  // Map the meal and format options
  useEffect(() => {
    if (mealOffered) {
      const mealOffer = mealOffered.split(','); 
      const mappedMeals = mealOffer.map((meal) => {
        switch (meal) {
          case '1':
            return 'Breakfast';
          case '2':
            return 'Snacks';
          case '3':
            return 'Lunch';
          case '4':
            return 'Dinner';
          default:
            return ''; 
        }
      });
      setMealOffereds(mappedMeals); 
    }
  }, [mealOffered]); 

  useEffect(() => {
    if (format) {
      const formatOffer = format.split(','); 
      const mappedFormat = formatOffer.map((formats) => {
        switch (formats) {
          case '1':
            return 'FIT';
          case '2':
            return 'GIT';
          case '3':
            return 'DELIVERY';
          default:
            return ''; 
        }
      });
      setFormatOffereds(mappedFormat); 
    }
  }, [format]);

 
  const handleDeleteClick = async (ids) => {
      try {
        const deleteurl = await fetch(`https://navrang.mvd-tech.io/API/restaurant_main_delete.php?id=${ids}`);
        const response = await deleteurl.json();
         if (response.status && response.message === 'Data deleted successfully') {
           toast.success('Restaurant deleted successfully');
           setRefresh(prev => !prev);
            handleDeleteModalClose(); 
         } else {
           toast.error('Error deleting restaurant');
         }
      } catch (error) {
        toast.error(`Error deleting restaurant,${error.message}`);
      }
  };

  return (
    <div 
      className="card mb-3 position-relative" 
      style={{
    backgroundColor: theme === 'Dark' ? '#232329' : '#f9f9f9',
    maxWidth: '100%',
    border: `2px solid ${theme === 'Dark' ? '#444' : '#ddd'}`,
    borderRadius: '15px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, boxShadow 0.3s ease',
  }}
      onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'scale(1.02)';
    e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  }}
      onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.1)';
  }}
    >
      <div className="row mt-2">
        <div className="col-md-3 col-12 d-flex justify-content-center align-items-center" style={{ padding: '12px' }}>
          <div 
            style={{
          borderRadius: '8px',
          overflow: 'hidden',
          width: '100%',
          height: '180px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
          transition: 'transform 0.3s ease, boxShadow 0.3s ease',
          marginLeft: '15px',
          cursor: 'pointer',
        }}
            onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'scale(1.05)';
          e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
        }}
            onMouseLeave={(e) => {
          e.currentTarget.style.transform = 'scale(1)';
          e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.15)';
        }}
          >
            <img 
              src={logo} 
              alt={name} 
              style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
          }}
            />
          </div>
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <h3 
                  className="card-text" 
                  style={{ color: theme === 'Dark' ? '#fff' : '#333', fontWeight: '700', fontSize: '1.8rem' }}
                >
                  {name}
                </h3>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <p 
                    style={{ fontSize: '1rem', marginBottom: '0.5rem' }}
                  >
                    <GrNotes /> Format: {formatOfferds.join(',')}
                  </p>
                  <p className="mt-4" style={{ fontSize: '1rem' }}><MdOutlineLunchDining /> Cuisines: {cuisines}</p>
                </div>
                <div className="col-md-4">
                  <p 
                    style={{ fontSize: '1rem', marginBottom: '0.5rem' }}
                  >
                    <ImSpoonKnife /> Meals: {mealOfferds.join(',')}
                  </p>
                  <p className="mt-4" style={{ fontSize: '1rem' }}><IoMdPeople /> Capacity: {capacity}</p>
                </div>
                <div className="col-md-4">
                  <p style={{ fontSize: '1rem', marginBottom: '0.5rem' }}><FaLocationDot /> Location: {count}</p>
                  <p className="mt-4" style={{ fontSize: '1rem' }}><IoMdPeople /> Clip Capacity: {clipcapacity}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => handleDeleteClick(id)}
        message="Are you sure you want to delete this restaurant?"
      />

      {/* Edit Restaurant Modal */}
      {showModal && (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-labelledby="exampleModalLabel">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ borderRadius: '10px', overflow: 'hidden' }}>
            <div className="modal-header" style={{ borderBottom: '1px solid #ddd' }}>
              <h3 className="modal-title" id="exampleModalLabel" style={{ fontWeight: '600' }}>Edit Restaurant</h3>
              <Button type="button" variant="primary" rounded size="sm" onClick={toggleModal} aria-label="Close">
                <IoMdCloseCircle className="close-icon" />
              </Button>
            </div>
            <div className="modal-body">
              <EditRestaurant onSubmit={EditRestaurantData} id={id} />
            </div>
          </div>
        </div>
      </div>
  )}

      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
        <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
          {permissionsAccess?.[1]?.master_id === '2' && permissionsAccess?.[1]?.delete === 1 && (
          <Button 
            variant="outline-danger" 
            size="sm" 
            title="Delete" 
            onClick={handleDeleteModalShow} 
            style={{ borderRadius: '8px', marginRight: '10px' }}
          >
            <span className="lnr lnr-trash" /> Delete
          </Button>
      )}

          <Button 
            variant="outline-primary" 
            size="sm" 
            title="Details" 
            onClick={() => restaurantDetails()} 
            style={{ borderRadius: '8px' }}
          >
            <span className="lnr lnr-file-empty" /> Details
          </Button>
        </ButtonGroup>
      </div>
    </div>


  );
};

RestaurantCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  cuisines: PropTypes.string.isRequired,
  mealOffered: PropTypes.string.isRequired,
  capacity: PropTypes.string.isRequired,
  clipcapacity: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default RestaurantCard;
