import React, { 
    useState, 
    useEffect, 
    useMemo, 
    useContext, 
} from 'react';
import PropTypes from 'prop-types';
import RestaurantLocation from './RestautantLocation';
import { RestaurantContext } from '../../../../context/RestaurantContext';


const LocationDetails = ({ restaurantDetailsId, theme }) => {
    const { LocationRefesh } = useContext(RestaurantContext);
  const [locationDetails, setLocationDetails] = useState([]);
  const [status, setStatus] = useState({ isLoading: true, error: null });

  useEffect(() => {
    if (!restaurantDetailsId) return;

    const fetchLocationDetails = async () => {
      try {
        setStatus({ isLoading: true, error: null });

        const response = await fetch(
          `https://navrang.mvd-tech.io/API/restaurant_details_fetch.php?id=${restaurantDetailsId}`,
        );

        if (!response.ok) {
          throw new Error('Failed to fetch location details');
        }

        const data = await response.json();
        if (!data?.data?.length) {
          throw new Error('No location data found');
        }

        setLocationDetails(data.data);
      } catch (err) {
        setStatus({ isLoading: false, error: err.message });
      } finally {
        setStatus(prev => ({ ...prev, isLoading: false }));
      }
    };

    fetchLocationDetails();
  }, [restaurantDetailsId, LocationRefesh]);

  const derivedStatus = useMemo(() => {
    if (status.isLoading) return { message: 'Loading location details...', type: 'loading' };
    if (status.error) return { message: `${status.error}`, type: 'error' };
    if (!locationDetails.length) return { message: 'No location data available', type: 'empty' };
    return { type: 'success' };
  }, [status, locationDetails]);

  const styles = {
    header: {
      color: theme ? 'white' : 'black',
      marginLeft: '10px',
      marginBottom: '5px',
    },
  };

  if (derivedStatus.type !== 'success') {
    return <div>{derivedStatus.message}</div>;
  }

  return (
    <div>
      <h2 
        style={styles.header}
      >Restaurant Locations
      </h2>
      {locationDetails.map(location => (
        <RestaurantLocation key={location.id} alldetails={location} theme={theme} />
      ))}
    </div>
  );
};

LocationDetails.propTypes = {
  restaurantDetailsId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default LocationDetails;
