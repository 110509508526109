import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import UserTables from './UsersData/UserTables';


const Users = () => (
  <Container>
    <UserTables />
  </Container>
  );

export default Users;
